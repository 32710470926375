import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { ButtonWithProgress, LinearFormControl } from 'src/components/widgets';
import { Divider } from '@material-ui/core';
import { auth } from 'src/firebase';

export default ({ token }) => {
    const [progress, setProgress] = useState(false);

    const handleContinue = async () => {
        setProgress(true);
        // todo: handle error
        await auth.signInWithCustomToken(token);
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px", maxWidth: "600px" }}>
            <Typography style={{marginTop: 32}}>
                Credentialele dumneavoastra au fost validate.
            </Typography>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyContent: "space-between" }}>
            <ButtonWithProgress variant="contained" color="primary" disableElevation disabled={progress} showProgress={progress}
                onClick={handleContinue}>CONTINUAȚI</ButtonWithProgress>
        </LinearFormControl>
    </>
};