import React, { useRef, useState } from 'react';
import { sha256 } from 'sha.js';

export default ({ oaScope, style, className, onProofReceived }) => {
    const proofHandlerRef = useRef();
    const iframeRef = useRef();
    const [url, setUrl] = useState(null);
    
    function getProofUrl() {
        const urlBase = process.env.REACT_APP_VERIFICABIL_OA_URL;
        const redirectUrl = process.env.REACT_APP_VERIFICABIL_OA_REDIRECT_URL;
        const orgId = process.env.REACT_APP_VERIFICABIL_OA_ORG_ID;

        var code = new Date().getTime().toString();
        // callback when data is received
        proofHandlerRef.current = async (event) => {
            if (event.isTrusted) {
                console.info("Proof result", JSON.stringify(event.data));
                // todo: refactor this in case the code is generated externally
                onProofReceived && onProofReceived(code, event.data.code);
            } else {
                console.warn("Untrusted message", event.origin);
            }
        }

        // get base64url of sha256 of code
        let challenge = new sha256().update(code).digest('base64');
        challenge = challenge.replace(/[/]/g, "_").replace(/[+]/g, "-").replace(/[=]/g, "");
        console.info("Code", code, "Challenge", challenge);
        return `${urlBase}?response_type=code&client_id=${orgId}&scope=${oaScope}&redirect_uri=${redirectUrl}&code_challenge=${challenge}&code_challenge_method=S256`;
    }

    // todo: investigate if this is the right setup for hooking up the event listener
    if (url === null) {
        setUrl(getProofUrl());
        window.addEventListener('message', event => proofHandlerRef.current && proofHandlerRef.current(event), false);
    }

    return <iframe title="verificabil_frame" src={url} style={style} className={className} ref={iframeRef} />
}