import React, { createContext, useState, useEffect, useRef } from 'react';
import { auth } from '../firebase';

const isSignInLink = () => auth.isSignInWithEmailLink(window.location.href);

export const AuthContext = createContext();
export const withAuth = Component => props => (
    <AuthConsumer>
        {authState => <Component {...props} auth={{ isSignInLink, ...authState }} />}
    </AuthConsumer>
)

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
    const [authState, setAuthState] = useState({ isInitializing: true });
    const authUnsubscribeRef = useRef();

    useEffect(() => {
        // check if this is a sign-in link
        if (authState.isInitializing && isSignInLink()) {
            // extract user's email and try to log them in
            const params = new URLSearchParams(window.location.search);
            const email = params.get('email');
            if (email) {
                auth.signInWithEmailLink(email).then(cred => {
                    console.info("SIGNED IN", JSON.stringify(cred));
                }).catch(err => {
                    console.warn("UNABLE TO SIGN IN", JSON.stringify(err));
                    // sign out so we redirect to login page
                    auth.signOut();
                })
            }
        }

        if (!authUnsubscribeRef.current) {
            console.debug("Subscribing to auth.onIdTokenChanged");
            authUnsubscribeRef.current = auth.onIdTokenChanged(async (user, error) => {
                if (error) {
                    console.warn("AUTH ERROR");
                }
                if (user) {
                    console.info("User is logged in");
                    const idToken = await user.getIdTokenResult();
                    // store user, token and make up a list of roles
                    // based on custom criteria (claims in our case)
                    setAuthState({
                        isSignedIn: true, 
                        user,
                        idToken,
                        signOut: () => auth.signOut()
                    });
                } else if (!user) {
                    console.info("No user");
                    setAuthState({ isSignedIn: false });
                }
            });
        }
        return () => {
            if (authUnsubscribeRef.current) {
                console.debug("Unsubscribing auth.onIdTokenChanged");
                authUnsubscribeRef.current();
                authUnsubscribeRef.current = null;
            };
        }
    }, []); //eslint-disable-line
    // here above we ignore dependency on effect so cleanup only runs on unmount

    return <AuthContext.Provider value={authState}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthProvider;