import { get as _get } from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';

pdfMake.fonts = {
    // download default Roboto font from cdnjs.com
    Roboto: {
        normal: `${process.env.REACT_APP_FONT_CDN_URL}/Roboto/Roboto-Regular.ttf`,
        bold: `${process.env.REACT_APP_FONT_CDN_URL}/Roboto/Roboto-Medium.ttf`
    }
}

var styles = {
    header: {
        fontSize: 15,
        bold: true,
        margin: [0, 0, 0, 5]
    },
    subheader: {
        fontSize: 13,
        bold: true,
        margin: [0, 0, 0, 10]
    },
    smallText: {
        fontSize: 10
    },
    sectionTitle: {
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 5]
    },
    sectionScore: {
        alignment: "center",
        fontSize: 11,
        color: "#484EC7",
        bold: true,
        margin: [0, 5, 0, 5]
    },
    questionTitle: {
        fontSize: 11,
        margin: [0, 5, 0, 5]
    },
    questionScore: {
        alignment: "center",
        fontSize: 11,
        bold: true,
        margin: [0, 5, 0, 5]
    },
    responseText: {
        fontSize: 10,
        margin: [10, 5, 0, 0]
    },
    info: {
        fontSize: 10,
        margin: [10, 10, 10, 10]
    },
    disabledResponseText: {
        fontSize: 10,
        decoration: "lineThrough",
        margin: [10, 5, 0, 0],
        color: "#777777"
    },
    sectionContent: {
        margin: [0, 5, 0, 15]
    }
};

export const downloadPdf = (({ fileName, definition, results, patient, date }) => {

    function renderItems(parentContent, items, path) {
        items.forEach(item => {
            if (item.type === 'question') {
                renderQuestion(parentContent, item, path);
            } else {
                renderSection(parentContent, item, path);
            }
        })
    };

    function renderQuestion(parentContent, q, path) {
        const myPath = path ? [...path, q.id] : [q.id];
        const valuePath = [...myPath, "value"];
        let valueContent = "";
        if (q.question_type.startsWith("range_")) {
            const value = _get(results, valuePath, "-");
            valueContent = { text: value, style: "questionScore" };
        }
        parentContent.push([{ text: q.title, style: 'questionTitle' }, valueContent]);

        if (q.question_type === 'select_many') {
            q.select_options.forEach(option => {
                const isSelected = _get(results, valuePath, []).includes(option.value);
                parentContent.push([{ text: option.label, style: isSelected ? 'responseText' : 'disabledResponseText' }, ""]);
            });
        }
    }

    function renderSection(parentContent, s, path) {
        const myPath = path ? [...path, s.id] : [s.id];
        const sectionLevel = myPath.length;

        // render on fixed number of columns, first level are table 
        // lower levels are just rows lined up with the headings from top section
        if (sectionLevel === 1) {
            const sectionContent = {
                style: 'sectionContent',
                table: {
                    widths: ['*', 30],
                    headerRows: 0,
                    body: [
                        [
                            { text: `${s.id}. ${s.title}`, style: 'sectionTitle' },
                            {
                                border: [true, true, true, true],
                                text: _get(results, myPath + ".overrideScore", "-"), style: 'sectionScore'
                            }
                        ]
                    ]
                },
                layout: {
                    defaultBorder: false,
                    hLineColor: function (i, node) {
                        return '#484EC7';
                    },
                    vLineColor: function (i, node) {
                        return '#484EC7';
                    },
                }
            };
            if (s.info) {
                sectionContent.table.body.push([{
                    colSpan: 2,
                    text: "",
                    style: "responseText"
                }]);    
                sectionContent.table.body.push([{
                    fillColor: "#ecf6fd",
                    color: '#484EC7',
                    colSpan: 2,
                    text: s.info,
                    style: "info"
                }])
            }
            renderItems(sectionContent.table.body, s.items, myPath);
            parentContent.push(sectionContent);
        } else {
            renderItems(parentContent, s.items, myPath);
        }
    }

    var patientInfo = `Pacient: ${patient.patientName || patient.name}`;
    if (patient.role === 'caregiver') {
        patientInfo += ` / Aparţinător: ${patient.name}`;
    }

    var pdfDefinition = {
        footer: (currentPage, pageCount) => ({
            columns: [
                { text: moment().format("D MMM YYYY, H:mm:ss"), style: "smallText", margin: [20, 10, 0, 10] },
                { text: `Pagina ${currentPage} / ${pageCount}`, alignment: 'right', style: "smallText", margin: [0, 10, 20, 10] }
            ]
        }),
        content: [
            {
                columns: [
                    { text: patientInfo, style: 'header' },
                    { text: date.format("MMM YYYY"), style: 'header', alignment: "right", color: "#484EC7" }
                ]
            },
            {
                columns: [
                    { text: definition.title, style: 'subheader' },
                    { text: `Scor: ${parseInt(results.score)}%`, style: 'subheader', alignment: "right", color: "#484EC7" }
                ]
            }
            
        ],
        styles
    };

    renderItems(pdfDefinition.content, definition.items);

    // pdfMake.createPdf(pdfDefinition).open();
    pdfMake.createPdf(pdfDefinition).download(fileName);
    // return pdfDefinition;
});