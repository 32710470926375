import React, { useState, useEffect, useRef, useCallback } from 'react';
import { get as _get, setWith as _setWith, unset as _unset, cloneDeep as _cloneDeep } from 'lodash';
import { Typography, Button, IconButton, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
// internal references
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import FunctionalityMonthSummary from 'src/components/widgets/functionalityMonthSummary';
import EditSurvey from './editSurvey';
import { downloadPdf } from './pdfSurvey';

import { useStaticData } from 'src/providers/StaticDataProvider';
import { ArrowBackIos, ChevronLeftSharp, ChevronRightSharp, LockOpen } from '@material-ui/icons';
import SurveyUtils from 'src/util/survey';

import ProfileView from './visitStepComponents/ProfileView';
import TreatmentView from './visitStepComponents/TreatmentView';
import { firestoreDelta } from 'src/utils';
import { LinearFormControl } from '../widgets';
import PercentBar from '../widgets/PercentBar';
import VisitStepper from './VisitStepper';
import TherapyView from './visitStepComponents/TherapyView';

const useStyles = makeStyles((theme) => ({
    tab: {
        padding: "0.5rem 1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        placeContent: "flex-end",
        cursor: "pointer",
        textAlign: "center",
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        minWidth: 0,
        opacity: 0.7,
        backgroundColor: "#f0f0f0",
        "&.selected": {
            cursor: "default",
            opacity: 1,
            fontWeight: 500,
            backgroundColor: "#ffffff"
        }
    }
}));

export default ({ patient, readOnly, evalSummary, evalDetail, setEvalDetail,
    currentSurveyId, setCurrentSurveyId, currentStepId, setCurrentStepId }) => {
    const classes = useStyles();
    // context hooks
    const firestoreStreams = useFirestoreStreams();
    const staticData = useStaticData();
    //
    const [currentStep, setCurrentStep] = useState();
    const [currentSurvey, setCurrentSurvey] = useState();
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [edit, setEdit] = useState(readOnly);
    // keep track of current patient/period
    const patientPeriodKeyRef = useRef();

    const setCurrentStepAction = useCallback((step) => {
        setCurrentStep(step);
        // update current step reference in parent
        if (setCurrentStepId && step !== currentStepId) setCurrentStepId(step);
    }, [currentStepId, setCurrentStepId]);

    const setCurrentSurveyAction = useCallback((data) => {
        const id = data ? data.id : undefined;
        setCurrentSurvey(data);
        // update current survey id reference in parent
        if (setCurrentSurveyId && id !== currentSurveyId) setCurrentSurveyId(id);
    }, [currentSurveyId, setCurrentSurveyId]);

    const refreshSurvey = useCallback((survey = currentSurvey) => {
        if (survey && survey.id) {
            const definition = _cloneDeep(staticData.surveys.find(i => i.id === survey.id).data);
            const surveyUtil = new SurveyUtils(evalDetail.profile, definition);
            // filter conditional items based on profile 
            surveyUtil.enrichMetadata(definition);
            survey.definition = definition;
            setCurrentSurveyAction(survey);
        }
    }, [currentSurvey, evalDetail, setCurrentSurveyAction, staticData.surveys]);

    const loadSurveyResults = useCallback((surveyCode) => {
        let results;
        const detail = { id: surveyCode };
        results = _get(evalDetail, `results.${surveyCode}`, {});
        // keep reference results for delta comparison
        detail.results = results;
        detail.originalResults = _cloneDeep(results);
        refreshSurvey(detail);
    }, [refreshSurvey, evalDetail]);


    const downloadSurveyAsPdf = useCallback((surveyCode) => {
        let results;
        const detail = { id: surveyCode };
        results = _get(evalDetail, `results.${surveyCode}`, {});
        // keep reference results for delta comparison
        detail.results = results;

        const definition = _cloneDeep(staticData.surveys.find(i => i.id === surveyCode).data);
        const surveyUtil = new SurveyUtils(evalDetail.profile, definition);
        // filter conditional items based on profile 
        surveyUtil.enrichMetadata(definition);
        detail.definition = definition;
        const date = _get(evalSummary, "date", false);
        if (date !== false) {
            const fileName = `${patient.id}-${surveyCode}-${date.format("YYYY-MM")}`;
            downloadPdf({ fileName, definition, results, patient: patient.data, date });
        }
    }, [evalDetail, staticData.surveys, patient, evalSummary]);

    useEffect(() => {
        // set current step only if we have evalDetail
        if (evalDetail && !evalDetail.loading) {
            let step = _get(evalDetail, "activeStep", 1);
            if (!currentStep || currentStep > step) {
                if (step > 4) step = 4;
                if (step < 1) step = 1;
                setCurrentStepAction(step);
            } 
        }
    }, [evalDetail, currentStep, setCurrentStepAction]);

    // update current survey when parent is requesting it
    useEffect(() => {
        if (!staticData.isInitialized || !_get(evalDetail, "yearMonth")) {
            setCurrentSurvey(undefined);
        } else if (evalDetail && !evalDetail.loading && staticData.isInitialized && (!currentSurvey || currentSurvey.id !== currentSurveyId)) {
            loadSurveyResults(currentSurveyId);
        } else if (!currentSurveyId && currentSurvey) {
            setCurrentSurvey(undefined);
        }
    }, [currentSurveyId, staticData, currentSurvey, setCurrentSurvey, evalDetail, loadSurveyResults])

    // update current survey when parent is requesting it
    useEffect(() => {
        if (currentStepId < 1) {
            setCurrentStepAction(1);
        } else if (currentStepId > 4) {
            setCurrentStepAction(4);
        } else if (currentStepId !== currentStep) {
            setCurrentStep(currentStepId);
        }
    }, [currentStepId, setCurrentStep, currentStep, setCurrentStepAction])

    // handle edit mode updates
    useEffect(() => {
        // cannot enable edit in readOnly mode
        if (readOnly && edit) {
            setEdit(false);
            return;
        }
        const key = `${_get(patient, "id", "")}_${_get(evalDetail, "yearMonth", "")}`;
        const newEdit = !readOnly && _get(evalDetail, "activeStep", 0) <= 4;
        if (edit !== newEdit && key !== patientPeriodKeyRef.current) {
            // reset edit mode
            setEdit(newEdit);
        }
        // keep track of our new context
        patientPeriodKeyRef.current = key;
    }, [patient, readOnly, evalDetail, edit]);

    const handleContinue = async (data) => {
        const yearMonth = evalDetail.yearMonth;
        let deltas;
        let visitDeltas = {}; // updates to send to firestore
        const newDetail = data || _cloneDeep(evalDetail); // updates to current evalDetail
        if (evalDetail.activeStep === currentStep) {
            visitDeltas.activeStep = currentStep + 1;
            newDetail.activeStep = currentStep + 1;
        }
        // todo: handle errors
        try {
            // update other props
            deltas = firestoreDelta(newDetail, evalDetail);
            if (deltas !== false) {
                setSaveInProgress(true);
                Object.assign(visitDeltas, deltas);
                const docRef = firestoreStreams.collection("evaluations").doc(patient.id)
                    .collection("detail").doc(`${yearMonth}_medic`);
                // this may trigger an update on patient/evaluation summary data
                await firestoreStreams.updateRef(docRef, visitDeltas);
                setEvalDetail(newDetail);
            }

            if (currentStep < steps.length) {
                setCurrentStepAction(currentStep + 1);
            } else if (edit) {
                setEdit(false);
            }
        } catch (err) {
            console.error("ERROR", err.message);
        } finally {
            setSaveInProgress(false);
        }
    }

    const handleSurveyAction = async (action, surveyCode) => {
        if (currentSurvey && currentSurvey.results) {
            // save only if we have deltas
            const deltas = firestoreDelta(currentSurvey.results, currentSurvey.originalResults);
            if (deltas) {
                const docId = `${evalDetail.yearMonth}_medic`;
                const docRef = firestoreStreams.collection("evaluations").doc(patient.id)
                    .collection("detail").doc(docId);
                // todo: implement progress indicator
                const updateKey = `results.${currentSurvey.id}`;
                const results = _cloneDeep(currentSurvey.results);
                if (action !== 'close') {
                    setCurrentSurvey({ ...currentSurvey, loading: true });
                }
                try {
                    await firestoreStreams.updateRef(docRef,
                        { [updateKey]: results });
                    // refresh current state and avoid reading again
                    currentSurvey.originalResults = results;
                    // update current eval detail in state
                    const newEvalDetail = _cloneDeep(evalDetail);
                    _setWith(newEvalDetail, updateKey, results, Object);
                    setEvalDetail(newEvalDetail);
                } catch (err) {
                    // todo: display some error message
                    setCurrentSurvey({ ...currentSurvey, loading: false });
                    return;
                }
            }
        }
        if (action === 'edit' || action === 'view') {
            window.scrollTo(0, 0);
            loadSurveyResults(surveyCode);
        } else if (action === 'close' && currentSurvey) {
            setCurrentSurveyAction(undefined);
        }
    }

    const handleResponseChanged = (path, valuePath, value) => {
        const _survey = _cloneDeep(currentSurvey);
        const results = _survey.results;
        // apply value first
        _setWith(results, valuePath, value, Object);
        // then re-compute scores up the tree
        const surveyUtil = new SurveyUtils(evalDetail.profile, _survey.definition);
        surveyUtil.computeScores(path, results);
        setCurrentSurveyAction(_survey);
    }

    const handleManualScoreChanged = (path, score) => {
        const _survey = _cloneDeep(currentSurvey);
        const results = _survey.results;

        const autoScore = _get(results, [...path, "autoScore"], false);
        const floatScore = score && parseFloat(score);
        if (!floatScore && floatScore !== 0) {
            _unset(results, [...path, "overrideScore"]);
            if (autoScore !== false) {
                _setWith(results, [...path, "score"], autoScore, Object);
            } else {
                _unset(results, [...path, "score"]);
            }
        } else {
            _setWith(results, [...path, "overrideScore"], floatScore, Object);
            _setWith(results, [...path, "score"], floatScore, Object);
        }
        const surveyUtil = new SurveyUtils(evalDetail.profile, _survey.definition);
        // calculate scores
        surveyUtil.computeScores(path, results);

        setCurrentSurveyAction(_survey);
    }

    const surveyCodes = ["C1", "C2", "C3", "C4"];
    const evaluationTabs = currentSurvey && <div style={{
        display: "inline-flex", flex: 1, alignItems: "stretch", minHeight: "4rem", margin: "0 1rem"
    }}>
        {surveyCodes.map(code => {
            const item = staticData.surveys.find(i => i.id === code);
            const score = _get(evalSummary, `data.${code}`);
            return <div key={code} className={classNames(classes.tab, currentSurvey.id === item.id && "selected")}
                onClick={() => handleSurveyAction('edit', code)}>
                <div style={{
                    marginBottom: "0.3rem",
                    textOverflow: "ellipsis"
                }}>{item.data.title}</div>
                <div style={{ width: "calc(100% - 10px)" }}><PercentBar color={code.toLowerCase()} value={parseInt(score)}
                    direction="horizontal"
                    height="0.5rem" width="100%" />
                </div>
            </div>
        })}
    </div>

    const surveyIndex = currentSurvey && surveyCodes.indexOf(currentSurvey.id);
    const previousSurvey = surveyIndex >= 0 && staticData.surveys.find(i => i.id === surveyCodes[surveyIndex - 1]);
    const nextSurvey = surveyIndex >= 0
        && surveyIndex < surveyCodes.length - 1
        && staticData.surveys.find(i => i.id === surveyCodes[surveyIndex + 1]);

    const steps = [
        { label: "Profil Demografic" },
        { label: "Evaluare Funcționalitate" },
        { label: "Tratament" },
        { label: "Plan Terapeutic" }
    ];

    if (!evalSummary) {
        return <React.Fragment />;
    }

    return (
        <div style={{ display: "flex", alignItems: "stretch", marginTop: "1rem", marginRight: "1rem" }}>
            <div style={{ height: "auto", backgroundColor: "#f7f7f7", display: "flex", flexDirection: "column" }}>
                <VisitStepper title={evalSummary.loading ? "..." : evalSummary.date.format("MMMM YYYY")} evalDetail={evalDetail}
                    steps={steps} currentStep={currentStep} onStepClick={stepIx => {
                        handleSurveyAction('close');
                        setCurrentStepAction(stepIx);
                    }} />
                {!readOnly && !edit && <Button variant="contained" color="default" startIcon={<LockOpen />}
                    disableElevation style={{ alignSelf: "stretch" }} onClick={() => setEdit(true)}>Modifică</Button>}
            </div>
            {currentStep > 0 && <div style={{
                flex: "auto",
                borderTop: "2px solid #f0f0f0", borderRight: "2px solid #f0f0f0", borderTopRightRadius: "1rem"
            }}>
                <div style={{ display: "inline-flex", width: "100%", alignItems: "center" }}>
                    {currentSurvey && currentSurveyId && <IconButton color="primary"
                        style={{ width: "2rem", marginLeft: "1rem", marginRight: "-1rem" }}
                        onClick={() => handleSurveyAction('close')} size="small"><ArrowBackIos />
                    </IconButton>}
                    <Typography variant="h6" style={{
                        whiteSpace: "nowrap", flex: "0",
                        padding: "0.5rem 1rem"
                    }}>
                        {steps[currentStep - 1].label}
                    </Typography>
                </div>
                {currentSurvey && currentSurveyId && evaluationTabs}
                <div style={{ margin: "0 1rem 1rem 2rem" }}>
                    {!currentSurveyId && !evalDetail.loading && <>
                        {currentStep === 1 &&
                            <ProfileView
                                evalDetail={evalDetail}
                                readOnly={!edit}
                                onContinue={handleContinue}
                                saveInProgress={saveInProgress} />}
                        {currentStep === 2 &&
                            <FunctionalityMonthSummary  monthData={_get(evalSummary, "data", {})}
                                readOnly={!edit}
                                downloadSurveyAsPdf={downloadSurveyAsPdf}
                                onSurveyAction={handleSurveyAction} onContinue={handleContinue} />}
                        {currentStep === 3 &&
                            <TreatmentView
                                evalDetail={evalDetail}
                                readOnly={!edit}
                                onContinue={handleContinue}
                                saveInProgress={saveInProgress} />}
                        {currentStep === 4 &&
                            <TherapyView
                                evalDetail={evalDetail}
                                readOnly={!edit}
                                onContinue={handleContinue}
                                saveInProgress={saveInProgress}
                                previousMedication={{
                                    medication: _get(evalDetail, "treatment.medication"),
                                    medication2: _get(evalDetail, "treatment.medication2")
                                }}
                            />}
                    </>}
                    {currentSurvey && currentSurveyId && <EditSurvey
                        key="edit_survey"
                        code={currentSurvey.id}
                        definition={currentSurvey.definition}
                        loading={currentSurvey.loading}
                        results={currentSurvey.results}
                        onResultChange={handleResponseChanged}
                        onManualScoreChange={handleManualScoreChanged}
                        date={evalDetail.yearMonth}
                        readOnly={!edit}
                        profile={evalDetail.profile}
                        close={() => handleSurveyAction('close')} />}
                    {edit && currentSurveyId && currentSurvey && !currentSurvey.loading && <LinearFormControl centered>
                        {previousSurvey && <Button variant="outlined" startIcon={<ChevronLeftSharp />}
                            onClick={() => handleSurveyAction("edit", previousSurvey.id)}>
                            {previousSurvey.data.title}
                        </Button>}
                        {nextSurvey && <Button variant="outlined" endIcon={<ChevronRightSharp />}
                            onClick={() => handleSurveyAction("edit", nextSurvey.id)}>
                            {nextSurvey.data.title}
                        </Button>}
                        {!nextSurvey && <Button variant="outlined" endIcon={<ChevronRightSharp />}
                            onClick={async () => {
                                await handleSurveyAction("close");
                                handleContinue();
                            }}>Plan Terapeutic</Button>}
                    </LinearFormControl>}
                </div>
            </div>}
        </div>
    );
}