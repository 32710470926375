import React from 'react';
import { Typography, Stepper, Step, StepLabel, useTheme, StepButton } from '@material-ui/core';
import _get from 'lodash/get';

export default ({evalDetail, steps, currentStep, onStepClick, title}) => {
    const theme = useTheme();

    return <>
        <Typography variant="h6"
            style={{
                whiteSpace: "nowrap", flex: "0", paddingBottom: "1rem",
                borderBottom: "1px solid #f0f0f0", borderTopLeftRadius: "1rem",
                padding: "0.5rem",
                textAlign: "center",
                backgroundColor: theme.palette.primary.main,
                color: "#ffffff",
                fontWeight: "bold"
            }}>
            {title}
        </Typography>
        <Stepper style={{ padding: "1rem", whiteSpace: "nowrap", backgroundColor: "#f7f7f7", borderBottomLeftRadius: "1rem" }}
            orientation="vertical" nonLinear activeStep={currentStep - 1}>
            {steps.map((s, ix) => {
                const stepIx = ix + 1; // translate from 0 to 1-based
                const isLocked = _get(evalDetail, "isComplete", false);
                const isStepComplete = isLocked || (evalDetail && evalDetail.activeStep > stepIx);
                // only first two steps are required
                const isClickable = evalDetail && 
                    (isLocked || evalDetail.activeStep > 2 || evalDetail.activeStep > stepIx) && currentStep !== stepIx;
                return <Step key={stepIx}
                    completed={isStepComplete}>
                        {isClickable ? <StepButton active={evalDetail.activeStep >= stepIx}
                    onClick={() => onStepClick(stepIx)}>{s.label}</StepButton> : 
                    <StepLabel>{s.label}</StepLabel>}
                </Step>
            }
            )}
        </Stepper>
    </>
}