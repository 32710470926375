import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { ButtonWithProgress, LinearFormControl } from 'src/components/widgets';
import { Divider, Button, TextField, Checkbox } from '@material-ui/core';
import { ChevronLeftSharp } from '@material-ui/icons';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';

export default ({ userInfo, setUserInfo, onContinue, onBack }) => {
    const firestoreStreams = useFirestoreStreams();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [result/*, setResult*/] = useState(false);
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        setUserInfo({
            ...userInfo,
            [name]: event.target.value,
        });
    };

    const validateInput = () => {
        const newErrors = {};
        if (!userInfo.verified && (userInfo.familyName || "").trim().length < 2) {
            newErrors.familyName = "Numele este obligatoriu";
        }
        if (!userInfo.verified && (userInfo.givenName || "").trim().length < 2) {
            newErrors.givenName = "Prenumele este obligatoriu";
        }
        if (!userInfo.verified && (userInfo.password || "").trim().length < 6) {
            newErrors.password = "Parola trebuie sa aiba minimum 6 caractere";
        }
        return newErrors;
    }

    const handleContinue = async () => {
        const newErrors = validateInput();
        if (Object.keys(newErrors).length > 0)
            setErrors(newErrors);
        else {
            setProgress(true);
            // if the user logged in with a third party provider, their user record is already there
            // it will simply be enriched with current data
            try {
                const register = firestoreStreams.getCallableFunction('userRegister');
                const result = await register(userInfo);
                console.log("REGISTRATION RESULT", result);
                onContinue();
            } catch (err) {
                // todo: handle error
                console.error("REGISTRATION ERROR", err.message);
                setErrors({ ...errors, registration: true });
            } finally {
                setProgress(false);
            }
        }
    }

    if (result && result.issued) {
        return <img src={`data:image/svg+xml;utf8,${encodeURIComponent(result.qrCode)}`} style={{ width: 200, height: 200 }} alt="qr code" />;
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px", maxWidth: "600px" }}>
            <TextField autoComplete="off" label="Adresă e-mail" readOnly
                value={userInfo.email || ""} onChange={handleChange} />
            {userInfo.verified && <TextField autoComplete="off" label="Nume" readOnly
                value={userInfo.name || ""} onChange={handleChange} />}
            {!userInfo.verified && <>
                <LinearFormControl compact wrap>
                    <TextField autoComplete="off" label="Nume" name="familyName"
                        error={!!errors.familyName} helperText={errors.familyName}
                        value={userInfo.familyName || ""} onChange={handleChange} style={{ flex: "1 200px", marginRight: "1rem" }} />
                    <TextField autoComplete="off" label="Prenume" name="givenName"
                        error={!!errors.givenName} helperText={errors.givenName}
                        value={userInfo.givenName || ""} onChange={handleChange} style={{ flex: "1 200px", marginRight: "1rem" }} />
                </LinearFormControl>
            </>}
            {!userInfo.verified && <>
                <TextField autoComplete="off" label="Parola" type="password" inputProps={{ name: "password" }}
                    error={!!errors.password} helperText={errors.password}
                    value={userInfo.password || ""} onChange={handleChange} />
            </>}

            <Typography style={{marginTop: 32}}>
                Pentru a avea acces la platforma Registreye trebuie să instalați aplicația <b>Verificabil.Eu</b>.
                Veti primi prin email instructiunile necesare pentru a instala aplicatia si a verifica licenta dumneavoastra de libera practica.
            </Typography>

            <LinearFormControl>
                <Checkbox color="primary" checked={termsAccepted} onChange={(e, checked) => setTermsAccepted(checked)} />
                <Typography variant="body2" color="primary">Accept conditiile de utilizare a aplicatiei Registreye</Typography>
            </LinearFormControl>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyContent: "space-between" }}>
            <Button startIcon={<ChevronLeftSharp />} variant="outlined" disableElevation onClick={onBack}>INAPOI</Button>
            <ButtonWithProgress variant="contained" color="primary" disableElevation disabled={progress} showProgress={progress}
                onClick={handleContinue}>CONTINUAȚI</ButtonWithProgress>
        </LinearFormControl>
    </>
};