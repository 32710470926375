import React, { useState, useEffect, useRef, useCallback } from 'react';
import InviteComponent from './invite';
import Panel from 'src/components/layout/panel';
import {
    Button, Table, TableBody, TableRow, TableCell, TableContainer,
    Paper, makeStyles, IconButton, FormControl
} from '@material-ui/core';
import {  MenuOpen, ChevronRight, Check } from '@material-ui/icons';
import FilterHeader from './filterHeader';
import ContentWithSideBarComponent from 'src/components/layout/contentWithActionBar';
import FilterManagementComponent, { DEFAULT_FILTER } from './filterManagement';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import moment from 'moment';
import { delta } from 'src/utils';
import { TitleBar, PercentBar } from 'src/components/widgets';
import useLanguage from '../../translations/useLanguage';

const useStyles = makeStyles((theme) => ({
    checkbox: {
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            }
        },
    },
    c1: {
        color: theme.colorlib.c1.main
    },
    c2: {
        color: theme.colorlib.c2.main
    },
    c3: {
        color: theme.colorlib.c3.main
    },
    c4: {
        color: theme.colorlib.c4.main
    },
    $f: {
        color: theme.colorlib.functionality.main
    },
    noBorderRow: {
        '& .MuiTableCell-root': {
            border: 'none'
        }
    },
    highlightedFilter: {
        backgroundColor: "#f7f7f7",
        '& .MuiTableCell-root': {
            backgroundColor: "#f7f7f7"
        }
    },
    chip: {
        fontWeight: 400,
        margin: theme.spacing(0.5),
        backgroundColor: "#ffffff",
        cursor: "pointer",
        "&.selected": {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            backgroundColor: "#3F00DF1F"
        }
    },
    smallLabel: {
        ...theme.typography.body2
    },
    clickable: {
        cursor: "pointer"
    }
}));

const PatientsComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const firestoreStreams = useFirestoreStreams();
    const [showNewPatient, setShowNewPatient] = useState(false);
    // todo: persist this in the browser
    const [showSidebar, setShowSidebar] = useState(false);
    const [appointmentInfo, setAppointmentInfo] = useState([]);
    const [filterData, setFilterData] = useState(_cloneDeep(DEFAULT_FILTER));
    const [patients, setPatients] = useState();
    const patientsRef = useRef(patients);
    const [showListActions, setShowListActions] = useState(false);
    const [inviteInProgress, setInviteInProgress] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const timerRef = useRef();
    const { translations } = useLanguage();

    useEffect(() => {
        patientsRef.current = patients;
    }, [patients]);
    
    const filterPatients = useCallback((items) => {
        if (!items) return items;
        let _patients = [...items];
        if (_patients) {
            const filters = [];
            const _nameFilter = nameFilter.trim().toLowerCase();
            if (_nameFilter !== "") {
                // need to sort so that we can group similar lookahead terms
                // we will have glitches with strings that share the same root, like John Johnston
                // as Johnston alone will match both
                const nameTokens = _nameFilter.split(" ").filter(t => t !== "").sort();
                let lastToken = "";
                let search = nameTokens.reduce((result, token) => {
                    const append = (token === lastToken) ? `.*${token}` : `)(?=.*${token}`;
                    lastToken = token;
                    return result === "" ? append.substring(1) : result + append;
                }, "");
                search += ")";
                const regex = new RegExp(search, "i");
                filters.push(arr => arr.filter(p => {
                    return regex.test(p.data.displayName) || (p.data.patientName && regex.test(p.data.patientName))
                }));
            }
            if (filterData.c1)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.C1", false) !== false));
            if (filterData.c2)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.C2", false) !== false));
            if (filterData.c3)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.C3", false) !== false));
            if (filterData.c4)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.C4", false) !== false));
            if (filterData.functionality)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.$F", false) !== false));
            if (filterData.lastVisit)
                filters.push(arr => arr.filter(p => _get(p.data, "evaluation.medic.yearMonth", false) !== false));
            
            const thisYear = moment().year();
            // demographic
            if (filterData.demographics)
                filters.push(arr => arr.filter(p => {
                    let matchAll = true;
                    Object.entries(filterData.demographics.data).forEach(([k, v]) => {
                        let isNumeric = false;
                        if (_get(v, "$min-age") || _get(v, "$min")) {
                            isNumeric = true;
                            // number criteria
                            let minValue = parseInt(_get(v, "$min-age") || _get(v, "$min"));
                            let numValue = parseInt(_get(p.data, `profile.demographic.${k}`));
                            if (isNaN(numValue)) {
                                matchAll = false;
                            } else if (_get(v, "$min-age")) {
                                numValue = thisYear - numValue;
                            }
                            matchAll = matchAll && numValue >= minValue;
                        }
                        if (_get(v, "$max-age") || _get(v, "$max")) {
                            isNumeric = true;
                            // number criteria
                            let maxValue = parseInt(_get(v, "$max-age") || _get(v, "$max"));
                            let numValue = parseInt(_get(p.data, `profile.demographic.${k}`));
                            if (isNaN(numValue)) {
                                matchAll = false;
                            } else if (_get(v, "$max-age")) {
                                numValue = thisYear - numValue;
                            }
                            matchAll = matchAll && numValue <= maxValue;
                        }
                        if (!isNumeric) {
                            // option criteria (multiple selection)
                            let match = false;
                            Object.entries(v).forEach(([dk, dv]) => {
                                match = match || (_get(p.data, `profile.demographic.${k}`) === dk);
                            });
                            if (!match) {
                                matchAll = false;
                            }
                        }
                    });
                    return matchAll;
                }))
            
                
            filters.forEach(filter => {
                _patients = filter(_patients);
            });
            _patients.sort((a, b) => {
                if (filterData.lastVisit)
                    return a.data.evaluation.medic.yearMonth > b.data.evaluation.medic.yearMonth ? -1 : 1;
                else
                    return a.data.displayName > b.data.displayName ? 1 : -1
            });
        }
        return _patients;
    }, [nameFilter, filterData]);
    
    const handleSetPatients = useCallback((items) => {
        const summarizeAppointments = (items) => {
            const now = new Date().getTime();
            // if within 15 minutes, we consider it as "now"
            // const nowCutover = now - 1000 * 60 * 15;
            const today = moment(now).startOf("day").valueOf();
            const nextDay = moment(now).startOf("day").add(1, "day").valueOf();
    
            const appointments = [];
            items && items.forEach(p => {
                let appts = [...Object.entries(_get(p.data, "appointments.recent", {})),
                ...Object.entries(_get(p.data, "appointments.upcoming", {}))];
    
                appts = appts.filter(([id, unixDate]) => unixDate > today && unixDate < nextDay);
                appointments.push.apply(appointments, appts.map(([id, unixDate]) => ({
                    id,
                    patient: p,
                    date: moment(unixDate)
                })));
            });
    
            if (delta(appointments, appointmentInfo) !== false) {
                setAppointmentInfo(appointments);
            }
        }

        setPatients({ all: items, filtered: filterPatients(items) });
        summarizeAppointments(items);
    }, [filterPatients, appointmentInfo]);

    const handleSetPatientsRef = useRef(handleSetPatients);
    useEffect(() => {
        handleSetPatientsRef.current = handleSetPatients;
    }, [handleSetPatients]);

    const handleInvite = async (patient) => {
        let result;
        setInviteInProgress(true);
        try {
            const inviteFn = firestoreStreams.getCallableFunction("patientInvite");
            const data = {
                familyName: patient.isCaregiver ? patient.caregiverFamilyName : patient.patientFamilyName,
                givenName: patient.isCaregiver ? patient.caregiverGivenName : patient.patientGivenName,
                patientFamilyName: patient.patientFamilyName,
                patientGivenName: patient.patientGivenName,
                role: patient.isCaregiver ? "caregiver" : "patient",
                email: patient.email
            };
            if (patient.isCaregiver === true) {
                data.patientName = patient.patientName;
            }
            result = await inviteFn(data);
            // todo: handle error or exceptions, display confirmation message
        } catch (err) {
            console.error(err.message);
            result = { error: true };
        } finally {
            setInviteInProgress(false);
        }
        if (!result.error && result.data.error !== true)
            setShowNewPatient(false);
        return result;
    }

    const handleSetFilterData = (filter) => {
        setFilterData(filter || _cloneDeep(DEFAULT_FILTER));
    }

    useEffect(() => {
        if (patientsRef.current) {
            const filtered = filterPatients(patientsRef.current.all);
            setPatients({ all: patientsRef.current.all, filtered: filtered });
        }
    }, [filterData, nameFilter, filterPatients]);

    const handlePatientClick = (patientItem) => {
        history.push({ pathname: `/medic/patient/${patientItem.id}`, state: patientItem });
    }

    useEffect(() => {
        if (firestoreStreams.isInitialized && !firestoreStreams.hasSubscriptions()) {
            firestoreStreams.subscribe("patients", state => {
                // todo: figure out how to manage filters as they are applied
                handleSetPatientsRef.current(state.items);
            });
        }
    }, [firestoreStreams]);

    console.debug("render called in PatientsComponent");

    useEffect(() => {
        if (timerRef.current) {
            console.debug("Refreshing timer in patients view");
            clearInterval(timerRef.current);
        }
        console.debug("Setting timer in patients view");
        timerRef.current = setInterval(() => {/* todo: detect current appointment */ }, 15 * 1000);

        return () => {
            if (timerRef.current) {
                console.debug("Removing timer in patients view");
                clearInterval(timerRef.current);
            }
        }
    })

    // todo: move this out of here, normalize handling of these codes
    const evaluationCodes = ["c1", "c2", "c3", "c4", "$f"];
    const renderContent = () => {
        const filteredPatients = _get(patients, "filtered");
        return <>
            <div style={{ display: "flex", alignItems: "center", justifyItems: "space-between" }}><FilterManagementComponent
                setFilterData={handleSetFilterData}
                filterData={filterData}
                showListActions={showListActions}
                setShowListActions={setShowListActions} />
                {!showSidebar && !showNewPatient && <>
                    <IconButton onClick={() => setShowSidebar(true)}><MenuOpen size="small" /></IconButton>
                    <Button variant="outlined" color="primary" style={{ margin: "0", whiteSpace: "noWrap" }}
                        onClick={() => setShowNewPatient(true)}>{translations.patients.add}</Button>
                </>}
            </div>

            <TableContainer component={Paper} elevation={0} style={{
                width: "auto",
                borderTop: "2px solid #f0f0f0",
                borderRight: "2px solid #f0f0f0",
                borderTopRightRadius: showListActions ? 0 : "1rem", padding: "0 1rem 0 0"
            }}>
                <Table>
                    <FilterHeader classes={classes} filterData={filterData}
                        setNameFilter={setNameFilter} setFilterData={handleSetFilterData} />
                    <TableBody>
                        {filteredPatients && filteredPatients.map(item => {
                            const patient = item.data;
                            // for stats/filtering
                            // todo: for profile, maintain a completion attribute
                            const hasProfile = !!_get(patient, "profile");
                            const evaluation = _get(patient, "evaluation.medic", {});
                            const evalDate = evaluation.yearMonth && moment(evaluation.yearMonth, "YYMM");
                            return <TableRow key={item.id} hover>
                                <TableCell className={classNames(classes.clickable)} onClick={() => handlePatientClick(item)}
                                    style={{ height: "2rem" }}>
                                    {patient.role === 'caregiver' ? patient.patientName : patient.displayName}
                                    {patient.role === 'caregiver' && <><br /><em>{translations.patients.caregiver}: {patient.displayName}</em></>}
                                </TableCell>
                                {evaluationCodes.map(code => {
                                    // todo: remove the uppercase once the codes are normalized
                                    return <TableCell key={code} align="center">
                                        <PercentBar color={code} direction="horizontal"
                                            height="12px" width="80px"
                                            value={_get(evaluation, code.toUpperCase())} />
                                        {/* _get(evaluation, code.toUpperCase(), false) !== false && <Check className={classes[code]} />*/}

                                    </TableCell>
                                })}
                                <TableCell align="center">{hasProfile && <Check />}</TableCell>
                                <TableCell align="center">{evalDate ? evalDate.format("MMMM YYYY") : ""}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    }

    // todo: handle this via redux, persistence, etc...
    return <ContentWithSideBarComponent content={renderContent()}
        
        actionBarProps={{ style: { paddingLeft: "0", paddingRight: "0", flex: "0 0 20rem" } }}
        actionBarContent={(showSidebar || showNewPatient) && <>
            <div style={{ height: "4rem", display: "flex", alignItems: "center", justifyContent: "space-between", marginRight: "1rem" }}>
                <IconButton onClick={() => {
                    setShowSidebar(false);
                    setShowNewPatient(false);
                }}>
                    <ChevronRight size="small" />
                </IconButton>
                {!showNewPatient &&
                    <Button variant="outlined" color="primary" style={{ margin: "0 0 0 1rem" }}
                        onClick={() => setShowNewPatient(true)}>{translations.patients.add}</Button>}
            </div>
            {showNewPatient &&
                <Panel title={translations.patients.add} style={{ margin: "0 1rem 1rem 1rem", alignSelf: "stretch" }}
                    onCancel={() => setShowNewPatient(false)}>
                    <InviteComponent onInvite={handleInvite} saveInProgress={inviteInProgress} />
                </Panel>}
            {!showNewPatient && <div style={{ margin: "0 1rem" }}>
                <TitleBar variant="h6" text={moment().format("dddd, D MMMM")} />
                {appointmentInfo.length === 0 && <FormControl>{translations.patients.noAppointments}</FormControl>}
                <TableContainer component={Paper} elevation={0} style={{ width: "auto", marginTop: "1rem" }}>
                    <Table>
                        <TableBody>
                            {appointmentInfo && appointmentInfo.map(a => {
                                return <TableRow key={a.id} hover onClick={() => handlePatientClick(a.patient)}>
                                    <TableCell>{a.date.format("HH:mm")}</TableCell>
                                    <TableCell align="left">{a.patient.data.displayName}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
        </>} />
}

export default PatientsComponent;