/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState, useEffect, useContext, useRef, useCallback } from 'react';
import RxStreams from './rxStreams';
import { withAuth } from 'src/providers/AuthProvider';

export const FirestoreStreamContext = createContext();
export const FirestoreStreamConsumer = FirestoreStreamContext.Consumer;

export const withFirestoreStreams = Component => props => {
    const firestoreStreams = useFirestoreStreams();

    return <Component {...props} firestoreStreams={firestoreStreams} />
}

export const useFirestoreStreams = () => {
    const contextValue = useContext(FirestoreStreamContext);
    // use ref instead of state, we only mutate this internally
    // and do not need to re-render
    const subscriptionsRef = useRef([]);

    const unsubscribe = useCallback((name) => {
        subscriptionsRef.current.filter(s => s.name === name).forEach(s => s.subscription.unsubscribe());
        subscriptionsRef.current = subscriptionsRef.current.filter(s => s.name !== name);
    }, [subscriptionsRef]);
    
    // Wraps subscribe method of rxStreams in order to clean up subscriptions on unload
    const subscribe = useCallback((name, next, error, complete) => {
        const subscription = contextValue.rxDataSource.subscribe(name, next, error, complete);
        if (subscription) {
            unsubscribe(name);
            subscriptionsRef.current.push({name, subscription});
        }
        return subscription;
    }, [contextValue.rxDataSource, subscriptionsRef, unsubscribe]);

    const hasSubscriptions = useCallback(() => subscriptionsRef.current.length > 0, [subscriptionsRef]);

    const [state, setState] = useState({
        isInitialized: contextValue.rxIsInitialized,
        auth: contextValue.auth,
        ...contextValue.rxDataSource,
        // wrap rxStreams subscribe
        subscribe,
        hasSubscriptions
    });

    useEffect(() => {
        const cleanupSubscriptions = () => {
            if (subscriptionsRef.current) {
                subscriptionsRef.current.forEach(s => s.subscription.unsubscribe());
                subscriptionsRef.current = [];
            }
        }

        cleanupSubscriptions();
        setState({
            isInitialized: contextValue.rxIsInitialized,
            auth: contextValue.auth,
            ...contextValue.rxDataSource,
            // wrap rxStreams subscribe
            subscribe,
            unsubscribeAll: cleanupSubscriptions,
            unsubscribe,
            hasSubscriptions
        });
        return cleanupSubscriptions;
    }, [contextValue, subscribe, hasSubscriptions, unsubscribe]);

    return state;
    
}

export const FirestoreStreamProvider = ({ auth, ...props }) => {
    // we are not using the setter
    const [rxDataSource, /* setrxDataSource */] = useState(new RxStreams(auth));
    const [rxIsInitialized, setRxIsInitialized] = useState(false);

    useEffect(() => {

        const initStaticData = () => {
            // load static data only once, no need to keep an eye on it
            rxDataSource.registerQueryStream('static_data',
                'static', null, null, true, true);
        }

        const initAdminStreams = () => {
            rxDataSource.registerQueryStream('pending-doctor-registrations',
                'users',
                query => query.where('type', '==', 'medic')
                    .where('needsAdminActivation', '==', true),
                null,
                true);
        }

        const initMedicStreams = () => {
            rxDataSource.registerQueryStream('patients',
                'users',
                query => query.where('medicId', '==', auth.user.uid),
                null, // new Map([['active', i => i.data.isActive], ['inactive', i => !i.data.isActive]])
                true);

            rxDataSource.registerQueryStream('patient-filters',
                'filters',
                query => query.where('userId', '==', auth.user.uid),
                null, // new Map([['active', i => i.data.isActive], ['inactive', i => !i.data.isActive]])
                true);
        }

        const initPatientStreams = () => { }

        console.debug("UseEffect hook called in FirestoreStreamProvider", rxIsInitialized);
        if (rxIsInitialized && !auth.isSignedIn) {
            setRxIsInitialized(false);
            rxDataSource.cleanup();
        }

        if (!rxIsInitialized && auth.isSignedIn) {
            initStaticData();

            // we only run through this on second pass, after rxIsInitialied is unset
            switch (auth.idToken.claims.role) {
                case 'admin':
                    initAdminStreams();
                    break;
                case 'medic':
                    initMedicStreams();
                    break;
                case 'patient':
                    initPatientStreams();
                    break;
                default: 
                    break;
            }
            setRxIsInitialized(true);
        }
    }, [auth, rxDataSource, rxIsInitialized]);

    return <FirestoreStreamContext.Provider value={{ rxDataSource, rxIsInitialized, auth }}>
        {props.children}
    </FirestoreStreamContext.Provider>
}

export default withAuth(FirestoreStreamProvider);
