import React, { createContext, useState, useEffect, useContext } from 'react';
import { useFirestoreStreams } from './FirestoreStreamProvider';

export const StaticDataContext = createContext();

export const useStaticData = () => useContext(StaticDataContext);

export const withStaticData = Component => props => {
    const data = useStaticData();
    return <Component {...props} staticData={data} />
}

export const StaticDataConsumer = StaticDataContext.Consumer;

export const StaticDataProvider = (props) => {
    const [data, setData] = useState({ isInitialized: false });
    const firestoreStreams = useFirestoreStreams();

    useEffect(() => {
        const init = async () => {
            if (!firestoreStreams.isInitialized) {
                setData({ isInitialized: false });
            } else {
                firestoreStreams.subscribe("static_data", (state) => {
                    if (state.items.length > 0) {
                        const medicationDoc = state.items.find(i => i.id === 'medication');
                        const demographicDoc = state.items.find(i => i.id === 'demographic_criteria');
                        const surveys = state.items.filter(i => i.data.tag === 'survey');
                        // surveys && surveys.forEach(i => setScoreItems(i.data));
                        setData({
                            isInitialized: true,
                            demographicCriteria: demographicDoc && demographicDoc.data,
                            surveys,
                            medication: medicationDoc && medicationDoc.data
                        });
                    }
                });
            }
        };
        init();
    }, [firestoreStreams, setData]); //eslint-disable-line
    // here above we ignore dependency on effect so cleanup only runs on unmount

    return <StaticDataContext.Provider value={data}>
        {props.children}
    </StaticDataContext.Provider>
}

export default StaticDataProvider;