import React from 'react';
import moment from 'moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Color from 'color';

import AuthProvider from 'src/providers/AuthProvider';
import FirestoreStreamsProvider from 'src/providers/FirestoreStreamProvider';
import GlobalRoute from 'src/components/route/global';
import StaticDataProvider from './providers/StaticDataProvider';
import LanguageProvider from './translations/languageProvider';

const defaultTheme = createTheme();
// set up our own branch of colors
const colors = {
  c1: "#F49FC0",
  c2: "#9ADB9A",
  c3: "#E2CC6F",
  c4: "#7FC6FA",
  functionality: "#008265",
  $f: "#008265",
  $auto: "#c0c0f0",
  autoeval: "#c0c0f0"
};
const colorlib = {
  palette: (colorSpec) => {
    if (colorlib[colorSpec]){
      return colorlib[colorSpec];
    }
    const color = Color(colorSpec);
    return {
      main: color.toString(),
      background: color.alpha(0.3).toString(),
      border: color.lighten(0.1).toString()
    }
  }
}
Object.entries(colors).forEach(([name, color]) => colorlib[name] = colorlib.palette(color));

const theme = createTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
  palette: {
    primary: {
      main: "#484EC7"
    },
  },
  typography: {
    h6: {
      fontSize: "1.1rem"
    }
  },
  colorlib,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        // backgroundColor: "#ffffff",
        color: "#000"
      }
    },
    MuiTableCell: {
      root: {
        padding: "0.4rem"
      }
    },
    MuiTextField: {
      root: {
        // marginLeft: 0
      }
    },
    MuiFormControl: {
      root: {
        marginTop: defaultTheme.spacing(3)
      }
    },
    MuiButton: {
      root: {
        margin: defaultTheme.spacing(2),
        textTransform: "initial",
      }
    },
    // Name of the component ⚛️
    MuiCssBaseline: {
      // Name of the rule
      '@global': {
        '*, *::before, *::after': {
          transition: 'none !important',
          animation: 'none !important',
        },
      },
    },
  },
});

moment.locale('ro');
moment.updateLocale('ro', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
  months: [
    "Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie", "Iulie",
    "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
  ],
  monthsShort: [
    "Ian", "Feb", "Mar", "Apr", "Mai", "Iun", "Iul",
    "Aug", "Sep", "Oct", "Noi", "Dec"
  ],
  weekdays: [
    "Duminică", "Luni", "Marți", "Miercuri", "Joi", "Vineri", "Sâmbătă"
  ],
  weekdaysShort: [
    "Du", "Lu", "Ma", "Mi", "Jo", "Vi", "Sa"
  ],
  weekdaysMin: [
    "D", "L", "Ma", "Mi", "J", "V", "S"
  ],
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <FirestoreStreamsProvider>
          <StaticDataProvider>
            <Router>
              <LanguageProvider>
                <GlobalRoute/>
              </LanguageProvider>
            </Router>
          </StaticDataProvider>
        </FirestoreStreamsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
