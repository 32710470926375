import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

export default ({ showProgress, color, children, ...rest }) => {
    return <Button style={{ position: "relative" }} color={color} {...rest}>
        {children}
        {showProgress && <CircularProgress size="1.5rem"
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-.75rem',
                marginLeft: '-.75rem',
                color
            }}/>}
    </Button>
}