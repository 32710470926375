import React, { useState, useRef, useEffect } from 'react';
import { get as _get } from 'lodash';
import { auth as firebaseAuth, firebase } from 'src/firebase';
import { TextField, Grid, Link, Divider, Button } from '@material-ui/core';
import { LinearFormControl, ButtonWithProgress } from 'src/components/widgets';
import validator from 'validator';
import { useHistory } from 'react-router';
import useLanguage from '../../translations/useLanguage';

export default ({ userInfo, setUserInfo, onContinue }) => {

    const [emailError, setEmailError] = useState(false);
    const oaWindowRef = useRef();
    const oaFrameRef = useRef();
    const oaCallbackRef = useRef();
    const history = useHistory();
    const { translations } = useLanguage();

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // clear error on email
        if (emailError && name === 'email' && validator.isEmail(value.trim())) {
            setEmailError(false);
        }
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    };

    useEffect(() => {
        return () => {
            closeOauthPopup();
        }
    }, []);

    const handleLoginResult = async result => {
        console.log(result)
        const user = result.user;
        const token = await user.getIdTokenResult();
        const profile = _get(result, 'additionalUserInfo.profile', {});
        if (!token.claims.role) {
            setUserInfo({
                ...userInfo,
                verified: true,
                givenName: profile.given_name,
                familyName: profile.family_name,
                name: profile.name,
                email: profile.email,
                credential: result.credential
            });
            onContinue();
        } else {
            // todo: handle email not validated
        }
    }

    const handleGoogleSignIn = () => {
        setUserInfo({ ...userInfo, authenticating: true });
        const provider = new firebase.auth.GoogleAuthProvider();
        firebaseAuth.signInWithPopup(provider).then(handleLoginResult);
    }

    function closeOauthPopup(fireCancelEvent) {
        if (oaFrameRef.current) {
            window.cancelAnimationFrame(oaFrameRef.current);
            oaFrameRef.current = null;
        }
        if (oaWindowRef.current && !oaWindowRef.current.closed) {
            /*if (onClose) {
                onClose();
            }*/
            oaWindowRef.current.close();
        }
        oaWindowRef.current = null;
        // notify opener
        if (fireCancelEvent && oaCallbackRef.current) {
            console.debug("Oauth popup closed");
            window.postMessage({ cancel: true });
        }
        // detach listener
        window.removeEventListener("message", oaCallbackRef.current);
        oaCallbackRef.current = null;
    }

    const handleSignIn = (event) => {
        event.preventDefault();
        const email = (userInfo.email || "").trim();
        if (email === "") {
            setEmailError(translations.loginForm.emailError);
        } else if (!validator.isEmail(email)) {
            setEmailError(translations.loginForm.invalidEmailAddress);
        } else {
            setEmailError(false);
            onContinue();
        }
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px" }}>
            <TextField autoComplete="off" label={translations.loginForm.placeholderEmail} inputProps={{ name: "email" }}
                value={userInfo.email || ""} onChange={handleChange} maxRows={1}
                error={!!emailError} helperText={emailError} />
            <TextField label={translations.loginForm.password} type="password" inputProps={{ name: "password" }}
                helperText={translations.loginForm.passwordHelpText}
                value={userInfo.password || ""} onChange={handleChange} />
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 32, marginBottom: 16 }}>
                <Grid item xs={6}>
                    <ButtonWithProgress variant="contained" color="primary" disableElevation
                        onClick={handleSignIn} style={{ margin: 0 }}>{translations.loginForm.actionButton}</ButtonWithProgress>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Link color="primary" variant="body2" style={{ margin: 0, textAlign: "right", cursor: "pointer" }} onClick={() => history.replace("/password-reset")}>{translations.loginForm.forgotPassword}</Link>
                </Grid>
            </Grid>

        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset" }}>
            <Button style={{ alignSelf: "flex-start" }} variant="outlined" disableElevation
                startIcon={<img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="logo"
                    style={{ width: 18, height: 18, paddingRight: 12 }} />}
                onClick={handleGoogleSignIn}>Google</Button>
        </LinearFormControl>
    </>
};