import React from 'react';
import { Paper, makeStyles, useTheme, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
// internal imports
import { TitleBar } from 'src/components/widgets';

const useStyles = makeStyles((theme) => ({
    panel: {
        borderColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: "1rem 0"
    }
}));

export default ({ title, titleVariant, titleExtraContent, onCancel,
    fitContent, color, style, children, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();

    return <Paper variant="outlined" className={classes.panel} {...rest}
        style={Object.assign(color ? { borderColor: theme.palette[color].main } : {}, style)}>
        <TitleBar text={title} color={color} variant={titleVariant}>
            {titleExtraContent}
            {onCancel && <IconButton onClick={onCancel} style={{ marginRight: "-1rem" }}><Close /></IconButton>}
        </TitleBar>
        {children}
    </Paper>
}