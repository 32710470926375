import React, { useState, useLayoutEffect } from 'react';
import { get as _get } from 'lodash';
import { TextField, Snackbar } from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
// internal imports
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import ActionPanel from 'src/components/layout/panel';
import moment from 'moment';
import { LinearFormControl, ButtonWithProgress } from 'src/components/widgets';
import useLanguage from '../../translations/useLanguage';

/**
 * @param {*} appointment appointment info {id, date, patientId} 
 * @param {*} patient patient info {id, data: {displayName, appointments, etc. doc from users collection}}
 * @param {*} date date that is selected in the calendar (must be moment.startOf("day"))
 * @param {*} action describes the purpose of editing. can be "edit", "new" or "cancel" 
 * @param {*} patients array of patient items {id: , data: from users collection}. used for looking up existing appointments
 * @param {*} timeSlots array of time slots available to select in the time input box (instance of moment)
 * @param {*} setEditAppointment callback for navigating to a different appointment setEditAppointment(appointmentId)
 * @param {*} onCancel callback invoked when the user clicks to close the edit panel
 * @param {*} onDone callback invoked when the intent (action) has completed
 */
export default ({ appointment, patient, lockPatient, date, action, patients, timeSlots, setDate, setEditAppointment, onCancel, onDone }) => {
    /**
     * Currently selected time
     */
    const [selectedTime, setSelectedTime] = useState(appointment ? appointment.date : null);
    /**
     * Selected Patient
     */
    const [selectedPatient, setSelectedPatient] = useState(patient);
    /**
     * Result of action (for display purposes)
     */
    const [doneWithSuccess, setDoneWithSuccess] = useState(null);
    /**
     * Action progress indicator 
     */
    const [actionInProgress, setActionInProgress] = useState(false);
    /**
     * firestore access functions
     */
    const store = useFirestoreStreams();
    const { translations } = useLanguage();

    // updates selected time when the appointment changes
    useLayoutEffect(() => {
        if (appointment) {
            setSelectedTime(appointment.date);
        }
    }, [appointment])

    /**
     * translates current time to a different date (applies hour and minute to the "date" parameter)
     */
    const getTranslatedSelectedTime = () => {
        // we are translating at render time selectedTime to current date
        if (selectedTime && date) {
            const newStartOfDay = date.clone().startOf("day");
            if (!newStartOfDay.isSame(selectedTime.clone().startOf("day"))) {
                return newStartOfDay.set("hour", selectedTime.get("hour")).set("minute", selectedTime.get("minute"));
            }
        }
        return selectedTime;
    }

    /**
     * invoked when the user clicks Cancel, Schedule or Re-Schedule
     */
    const handleSubmit = async () => {
        // appointment data is 
        // {
        //     date: {unixTimeMillis}, medicId: {uid}, patientId: {uid}
        // }
        let promise;
        const newTime = getTranslatedSelectedTime().toDate().getTime();
        setActionInProgress(true);
        switch (action) {
            case "new":
                promise = store.saveItem("appointments", null, {
                    date: newTime,
                    patientId: selectedPatient.id
                });
                break;
            case "edit":
                promise = store.updateItem("appointments", appointment.id, {
                    date: newTime
                });
                break;
            case "cancel":
                promise = store.updateItem("appointments", appointment.id, {
                    isCanceled: true
                });
                break;
            default:
                break;
        }
        // todo: handle save errors or display a message
        await promise;
        setDoneWithSuccess(true);
        setActionInProgress(false);
    }

    /**
     * Invoked at the end of the Snack display time, so we can inform the parent that we are done
     */
    const handleSnackClose = () => {
        if (doneWithSuccess && onDone) {
            onDone();
        } else {
            setDoneWithSuccess(null);
        }
    }

    // we are translating at render time selectedTime to current date
    let _selectedTime = getTranslatedSelectedTime();
    let timeWarning = false;
    let timeError = false;

    // see if current time is selectable for current day
    const isInvalidSlot = _selectedTime &&
        (timeSlots.length === 0
            || _selectedTime.isBefore(timeSlots[0])
            || _selectedTime.isAfter(timeSlots[timeSlots.length - 1]));

    if (isInvalidSlot && (action === "new" || !_selectedTime.isSame(appointment.date))) {
        timeError = translations.appointmentEdit.noIntervalError;
    }

    let canSave = doneWithSuccess === null
        && !timeError
        && !actionInProgress
        && selectedPatient
        && _selectedTime
        && (!appointment || !_selectedTime.isSame(appointment.date));

    // see if we have future appointments
    if (action === 'new' && selectedPatient) {
        const upcoming = Object.entries(_get(selectedPatient, "data.appointments.upcoming", {})).sort((a, b) => a[1].date > b[1].date ? 1 : -1);
        if (upcoming.length > 0) {
            const upcomingEntry = upcoming[0];
            const upcomingDate = moment(upcomingEntry[1]);
            timeWarning = <>{translations.appointmentEdit.patientHasAppointmentError}<br />
                <b style={{ cursor: "pointer" }} onClick={() => setEditAppointment(upcomingEntry[0])}>{upcomingDate.format(`dddd, D MMMM ${translations.appointmentEdit.atHour} H:mm`)}</b></>;
        }
    }

    // useful info to display on top
    let appointmentInfo = false;
    if (action === 'edit') {
        if (appointment && (!_selectedTime || !appointment.date.isSame(_selectedTime))) {
            appointmentInfo = <>Programarea Initiala:&nbsp;
                <b style={{ cursor: "pointer" }} onClick={() => {
                    setSelectedTime(appointment.date);
                    setDate(appointment.date.clone().startOf("day"));
                }}>{appointment.date.format("dddd, D MMMM / H:mm")}</b></>
        } else {
            appointmentInfo = "Pentru modificare selectati o alta zi din calendar si/sau editati ora programarii";
        }
    }

    // content to embed in the edit panel
    const content = <div>
        <Autocomplete
            disabled={action === 'cancel'}
            value={_selectedTime}
            noOptionsText="Nimic de selectat"
            options={timeSlots}
            onChange={(evt, val) => setSelectedTime(val)}
            getOptionSelected={(option, val) => option.isSame(val)}
            getOptionLabel={(option) => option.format("HH:mm")}
            renderInput={(params) => <TextField {...params}
                helperText={timeError}
                error={!!timeError}
                label="Ora"
                variant="standard" />}
        />

        {(!!appointment || lockPatient) && <><TextField label="Nume Pacient"
            autoComplete="off"
            variant="standard"
            color="primary"
            InputProps={{ name: "patientName", readOnly: true, disableUnderline: true }}
            value={patient.data.displayName}
            fullWidth />
        </>}

        {!appointment && !lockPatient &&
            <Autocomplete
                noOptionsText="Nimic de selectat"
                options={patients}
                onChange={(evt, val) => setSelectedPatient(val)}
                value={selectedPatient || false}
                getOptionSelected={(patient, val) => patient && val && patient.id === val.id}
                getOptionLabel={(option) => option ? option.data.displayName : ""}
                renderInput={(params) => <TextField {...params}
                    label="Pacient"
                    variant="standard" />}
            />}

        <Snackbar open={doneWithSuccess !== null} autoHideDuration={1500} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={doneWithSuccess ? "success" : "error"}
                variant="filled" elevation={6}>
                {/* todo: improve this logic, read from variable/map */}
                {doneWithSuccess && action === 'new' && "Programarea a fost facută"}
                {doneWithSuccess && action === 'edit' && "Programarea a fost modificată"}
                {doneWithSuccess && action === 'cancel' && "Programarea a fost anulata"}
                {!doneWithSuccess && action === 'new' && "Programarea nu a putut fi facută"}
                {!doneWithSuccess && action === 'edit' && "Programarea nu a putut fi modificată"}
                {!doneWithSuccess && action === 'cancel' && "Programarea nu a putut fi anulata"}
            </Alert>
        </Snackbar>

        <LinearFormControl centered>
            {action === 'new' && <ButtonWithProgress variant="contained" disabled={!canSave} 
                showProgress={actionInProgress}
                color="primary" onClick={handleSubmit} disableElevation>Programeaza</ButtonWithProgress>}
            {action === 'edit' && <ButtonWithProgress variant="contained" disabled={!canSave} 
                showProgress={actionInProgress}
                color="primary" onClick={handleSubmit} disableElevation>Reprogrameaza</ButtonWithProgress>}
            {action === 'cancel' && <ButtonWithProgress variant="contained" 
                showProgress={actionInProgress}
                color="secondary" onClick={handleSubmit} disableElevation>Anuleaza</ButtonWithProgress>}
        </LinearFormControl>
    </div>;

    return <>
        {appointmentInfo && <Alert severity="info" style={{ marginBottom: "1rem" }}>{appointmentInfo}</Alert>}
        {timeWarning && <Alert severity="warning" style={{ marginBottom: "1rem" }}>{timeWarning}</Alert>}
        <ActionPanel
            onCancel={onCancel}
            title={date.format("dddd, D MMMM") /* actionTitles[sidebarContext.params.action]*/}
            titleVariant="h6"
            color={action === 'cancel' ? "secondary" : "primary"}
            style={{ marginTop: 0 }}>{content}</ActionPanel>
    </>
}