import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { ButtonWithProgress, LinearFormControl } from 'src/components/widgets';
import { Divider, Button } from '@material-ui/core';
import { ChevronLeftSharp } from '@material-ui/icons';

export default ({ onContinue, onBack }) => {
    const [progress, /*setProgress*/] = useState(false);

    const handleContinue = async () => {
        onContinue();
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px", maxWidth: "600px" }}>
            <Typography style={{marginTop: 32}}>
                Pentru a avea acces la platforma Registreye trebuie să validați licența dumneavoastră de liberă practică,
                cu aplicația <b>verificabil.eu</b>.
            </Typography>
            <Typography style={{marginTop: 32}}>
                Daca identitatea v-a fost deja verificata, continuați si scanați codul QR afisat pe ecran.
            </Typography>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyContent: "space-between" }}>
            <Button startIcon={<ChevronLeftSharp />} variant="outlined" disableElevation onClick={onBack}>INAPOI</Button>
            <ButtonWithProgress variant="contained" color="primary" disableElevation disabled={progress} showProgress={progress}
                onClick={handleContinue}>CONTINUAȚI</ButtonWithProgress>
        </LinearFormControl>
    </>
};