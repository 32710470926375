import React from 'react';
import { LinearFormControl } from 'src/components/widgets';
import { Divider, Button } from '@material-ui/core';
import { ChevronLeftSharp } from '@material-ui/icons';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import VerificabilProof from './VerificabilProof';

export default ({ onTokenResult, onBack }) => {
    const firestoreStreams = useFirestoreStreams();

    const handleProofReceived = async (verifier, authorization) => {
        try {
            const getToken = firestoreStreams.getCallableFunction('verificabilUpdateMedicGetToken');
            const tokenResponse = await getToken({ verifier, authorization });
            const {token, error} = tokenResponse.data;
            onTokenResult(token, error);
        } catch (err) {
            // todo: handle error
            console.error("SIGN IN ERROR", err.message);
            onTokenResult(false, err.message);
            // setErrors({ ...errors, registration: true });
        } finally {
            // setProgress(false);
        }
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 32px 32px", maxWidth: "600px" }}>
            <VerificabilProof oaScope={process.env.REACT_APP_VERIFICABIL_OA_SCOPE_MEDIC} style={{height: 300, border: "none"}}
                onProofReceived={handleProofReceived}/>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyContent: "space-between" }}>
            <Button startIcon={<ChevronLeftSharp />} variant="outlined" disableElevation onClick={onBack}>INAPOI</Button>
        </LinearFormControl>
    </>
};