import { Snackbar, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, useContext } from 'react';
import { AuthContext } from 'src/providers/AuthProvider';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import { ButtonWithProgress, TitleBar } from '../widgets';
import useLanguage from '../../translations/useLanguage';

const ProfileComponent = () => {
    const auth = useContext(AuthContext);
    const [progress, setProgress] = useState(false);
    const [actionStatus, setActionStatus] = useState(null);
    const firestoreStreams = useFirestoreStreams();
    const { translations } = useLanguage();

    const handleSnackClose = async () => {
        setActionStatus(null);
    }

    const handlePasswordReset = async () => {
        setProgress(true);
        try {
            const passwordReset = firestoreStreams.getCallableFunction('passwordReset');
            const result = await passwordReset({});
            setActionStatus("success");
            console.log("RESET RESULT", result);
        } catch (err) {
            setActionStatus("error");
            // todo: handle error
            console.error("RESET ERROR", err.message);
            setProgress(false);
        } finally {
            setProgress(false);
        }
    }

    /*return <div style={{ height: "calc(100vh - 64px)", minHeight: 500, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
            <Paper elevation={0} className={classes.formPaper}><div>{auth.idToken.claims.role}</div></Paper>
        </div>*/

    return <div style={{ padding: "20px 40px" }}>
        <TitleBar variant="h5" text={translations.profile.profile}></TitleBar>
        <TextField label={translations.profile.name}
            autoComplete="off"
            variant="standard"
            color="primary"
            InputProps={{ name: "name", readOnly: true, disableUnderline: true }}
            value={auth.idToken.claims.name}
            fullWidth />
        <TextField label={translations.profile.role}
            autoComplete="off"
            variant="standard"
            color="primary"
            InputProps={{ name: "role", readOnly: true, disableUnderline: true }}
            value={auth.idToken.claims.role}
            fullWidth />
        <TextField label={translations.profile.email}
            autoComplete="off"
            variant="standard"
            color="primary"
            InputProps={{ name: "email", readOnly: true, disableUnderline: true }}
            value={auth.idToken.claims.email}
            fullWidth />
        <ButtonWithProgress style={{ margin: "20px 0"}} variant="contained" color="primary" disableElevation disabled={progress} showProgress={progress}
         onClick={handlePasswordReset}>{translations.profile.resetPassword}</ButtonWithProgress>
        
        <Snackbar open={actionStatus !== null} autoHideDuration={1500} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity={actionStatus ? "success" : "error"}
                variant="filled" elevation={6}>
                {/* todo: improve this logic, read from variable/map */}
                {actionStatus === "success" ? translations.profile.passwordResetHelperText : translations.profile.passwordResetError}
            </Alert>
        </Snackbar>
        
    </div>
}

export default ProfileComponent;