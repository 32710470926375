import React from 'react';
import { withAuth } from 'src/providers/AuthProvider';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({ auth, authRoles, children, path, exact, component, render, location }) => {
    // console.debug(`rendering private route ${JSON.stringify(location)}`);

    // we do this rather than in sign in in order to avoid an unnecessary redirect.
    if (auth.isInitializing)
        return <div>Please wait (PRIVATE ROUTE)...</div>;

    let isAuthorized = auth.isSignedIn;
    if (isAuthorized && Array.isArray(authRoles) && auth.idToken.claims.role) {
        isAuthorized = authRoles.find(r => auth.idToken.claims.role)
    }

    if (!auth.isSignedIn)
        return <Redirect to={{
            pathname: "/signin",
            state: { referrer: location.pathname }
        }} />;

    return isAuthorized ? <Route
        path={path}
        component={component}
        render={render}
        exact={exact}>
        {children}
    </Route> : <div>NOT AUTHORIZED</div>
}


export default withAuth(PrivateRoute);