import React, { useState, useContext, useEffect } from 'react';
import { Paper, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

import EmailInput from './EmailInput';
import { AuthContext } from 'src/providers/AuthProvider';
import Final from './Final';

const useStyles = makeStyles(theme => ({
    nonEssential: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "unset"
        }
    },
    formPaper: {
        minWidth: 500
    }
}));

export default () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));

    const [currentStep, setCurrentStep] = useState('email-input');
    const auth = useContext(AuthContext);

    useEffect(() => {
        if (auth.isSignedIn && auth.idToken.claims.role) {
            history.replace("/");
        }
    }, [auth.isSignedIn, auth.idToken, history])

    const handleContinue = async (data) => {
        switch (currentStep) {
            case 'email-input':
                setCurrentStep('final');
                break;
            default:
                break;
        }
    }

    // const handleStepBack = () => {
    //     switch (currentStep) {
    //         case 'final':
    //             setCurrentStep('email-input');
    //             break;
    //         default:
    //             break;
    //     }
    // }

    const stepInfo = {
        "email-input": { label: "Resetare Parolă" },
        "final": { label: "Confirmare" }
    }

    const title = <Typography variant="h5" style={{ padding: "16px 32px 0 32px" }}>
        {stepInfo[currentStep].label}
    </Typography>;

    let content;
    switch (currentStep) {
        case 'email-input':
            content = <EmailInput onContinue={handleContinue} onBack={() => history.replace("/signin")}/>;
            break;
        case 'final':
            content = <Final onContinue={() => {
                history.replace("/signin")
            }} />;
            break;
        default: break;
    }

    if (auth.isInitializing) {
        return <></>;
    }

    if (!smMatch) {
        return <>{title}{content}</>
    } else {
        return <div style={{ height: "calc(100vh - 64px)", minHeight: 500, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
            <Paper elevation={0} className={classes.formPaper}>{title}{content}</Paper>
        </div>
    }
}