import React from 'react';
import { Typography, Button, Divider } from '@material-ui/core';
import { ChevronRightSharp, ChevronLeftSharp } from '@material-ui/icons';
import { LinearFormControl } from '../widgets';

export default ({ onContinue, onBack }) => {
    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "16px 32px 16px 32px", alignItems: "center" }}>
            <Typography variant="subtitle1" style={{alignSelf: "flex-start"}}>Pentru a continua, alegeți una din optiunile de mai jos</Typography>
            <Button variant="contained" disableElevation endIcon={<ChevronRightSharp />} color="primary"
                onClick={() => onContinue('medic')}
                style={{ margin: 0, width: 320, marginTop: 32, justifyContent: "space-between" }}>
                Sunt un Medic
                </Button>
            <Button variant="contained" disableElevation endIcon={<ChevronRightSharp />} color="primary"
                onClick={() => onContinue('patient')}
                style={{ margin: 0, width: 320, marginTop: 16, justifyContent: "space-between" }}>
                Sunt o Persoana Diagnosticata
        </Button>
            <Button variant="contained" disableElevation endIcon={<ChevronRightSharp />} color="primary"
                onClick={() => onContinue('caregiver')}
                style={{ margin: 0, width: 320, marginTop: 16, marginBottom: 16, justifyContent: "space-between" }}>
                Am grija de o Persoana Diagnosticata
        </Button>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", justifyContent: "space-between", width: "unset" }}>
            <Button startIcon={<ChevronLeftSharp />}
                variant="outlined" disableElevation onClick={onBack}>INAPOI</Button>
        </LinearFormControl>
    </>
};