export default {
  en: {
    steps: {
      signIn: "Login",
      chooseRole: "Welcome",
      registerMedic: "Register - Medic",
      registerPatient: `Register`,
      finalPatient: "Congratulations!",
      finalMedic: "Congratulations!"
    },
    scheduler: {
      addAppointment: 'Add appointment',
    },
    profile: {
      name: 'Name',
      role: 'Role',
      email: 'Email',
      title: 'Profile',
      resetPassword: 'Reset password',
      passwordResetHelperText: 'The email for password reset was send.',
      passwordResetError: 'Error! The email for password reset was not send.',
    },
    patientDetail: {
      caregiver: 'Caregiver: ',
      patient: 'Patient',
      lastAppointment: "Last appointment",
      nextAppointment: "Next appointment",
      addAppointment: 'Add appointment',
      noPatientSelectedError: 'You did not selected any patient'
    },
    filterManagement: {
      allPatients: 'All Patients',
      newList: 'New List',
      filterError: 'The list could not be saved',
      listExistsError: 'A list with this name already exists',
      save: 'Save',
      cancelChanges: 'Cancel changes',
      copy: " Copy",
      listName: "List`s name",
      deleteList: "Delete List",
      deleteForever: 'Delete forever',
      delete: 'Delete',
    },
    filterHeader: {
      searchByName: "Cautare dupa nume",
      demographicProfile: 'Demographic profile',
      lastVisit: 'Last Visit',
      name: 'Name',
      functionality: 'Functionality'
    },
    addPatient: {
      lastName: `Patient's last name`,
      firstName: `Patient's first name`,
      email: `Patient's email`,
      hasCaregiver: 'Has caregiver',
      actionButton: 'Add',
      caregiverName: `Caregiver's name`,
      caregiverEmail: `Caregiver's email`,
      caregiverFirstName: `Caregiver's first name`,
      emailError: "Invalid email",
      emailErrorRegistered: 'Email already registered',
      helperPatientsFamilyName: `Add patient's last name`,
      patientHelperTextGiverName: `Add patient's first name`,
      caregiverHelperTextFamilyName: `Add caregiver's last name`,
      caregiverHelperGivenName: `Invalid caregiver's first name`,
    },
    patients: {
      add: 'Add pacient',
      noAppointments: 'No scheduled appointments for today',
      caregiver: 'Caregiver'
    },
    navbar: {
      login: 'Login',
      patients: 'Patients',
      calendar: 'Calendar'
    },
    loginForm: {
      title: 'Sign in',
      placeholderEmail: 'Email address',
      password: 'Password',
      passwordHelpText: 'You are not registered? Continue without a password.',
      actionButton: 'CONTINUE',
      forgotPassword: 'Forgot password?',
      back: 'BACK',
      emailError: 'Invalid email address',
      invalidEmailAddress: 'Invalid email address'
    }
  },
  ro: {
    navbar: {
      login: 'Intră în cont',
      patients: 'Pacienți',
      calendar: 'Calendar'
    },
    loginForm: {
      title: 'Conectați-vă',
      placeholderEmail: 'Adresă e-mail',
      password: 'Parolă',
      passwordHelpText: 'Nu sunteți înregistrat? Continuați fără a introduce o parolă.',
      actionButton: 'CONTINUAȚI',
      forgotPassword: 'Ați uitat parola?',
      emailError: 'Introduceți o adresa de e-mail',
      invalidEmailAddress: 'Adresa nu este validă',
      back: 'ÎNAPOI'
    },
    patients: {
      add: 'Adaugă Pacient',
      noAppointments: 'Nimic programat pentru azi',
      caregiver: 'Aparţinător'
    },
    addPatient: {
      lastName: `Nume pacient`,
      firstName: 'Prenume pacient',
      email: 'Email pacient',
      hasCaregiver: 'Are apartinator',
      actionButton: 'Add',
      caregiverName: 'Nume aparţinător',
      caregiverEmail: 'Email aparţinător',
      caregiverFirstName: 'Prenume Aparţinător',
      emailError: "Introduceţi o adresa de email validă",
      emailErrorRegistered: "Adresa este deja inregistrată",
      helperPatientsFamilyName: "Introduceţi numele pacientului",
      patientHelperTextGiverName: "Introduceţi prenumele pacientului",
      caregiverHelperTextFamilyName: "Introduceti numele apartinatorului",
      caregiverHelperGivenName: `Introduceţi prenumele aparţinătorului`,
    },
    filterManagement: {
      allPatients: 'Toti Pacientii',
      newList: 'Lista noua...',
      filterError: 'Lista nu a putut fi salvată',
      listExistsError: "O lista cu acest nume exista deja",
      save: 'Salvează',
      cancelChanges: 'Anuleaza Modificări',
      copy: " Copie",
      listName: "List`s name",
      deleteList: "Stergere Lista",
      deleteForever: 'Sterge Definitiv',
      delete: 'Sterge',
    },
    filterHeader: {
      searchByName: "Cautare dupa nume",
      demographicProfile: 'Profil Demografic',
      lastVisit: 'Ultima Vizită',
      name: 'Nume',
      functionality: 'Funcționalitate'
    },
    profile: {
      name: 'Nume',
      role: 'Rol',
      email: 'Email',
      title: 'Profil utilizator',
      resetPassword: 'Resetare parolă',
      passwordResetHelperText: "Emailul pentru resetarea parolei a fost trimis",
      passwordResetError: "Eroare! Emailul pentru resetarea parolei nu a fost trimis."
    },
    patientDetail: {
      caregiver: 'Apartinator: ',
      patient: 'Pacient',
      lastAppointment: "Ultima consultatie",
      nextAppointment: "Urmatoarea Consultație",
      addAppointment: 'Adaugă Programare',
      noPatientSelectedError: 'Nu ati selectat niciun pacient'
    },
    scheduler: {
      addAppointment: 'Adaugă Programare',
    },
    appointmentEdit: {
      noIntervalError: "Interval indisponibil pentru aceasta zi",
      patientHasAppointmentError: 'Pacientul mai are o programare',
      atHour: '[la ora]'
    },
    steps: {
      signIn: "Conectați-vă",
      chooseRole: "Bine ati venit",
      registerMedic: "Înregistrare - Medic",
      registerPatient: `Înregistrare`,
      finalPatient: "Felicitări!",
      finalMedic: "Felicitări!"
    },
  }
  
};