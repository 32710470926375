import  _ from 'lodash';
import firebase from "firebase/app";
import "firebase/firestore";
import moment from 'moment';

export const isEmptyOrBlank = (value) => {
  return _.isEmpty(value) || value.toString().trim() === '';
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}

/**
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const delta = (obj1, obj2, parentPath, result, isRemoveBranch = false) => {
  result = result || { add: [], remove: [], change: [] };
  const valueKey = isRemoveBranch ? "remove" : "add";

  if (_.isNil(obj2)) {
    if (_.isNil(obj1) || Array.isArray(obj1))
      return false;
    else if (_.isObject(obj1)) {
        Object.keys(obj1).forEach(key => result[valueKey].push(key));
        return result;
    }
  }

  const returnValue = _.transform(obj1, (result, value, key) => {
    const keyWithPath = parentPath ? `${parentPath}.${key}` : key;
    
    if (!obj2.hasOwnProperty(key)) {
      result[valueKey].push(keyWithPath);
    } else if (!_.isEqual(value, obj2[key])) {
      // if both values are objects, we dive
      // we treat arrays as atomic values
      if (_.isObject(value) && _.isObject(obj2[key])) {
        delta(value, obj2[key], keyWithPath, result, isRemoveBranch);
      } else if (!isRemoveBranch) {
        // otherwise we just flag it as a change
        result.change.push(keyWithPath);
      }
    }
    return result;
  }, result);

  // now see what is in obj2 that is not in obj1
  if (!_.isNil(obj1) && !isRemoveBranch) {
    _.transform(obj2, (result, value, key) => {
      const keyWithPath = parentPath ? `${parentPath}.${key}` : key;
      if (!obj1.hasOwnProperty(key)) {
        result.remove.push(keyWithPath);
      } else if (_.isObject(obj1[key]) && _.isObject(value)) {
        // if both values are objects, we dive,
        // we would have captured changes/additions in previous step, now we capture only missing values
        delta(value, obj1[key], keyWithPath, result, true);
      }
      return result;
    }, result);
  }

  return (result.add.length === 0 && result.change.length === 0 && result.remove.length === 0) ? false : returnValue;
}

/**
 * Returns Firestore-compatible update document for changing obj2 into obj1
 * @param {*} obj1 new version of object
 * @param {*} obj2 old versin of object
 */
export const firestoreDelta = (obj1, obj2, pathPrefix) => {
  // for brand new object, return the actual object
  if (_.isNil(obj2)){
    return pathPrefix ? {[pathPrefix]: obj1} : obj1;
  }

  pathPrefix = pathPrefix || "";
  if (!pathPrefix.endsWith(".") && pathPrefix !== "") {
    pathPrefix += ".";
  }
  const result = delta(obj1, obj2);
  if (result === false) {
    return false;
  }
  const returnValue = {};
  result.add.forEach(path => returnValue[`${pathPrefix}${path}`] = _.get(obj1, path));
  result.change.forEach(path => returnValue[`${pathPrefix}${path}`] = _.get(obj1, path));
  result.remove.forEach(path => returnValue[`${pathPrefix}${path}`] = firebase.firestore.FieldValue.delete());
  return returnValue;
}

/**
 * Will filter an object tree based on provided predicate
 * @param collection
 * @param predicate
 * @param thisArg
 */
export const pickDeep = (collection, predicate, thisArg, ...rest) => {
  if (_.isNil(predicate))
    predicate = (val) => { return _.isBoolean(val) || _.isFinite(val) ? true : !_.isEmpty(val); };
  if (_.isFunction(predicate)) {
    predicate = _.iteratee(predicate, thisArg);
  } else {
    var keys = _.flatten(_.rest([collection, predicate, thisArg, ...rest]));
    predicate = function (val, key) {
      return _.contains(keys, key);
    }
  }

  return _.transform(collection, (memo, val, key) => {
    var include = predicate(val, key);
    if (include && _.isObject(val)) {
      val = pickDeep(val, predicate);
      include = !_.isEmpty(val);
    }

    if (include) {
      _.isArray(collection) ? memo.push(val) : memo[key] = val;
    }
  });
}

export const calendar = (time) => {
  const momentTime = moment(time);
  return !time ? '-' : moment(time).calendar({
    sameDay: '[Astăzi la] H:mm',
    nextDay: '[Mâine la] H:mm',
    nextWeek: 'dddd [la] H:mm',
    lastDay: '[Ieri]',
    lastWeek: 'dddd, D MMMM',
    sameElse: now => momentTime.isBefore(now) ? 'dddd, D MMMM' : 'dddd, D MMMM [la] H:mm'
  })
}