import React from 'react';
import {makeStyles} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flex: "1",
        margin: "0 1rem 1rem 2rem"
    },
    actionBar: {
        display: "flex",
        flexDirection: "column",
        flex: "0.25",
        alignItems: "stretch"
    }
}));

const ContentWithActionBarComponent = ({content, contentProps, actionBarContent, actionBarProps}) => {
    const classes = useStyles();
    const barProps = actionBarProps || {};
    contentProps = contentProps || {};
    return <div className={classes.root}>
        <div className={classnames(classes.content, contentProps && contentProps["className"])} {...contentProps}>{content}</div>
        {actionBarContent && <div className={classnames(classes.actionBar, barProps["className"])}
            {...barProps}>{actionBarContent}</div>}
    </div>
}

export default ContentWithActionBarComponent;