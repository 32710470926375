import UKFlagImg from '../images/uk.png';
import ROFlagImg from '../images/ro.png';
import React, { createContext, useEffect, useState } from 'react';
import localTranslations from './localTranslations';

export const languages = [{
  id: 'en',
  image: UKFlagImg,
  text: 'Engleză'
}, {
  id: 'ro',
  image: ROFlagImg,
  text: 'Română'
},];

const LOCAL_STORAGE_LANGUAGE_KEY = 'registreye_language';
const defaultLanguage = languages[1].id;

export const LanguageContext = createContext({
  translations: localTranslations[defaultLanguage],
  setLanguage: () => {
  },
  language: defaultLanguage,
});

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);
  
  useEffect(() => {
    try {
      setLanguage(localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) ?? defaultLanguage);
    } catch (e) {
      console.log(e);
    }
  }, []);
  
  return (
    <LanguageContext.Provider value={{
      translations: localTranslations[language],
      language,
      setLanguage,
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;