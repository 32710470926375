import React, { useEffect, useState } from 'react';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Paper, Button } from '@material-ui/core';

const UsersComponent = () => {
    // todo: track loading state
    const [users, setUsers] = useState(null);
    const firestoreStreams = useFirestoreStreams();

    useEffect(() => {
        console.debug("useEffect called in UsersComponent");
        
        if (firestoreStreams.isInitialized) {
            firestoreStreams.subscribe('pending-doctor-registrations',
            state => {
                setUsers(state.items);
            });
        }
    }, [firestoreStreams]);

    const onAdminActivate = async (userId) => {
        const activate = firestoreStreams.getCallableFunction('userActivate');
        const actResult = await activate({uid: userId});
        console.info("ACTIVATION RESULT", actResult);
    }

    if (users == null) {
        return "Loading users...";
    }

    return users.length === 0 ? <div>Nimic de afisat</div> :
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Nume</TableCell>
            <TableCell align="right">Data Înregistrare</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((u) => (
            <TableRow hover key={u.id}>
              <TableCell>{u.data.displayName}</TableCell>
              <TableCell align="right">{u.data.dateRegistered}</TableCell>
              <TableCell><Button onClick={() => onAdminActivate(u.id)}>Activeaza</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
}

export default UsersComponent;