import React, { useState, useRef, useCallback } from 'react';
import { TableHead, TableRow, TableCell, Checkbox, Chip, FormControlLabel, Typography, InputBase, TextField } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox, PlaylistAddCheck, PlaylistAdd, Search } from '@material-ui/icons';
import clsx from 'clsx';
import {
    cloneDeep as _cloneDeep,
    get as _get, set as _set, unset as _unset,
    debounce as _debounce, isUndefined as _isUndefined
} from 'lodash';
import { useStaticData } from 'src/providers/StaticDataProvider';
import useLanguage from '../../translations/useLanguage';

const FilterHeaderComponent = ({ classes, filterData, setFilterData, setNameFilter }) => {
    const staticData = useStaticData();
    const [showDemographics, setShowDemographics] = useState(false);
    const nameFilterRef = useRef("");
    const { translations } = useLanguage();

    const handleCheck = (event) => {
        const name = event.target.name;
        const newData = { ...filterData, [name]: event.target.checked };
        setFilterData(newData);
    }

    const toggleDemographics = () => {
        setShowDemographics(!showDemographics);
    }

    const handleChipClick = (attributeKey) => {
        const newFilterData = _cloneDeep(filterData);
        const showChipPath = ['demographics', 'show', attributeKey];
        const chipSelected = _get(newFilterData, showChipPath) === true;
        if (chipSelected) {
            _unset(newFilterData, showChipPath);
            // clear data as well
            _unset(newFilterData, ['demographics', 'data', attributeKey]);
        }
        else
            _set(newFilterData, showChipPath, true);
        setFilterData(newFilterData);
    }

    const setDemographicFilterValue = (remove, attributeKey, valueKey, value) => {
        const newFilterData = _cloneDeep(filterData);
        const valuePath = ['demographics', 'data', attributeKey, valueKey];
        const attributePath = ['demographics', 'data', attributeKey];
        // if value is not provided, we set the value of [valueKey] to true
        if (!remove && _isUndefined(value))
            value = true;
        if (remove) {
            if (Object.keys(_get(newFilterData, attributePath))?.length > 1) {
                _unset(newFilterData, valuePath);
            } else {
                _unset(newFilterData, attributePath);
            }

        } else {
            _set(newFilterData, valuePath, value);
        }
        setFilterData(newFilterData);
    }

    const debounceNameFilterChange = useCallback(_debounce(() => {
        console.log("debouncing on", nameFilterRef.current);
        setNameFilter(nameFilterRef.current);
    }, 500, { trailing: true, maxWait: 3000 }), [nameFilterRef]);

    console.debug("render called in patients/FilterHeaderComponent");
    const hasDemographicsCriteria = Object.keys(filterData.demographics.data).length > 0;
    const demographicsIcon = hasDemographicsCriteria ? <PlaylistAddCheck color="primary" /> : <PlaylistAdd />;
    if (!staticData.isInitialized) {
        return <></>;
    }
    const _surveys = staticData.surveys.filter(s => s.data.type === 'doctor');
    return (
        <TableHead>
            <TableRow className={classes.noBorderRow}>
                <TableCell style={{ minWidth: "15rem" }}>{translations.filterHeader.name}</TableCell>
                {_surveys.map(({ id, data }) =>
                    <TableCell key={id} align="center">{data.title}</TableCell>)}
                <TableCell align="center">{translations.filterHeader.functionality}</TableCell>
                <TableCell align="center" style={{ whiteSpace: "noWrap" }}>{translations.filterHeader.demographicProfile}</TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>{translations.filterHeader.lastVisit}</TableCell>
            </TableRow>
            <TableRow className={clsx(showDemographics && classes.noBorderRow)}>
                <TableCell>
                    <InputBase margin="dense"
                        fullWidth
                        endAdornment={<Search style={{ opacity: 0.3 }} />}
                        placeholder={translations.filterHeader.searchByName}
                        onKeyPress={event => {
                            const re = /[a-zA-Z0-9 \-']+/g;
                            if (!re.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={event => {
                            // we store name filter in a ref so it can be used by debounce
                            nameFilterRef.current = event.target.value;
                            debounceNameFilterChange();
                        }} />
                </TableCell>
                {_surveys.map(({ id }) =>
                    <TableCell align="center" key={id}>
                        <Checkbox
                            size="small"
                            className={classes.checkbox}
                            name={id.toLowerCase()}
                            checked={filterData[id.toLowerCase()]}
                            onChange={handleCheck}
                            checkedIcon={<CheckBox className={classes[id.toLowerCase()]} />}
                            icon={<CheckBoxOutlineBlank className={classes[id.toLowerCase()]} />}
                        /></TableCell>)}
                <TableCell align="center"><Checkbox size="small"
                    className={classes.checkbox}
                    name="functionality"
                    checked={filterData.functionality}
                    checkedIcon={<CheckBox className={classes.$f} />}
                    onChange={handleCheck}
                    icon={<CheckBoxOutlineBlank className={classes.$f} />} />
                </TableCell>
                <TableCell align="center" className={clsx(showDemographics && classes.highlightedFilter)}>
                    <Checkbox
                        onClick={toggleDemographics}
                        className={classes.checkbox}
                        color="default"
                        checked={true}
                        checkedIcon={demographicsIcon}
                        icon={demographicsIcon} /></TableCell>
                <TableCell align="center">
                    <Checkbox size="small"
                        name="lastVisit"
                        onClick={handleCheck}
                        className={classes.checkbox}
                        color="default"
                        checked={filterData.lastVisit}
                        checkedIcon={<CheckBox />}
                        icon={<CheckBoxOutlineBlank />}
                    />
                </TableCell>
            </TableRow>
            {showDemographics && staticData.isInitialized &&
                <TableRow className={classes.highlightedFilter}>
                    <TableCell colSpan={8}>
                        <div style={{ padding: "0.5rem 1rem" }}>{staticData.demographicCriteria.criteria.map(c => {
                            const attributeKey = c.filterName || c.attributeName;
                            return (
                                <Chip
                                    clickable={false}
                                    className={clsx(classes.chip, filterData.demographics.show[attributeKey] && "selected")}
                                    onClick={() => handleChipClick(attributeKey)}
                                    key={attributeKey}
                                    color="default"
                                    variant="outlined"
                                    size="small"
                                    label={c.filterLabel || c.label} />
                            )
                        })}</div>
                        <div>
                            {staticData.demographicCriteria &&
                                staticData.demographicCriteria.criteria.filter(c => filterData.demographics.show[c.filterName || c.attributeName] === true)
                                    .map(c => {
                                        const attributeKey = c.filterName || c.attributeName;
                                        const isNumber = c.attributeType === 'number';
                                        const minValueName = c.filterType === 'age' ? "$min-age" : "$min";
                                        const maxValueName = c.filterType === 'age' ? "$max-age" : "$max";
                                        const minValue = _get(filterData, ['demographics', 'data', attributeKey, minValueName], "");
                                        const maxValue = _get(filterData, ['demographics', 'data', attributeKey, maxValueName], "");

                                        return <React.Fragment key={attributeKey}>
                                            <hr color="white" />
                                            <div style={{ padding: "0.5rem 1rem" }}>
                                                <Typography variant="subtitle2">{c.filterLabel || c.label}</Typography>
                                                {c.allowedValues && c.allowedValues.map(v => {
                                                    const isValueSelected = !!_get(filterData, ['demographics', 'data', attributeKey, v.value]);
                                                    return <FormControlLabel classes={{ label: classes.smallLabel }}
                                                        key={v.value}
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => setDemographicFilterValue(
                                                                    !event.target.checked, attributeKey, v.value)}
                                                                checked={isValueSelected}
                                                                name="checkedB"
                                                                color="default"
                                                                size="small"
                                                                value={v.value}
                                                            />
                                                        }
                                                        label={v.label}
                                                    />
                                                })}
                                                {isNumber &&
                                                    <div>
                                                        <TextField key={`${c.attributeName}_min`} label="Min"
                                                            style={{ width: "100px", marginRight: "30px" }}
                                                            type="number" margin="dense"
                                                            InputProps={{ shrink: true }}
                                                            onChange={(event) => setDemographicFilterValue(!(parseInt(event.target.value) >= 0),
                                                                attributeKey, minValueName, event.target.value)}
                                                            name={`${c.attributeName}_min`}
                                                            value={minValue} />
                                                        <TextField key={`${c.attributeName}_max`} label="Max"
                                                            style={{ width: "100px" }}
                                                            type="number" margin="dense"
                                                            InputProps={{ shrink: true }}
                                                            onChange={(event) => setDemographicFilterValue(!(parseInt(event.target.value) >= 0),
                                                                attributeKey, maxValueName, event.target.value)}
                                                            name={`${c.attributeName}_max`}
                                                            value={maxValue} />

                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                    )}
                        </div>
                    </TableCell>
                </TableRow>}
        </TableHead>
    )
}

export default FilterHeaderComponent;