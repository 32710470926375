import React,  {useState} from 'react';
import moment from 'moment';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PercentBar from './PercentBar';
import { useStaticData } from 'src/providers/StaticDataProvider';

const useStyles = makeStyles(theme => {
    return {
        selected: {
            borderBottom: '0.5ch solid #c0c0c0'
        },
        surveyBarContainer: {
            display: "flex",
            flexWrap: "nowrap",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-around",
            height: "6ch",
            width: "6ch",
            margin: "1ch"
        },
        surveyBar: {
            display: "flex",
            flexDirection: "column",
            flex: 0.2
        }
    }
});

export const SurveyChart = ({ data, onClick, style }) => {
    const classes = useStyles();

    return <div className={classes.surveyBarContainer} style={style} onClick={onClick}>
        <PercentBar value={data.C1} color="c1" className={classes.surveyBar}></PercentBar>
        <PercentBar value={data.C2} color="c2" className={classes.surveyBar}></PercentBar>
        <PercentBar value={data.C3} color="c3" className={classes.surveyBar}></PercentBar>
        <PercentBar value={data.C4} color="c4" className={classes.surveyBar}></PercentBar>
    </div>
}

export default ({ date, monthIndex, data, medi1, medi2, selected, onClick }) => {
    const classes = useStyles();
    const staticData = useStaticData();

    const [medState, setMedState] = useState("");
    const [medState2, setMedState2] = useState("");

    let printMed1 = async () => {
        let a = await medi1;
        setMedState(a); 
    }  
    printMed1();
    let printMed2 = async () => {
        let a = await medi2;
        setMedState2(a); 
    }  
    printMed2();
    // const medication1 = _get(data, "treatment.medication", "");
    // if (medication1) {
    //     medicationText = staticData.medication.schizo.find(m => m.id === medication1)?.label || "?";
    // }
    // const medication2 = _get(data, "treatment.medication2", "");
    // if (medication2) {
    //     medicationText += ", " + staticData.medication.schizo.find(m => m.id === medication2)?.label || "?";
    // }
    let medicationText = "";
    const medication = medState;
    if (medication) {
        medicationText = staticData.medication.schizo.find(m => m.id === medication)?.label || "";
    }
    const medication2 = medState2;
    if (medication2) {
        medicationText += ", " + staticData.medication.schizo.find(m => m.id === medication2)?.label || "";
    }

    const momentDate = new moment(date);
    const functionality = data["$F"];
    return <div style={{ cursor: "pointer" }}
        onClick={onClick}
        className={classNames({ [classes.selected]: selected })}>
        <Typography align="center">{momentDate.format("MMM")}</Typography>
        <SurveyChart data={data} />
        <Typography variant="body2" align="center">{functionality ? `${functionality}%` : "-"}</Typography>
        {medicationText && <Typography variant="caption" align="center">{medicationText}</Typography>}
    </div>
}
