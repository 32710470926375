import React from 'react';
import { makeStyles } from '@material-ui/core';
import FunctionalityTile from './functionalityTile';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => {
    return {
        root: {
            marginBottom: 10,

        },
        scrollContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        tile: {

        },
        c1: {
            backgroundColor: theme.colorlib.c1.main
        },
        c2: {
            backgroundColor: theme.colorlib.c2.main
        },
        c3: {
            backgroundColor: theme.colorlib.c3.main
        },
        c4: {
            backgroundColor: theme.colorlib.c4.main
        },
        dim: {
            opacity: 0.2
        },
        c1_border: {
            border: theme.colorlib.c1.border
        },
        c2_border: {
            border: theme.colorlib.c2.border
        },
        c3_border: {
            border: theme.colorlib.c3.border
        },
        c4_border: {
            border: theme.colorlib.c4.border
        },
    }
});

export default (({ evaluationDetail, evaluationSummary, selectedMonth, setSelectedMonth }) => {
    const classes = useStyles();

    const handleTileClick = (event, index, yearMonth) => {
        setSelectedMonth(yearMonth);
    }

    return !Array.isArray(evaluationSummary) ? <Skeleton /> : <div className={classes.root}>
        <div className={classes.scrollContainer}>
            {evaluationSummary.map((item, ix) => (
                <FunctionalityTile
                    key={ix}
                    // todo: this should be based on month or index, not necessarily date
                    selected={selectedMonth === item.yearMonth}
                    date={item.date} monthIndex={ix} data={item.data || {}}
                    medi1={item.medication1}
                    medi2={item.medication2}
                    onClick={event => handleTileClick(event, ix, item.yearMonth)}
                />
            ))}
        </div>
    </div>
})