import React from 'react';
import Typography from '@material-ui/core/Typography';
import { LinearFormControl } from 'src/components/widgets';
import { Divider, Button } from '@material-ui/core';

export default ({ userInfo, onContinue }) => {
    // <img src={`data:image/svg+xml;utf8,${encodeURIComponent(result.qrCode)}`} style={{width: 200, height: 200}} alt="qr code"/>;
   
    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "16px 32px 16px 32px", maxWidth: "600px" }}>
            <Typography>Va multumim, inregistrarea a fost incheiata cu succes.</Typography>
            <br />
            {userInfo.verified && <Typography>Click [AICI] pentru a instala aplicatia Registreye pe telefonul mobil.</Typography>}
            {!userInfo.verified && <Typography>Veti primi imediat un mesaj pe email, cu instructiuni pentru a instala aplicatia Registreye pe telefonul mobil.</Typography>}
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyItems: "space-between" }} centered>
            <Button variant="contained" color="primary" disableElevation
                onClick={onContinue}>INCHIDE</Button>
        </LinearFormControl>
    </>
};