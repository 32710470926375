import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        lineHeight: "2rem",
        flex: 1
    }
}));

export default ({ text, variant, color, children, style }) => {
    const classes = useStyles();
    const myStyle = Object.assign({ display: "flex", alignItems: "center" }, (style || {}));

    return <div style={myStyle}>
        {text &&
            <Typography color={color || "textPrimary"} variant={variant} className={classes.title}>{text}</Typography>}
        {children}
    </div>
}