import React, { memo, useLayoutEffect, useState, useRef } from 'react';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import Autosizer from 'react-virtualized-auto-sizer';
import { useTheme, makeStyles, Typography, Badge, Button } from '@material-ui/core';
import classnames from 'classnames';
import deepEqual from 'deep-equal';

const useStyles = makeStyles((theme) => ({
    dayTile: {
        // borderBottom: "1px solid #c0c0c0",
        "& h5": {
            fontSize: "1.2rem",
            lineHeight: "5rem",
            fontWeight: 500,
            color: "#505050"
        },
        "& .MuiBadge-root": {
            position: "absolute",
            right: "1rem",
            top: "1rem",
            transform: "scale(0.9)"
        },
        "& .timespan": {
            // backgroundColor: "#ffffff",
            position: "absolute",
            bottom: 0,
            // lineHeight: "2rem",
            width: "100%",
            lineHeight: "2rem",
            textAlign: "center",
            color: "#50505090"
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        minWidth: "6ch",
        "& .weekend": {
            opacity: 0.6
        }
    },
    selectedDay: {
        //  border: "1px solid blue"
        backgroundColor: "#6100ED20",
        "& .timespan": {
            color: "#6100ED90",
            backgroundColor: "#ffffff"
        }
    },
    today: {
        "& .dateLabel": {
            // fontSize: "1.2em",
            // fontWeight: "bold",
            color: theme.palette.primary.main
        }
    },
    disabled: {
        opacity: 0.2,
        cursor: "default"
    },
    firstDay: {
        // borderLeft: "1px solid #f0f0f0"
    },
    monthStart: {
        // backgroundColor: "#f7f7f8",
        borderTop: "1px solid #c0c0c0"
    },
    highlighted: {
        border: "1px solid #6100ED"
    }
}))

const extractWeekData = (startDate, appointments) => {
    const data = [];
    if (appointments && appointments.length > 0) {
        // populate an array of weekly data, beginning with our startDate as week 0
        const highestDate = moment(appointments[appointments.length - 1].date);
        let ixWeekStart = startDate.clone();
        let ixWeekEnd = startDate.clone().endOf("week");
        let weekIndex = 0;
        while (ixWeekStart < highestDate) {
            // get all appointments this week, by day
            const weekAppointments = appointments.filter(a => ixWeekStart.isSameOrBefore(a.date) && a.date.isSameOrBefore(ixWeekEnd));
            if (weekAppointments.length > 0) {
                // break them down daily
                data[weekIndex] = Array.apply(null, Array(7)).map((d, ix) =>
                    weekAppointments.filter(a =>
                        ixWeekStart.clone().add(ix, "days").isSameOrBefore(a.date)
                        && a.date.isBefore(ixWeekStart.clone().add(ix + 1, "days"))));
            }
            weekIndex++;
            ixWeekStart.add(1, "week");
            ixWeekEnd.add(1, "week");
        }
        return data;
    }
    return data;
}

export default memo(({ disabled, selectedDate, setSelectedDate,
    appointments, disablePastDates, onScroll, listRef, highlightDate }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [startDate/*, setStartDate*/] = useState(moment().startOf("week").add(-3, "weeks"));
    const [stickyMonth, setStickyMonth] = useState(startDate.clone().format("MMM"));
    const [weekData, setWeekData] = useState([]);
    const scrollContextRef = useRef({ firstRowIndex: 3, pixelOffset: 0 });
    const stickyLabelRef = useRef();

    const today = moment().startOf("day");
    const itemHeight = 7 / parseFloat(theme.typography.pxToRem(1));

    useLayoutEffect(() => {
        console.debug("REFRESHING WEEK DATA IN CALENDAR GRID", appointments.length, startDate.toString());
        const data = extractWeekData(startDate, appointments);
        setWeekData(data);
    }, [appointments, setWeekData, startDate]);

    console.debug("BIG RENDER IN CALENDAR GRID");

    const Row = ({ index, style }) => {
        const ixDate = startDate.clone().add(index, "weeks");
        const myData = weekData.length > index && weekData[index];

        // if top row is more than few pixels off, display current month on the beginning of this row
        const showMonthLabel = false;
        //(scrollContextRef.current.firstRowIndex === index && scrollContextRef.current.pixelOffset <= 10) ||
        //    (scrollContextRef.current.firstRowIndex === index - 1 && scrollContextRef.current.pixelOffset > 10);

        const days = Array.apply(null, Array(7)).map((d, ix) => {
            const thisDate = ixDate.clone().add(ix, "days");
            return <DayTile key={ix} date={thisDate} appointments={myData && myData[ix]}
                highlighted={highlightDate && highlightDate.clone().startOf("day").isSame(thisDate)}
                disabled={disabled || (disablePastDates && thisDate.isBefore(today))}
                showMonthLabel={(ix === 0 && showMonthLabel) || thisDate.date() === 1} />
        });
        return <div style={{ ...style, display: "flex", borderBottom: "1px solid #f0f0f0" }}>{days}</div>
    }

    const DayTile = ({ date, appointments, disabled, showMonthLabel, highlighted }) => {
        // time interval first - last appointment of the day
        let timeSpan = appointments && appointments.length > 0 && appointments[0].date.format("HH:mm");
        if (timeSpan) {
            timeSpan += appointments && appointments.length > 1 ? " - " + appointments[appointments.length - 1].date.format("HH:mm") : "";
        }

        // const currentMonth = date.get("month");

        return <div style={{
            cursor: disabled ? "default" : "pointer",
            flex: `${1 / 7} 8rem`, // padding: "0.2rem",
            position: "relative"
        }}
            className={classnames(classes.dayTile, {
                [classes.highlighted]: highlighted,
                // [classes.monthStart]: !date.isSame(selectedDate) && currentMonth % 2 === 1,
                [classes.monthStart]: date.date() < 8 && date.date() <= date.day(),
                [classes.firstDay]: date.date() === 1,
                [classes.today]: today.isSame(date), [classes.selectedDay]: date.isSame(selectedDate),
                [classes.disabled]: disabled
            })}
            onClick={() => !disabled && setSelectedDate(date)}
        >
            {showMonthLabel && <Typography variant="button"
                style={{ position: "absolute", top: 0, left: "0.5rem", right: 0, color: "#6100EDC0" }}
            >{date.format("MMM")}
            </Typography>}

            {/*date.isSame(today) && <SignalCellular4Bar style={{position: "absolute", right: 0, transform: "rotate(-90deg)", opacity: 0.3}} color="#777"/>*/}

            <Typography
                align="center"
                variant="h5"><span className={classnames("dateLabel", { "weekend": date.day() === 0 || date.day() === 6 })}>{date.date()}</span></Typography>

            <Badge
                badgeContent={appointments ? appointments.length : 0}
                color="primary" />

            {timeSpan && <Typography variant="body2" className="timespan">{timeSpan}</Typography>}
        </div>
    }

    const handleScroll = ({ scrollOffset, scrollUpdateWasRequested }) => {
        // keep track
        const firstRowIndex = Math.floor(scrollOffset / itemHeight);
        const pixelOffset = scrollOffset % itemHeight;
        const currentFirstDay = startDate.clone().add(firstRowIndex, "weeks");
        const upcomingFirstDay = startDate.clone().add(firstRowIndex + 1, "weeks");
        let currentMonth = (pixelOffset < itemHeight / 2 ? currentFirstDay : upcomingFirstDay).format("MMM");
        const labelHeight = parseInt(window.getComputedStyle(stickyLabelRef.current).lineHeight);
        if ((upcomingFirstDay.date() === 1 && pixelOffset > labelHeight) || (currentFirstDay.date() === 1 && pixelOffset < labelHeight))
            currentMonth = "";
        if (stickyMonth !== currentMonth) {
            setStickyMonth(currentMonth);
        }
        scrollContextRef.current = { firstRowIndex, pixelOffset, stickyMonth };
        if (onScroll) {
            onScroll(scrollOffset, scrollUpdateWasRequested);
        }
    }

    const scrollToday = () => {
        // todo: move 3 into a constant
        listRef.current.scrollTo(3 * itemHeight);
        setSelectedDate(moment().startOf("day"));
    }

    const weekDays = moment.weekdaysMin();
    // since we set monday as first day of week
    weekDays.push(weekDays.shift());

    return <>
        <div><Button variant="contained" color="primary" style={{marginLeft:0}}
            onClick={scrollToday}>
            {moment().format("[Astăzi:] dddd, D MMMM")}
        </Button></div>
        <div style={{ display: "flex", paddingBottom: "1rem", textAlign: "center", color: "#50505090" }}>
            {
                weekDays.map(d =>
                    <div style={{ flex: 1 / 7 }} key={d}>{d}</div>
                )}
        </div>
        <div style={{ display: "block", flex: 1 }}><Autosizer>
            {({ height, width }) => {
                return <>
                    <Typography ref={stickyLabelRef} variant="button"
                        style={{ position: "absolute", top: 0, left: "calc(0.5rem + 1px)", right: 0, color: "#6100EDC0", zIndex: 99 }}
                    >{stickyMonth}
                    </Typography>
                    <FixedSizeList

                        ref={listRef}
                        height={height}
                        itemSize={itemHeight}
                        itemCount={30}
                        width={width}
                        initialScrollOffset={3 * itemHeight}
                        onScroll={handleScroll}
                    >
                        {Row}
                    </FixedSizeList>
                </>
            }}
        </Autosizer></div></>;
}, deepEqual);