import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import { TextField, Button, Select, MenuItem, Typography, IconButton, makeStyles, InputBase } from '@material-ui/core';
import { difference } from 'src/utils';
import { MoreVert, SaveOutlined, CloseSharp, LayersClearOutlined, FileCopyOutlined, DeleteForeverOutlined } from '@material-ui/icons';
import { ButtonWithProgress } from '../widgets';
import useLanguage from '../../translations/useLanguage';

export const DEFAULT_FILTER = {
    isPatient: false,
    isCaregiver: false,
    c1: false,
    c2: false,
    c3: false,
    c4: false,
    functionality: false,
    demographics: { show: {}, data: {} }
}

const useStyles = makeStyles((theme) => ({
    bar: {
        flex: 1,
        borderTopRightRadius: "1rem",
        display: "flex",
        alignItems: "center"
    },
    activeBar: {
        backgroundColor: theme.palette.primary.main,
        "& .MuiButton-root": {
            margin: "0 1rem",
            color: "#fff"
        },
        "& .MuiIconButton-root": {
            color: "#fff"
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#fff"
        },
        
        "& .MuiFormHelperText-root": {
            color: "#fff"
        },
        "& .Mui-disabled": {
            color: "#ffffff80"
        },
        // this one is set by ButtonWithProgress
        "& .MuiCircularProgress-root": {
            color: "#fff"
        }
    },
    alert: {
        backgroundColor: theme.palette.error.main
    },
    selectRoot: {
        marginRight: "1rem",
        "& .MuiSelect-select": {
            backgroundColor: "unset"
        }
    }
}));

const cleanupFilter = (filter) => {
    const _filter = _.cloneDeep(filter);
    // remove the category show indicators, only filter values matter
    _.unset(_filter, 'demographics.show');
    return _filter;// pickDeep(_filter, v => v !== false);
}

const compareFilters = (filter1, filter2) => {
    return difference(cleanupFilter(filter1), cleanupFilter(filter2));
}

const FilterManagementComponent = ({ filterData, setFilterData, showListActions, setShowListActions }) => {
    const classes = useStyles();
    const [filters, setFilters] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({ id: false });
    const [newFilter, setNewFilter] = useState({ show: false, name: "" });
    const [deleteFilter, setDeleteFilter] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false);
    const firestoreStreams = useFirestoreStreams();
    const {translations} = useLanguage();

    const handleSetFilters = useCallback((state) => {
        if (selectedFilter.id && !state.items.find(i => i.id === selectedFilter.id)){
            setSelectedFilter({id: false});
            setFilterData(_.clone(DEFAULT_FILTER));
        }
        setFilters(state.items);
    }, [selectedFilter, setFilterData]);

    const handleSetFiltersRef = useRef(handleSetFilters);
    useEffect(() => {
        handleSetFiltersRef.current = handleSetFilters;
    }, [handleSetFilters]);

    const handleFilterSelected = (event) => {
        const filterId = event.target.value;
        const filterItem = filterId ? filters.find(i => i.id === filterId) : { id: false };
        setSelectedFilter(filterItem);
        setNewFilter({ show: false, name: "" });
        setDeleteFilter(false);
        setFilterData(filterItem.id ? filterItem.data.criteria : _.clone(DEFAULT_FILTER));
    }

    const handleFilterReset = (event) => {
        event.preventDefault();
        setFilterData(selectedFilter.id ? selectedFilter.data.criteria : _.clone(DEFAULT_FILTER));
    }

    const handleFilterShowDelete = (event) => {
        event.preventDefault();
        setDeleteFilter(true);
    }

    const handleFilterDelete = async (event) => {
        // todo: get rid of prevent default and handle this without swapping
        try {
            setActionInProgress(true);
            await firestoreStreams.deleteItem("filters", selectedFilter.id);
            // this will reset the filter to default ("toti pacientii"), no actions 
            setShowListActions(false);
            setDeleteFilter(false);
        } catch (err) {
            // todo: handle error
        } finally {
            setActionInProgress(false);
        }
    }

    const handleFilterSave = async (event) => {
        event.preventDefault();
        // todo: save filter();
        try {
            setActionInProgress(true);
            const data = {
                userId: firestoreStreams.auth.user.uid,
                name: newFilter.show ? newFilter.name.trim() : selectedFilter.data.name,
                criteria: filterData
            }
            // this will trigger a refresh on the filter list
            // we set up the selectedFilter now and then we just refresh the list and keep the same
            const newDoc = await firestoreStreams.saveItem('filters', newFilter.show ? null : selectedFilter.id, data);
            setNewFilter({ show: false, name: "" });
            setSelectedFilter({ id: newDoc.id, data: newDoc.data() });
            // todo: show some confirmation
        } catch (err) {
            // todo: unify error handling
            setActionInProgress(false);
            console.error("ERROR SAVING FILTER", err.message)
            setNewFilter({ name: newFilter.name, error: true, message: translations.filterManagement.filterError, canRetry: true });
        } finally {
            setActionInProgress(false);
        }
    }

    const handleFilterShowNew = (event, show) => {
        event.preventDefault();
        setNewFilter({ show, name: "" });
    }

    const handleNewFilterNameChange = (event) => {
        const value = event.target.value;
        const _newFilter = {
            name: value,
            show: true
        }
        // todo: check if name exists
        if (filters && filters.find(i => i.data.name.toUpperCase() === value.trim().toUpperCase())) {
            _newFilter.error = true;
            _newFilter.message = translations.filterManagement.listExistsError
        }

        setNewFilter(_newFilter);
    };

    useEffect(() => {
        console.debug("useLayoutEffect called in FilterManagementComponent", firestoreStreams.isInitialized);

        if (firestoreStreams.isInitialized && !firestoreStreams.hasSubscriptions()) {
            firestoreStreams.subscribe('patient-filters',
                state => {
                    handleSetFiltersRef.current(state);
                });
        }
    }, [firestoreStreams, selectedFilter, setSelectedFilter, setFilterData, setFilters]);

    const selectedData = _.get(selectedFilter, 'data.criteria', DEFAULT_FILTER);
    const isFilterModified = Object.keys(compareFilters(filterData, selectedData)).length > 0;
    const isDefaultMenu = Object.keys(compareFilters(filterData, DEFAULT_FILTER)).length === 0;

    return <div style={{ display: "flex", alignItems: "stretch", whiteSpace: "nowrap", minHeight: "4rem", flexWrap: "nowrap", flex: "auto" }}>

        <Select
            className={classes.selectRoot}
            id="filter-select"
            renderValue={(value) => {
                return <Typography variant="h5">
                    {value === false || isDefaultMenu ?
                        (isFilterModified && !isDefaultMenu ? translations.filterManagement.newList : translations.filterManagement.allPatients) :
                        filters.find(i => i.id === value).data.name
                    }
                </Typography>
            }}
            input={<InputBase />}
            displayEmpty
            onChange={handleFilterSelected} value={selectedFilter.id}>
            <MenuItem value={false}>{selectedFilter.id || !isFilterModified ? translations.filterManagement.allPatients : translations.filterManagement.newList}</MenuItem>
            {
                filters && filters.map(i => <MenuItem key={i.id} value={i.id}>{i.data.name}</MenuItem>)
            }
        </Select>
        <div className={classNames(classes.bar, showListActions && (isFilterModified || !isDefaultMenu) && classes.activeBar, deleteFilter && classes.alert)}>
            {(isFilterModified || !isDefaultMenu) &&
                <IconButton onClick={event => {
                    if (newFilter.show) {
                        handleFilterShowNew(event, false);
                    } else if (deleteFilter) {
                        setDeleteFilter(false);
                    } else {
                        setShowListActions(!showListActions);
                    }
                }}>
                    {showListActions ? <CloseSharp /> : <MoreVert />}
                </IconButton>}
            {showListActions &&
                <>
                    {!deleteFilter && !newFilter.show && isFilterModified && selectedFilter.id && !isDefaultMenu &&
                        <Button variant="text" startIcon={<SaveOutlined />} onClick={handleFilterSave}>{translations.filterManagement.save}</Button>}
                    {!deleteFilter && !newFilter.show && isFilterModified &&
                        <Button variant="text" startIcon={<LayersClearOutlined />} onClick={handleFilterReset}>{translations.filterManagement.cancelChanges}</Button>}
                    {!deleteFilter && !newFilter.show && !isDefaultMenu && !newFilter.show &&
                        <Button variant="text" startIcon={selectedFilter.id ? <FileCopyOutlined /> : <SaveOutlined />} onClick={
                            event => handleFilterShowNew(event, true)}>{translations.filterManagement.save}{selectedFilter.id ? translations.filterManagement.copy : ""}
                        </Button>}
                    {!deleteFilter && !newFilter.show && selectedFilter.id &&
                        <Button variant="text" startIcon={<DeleteForeverOutlined />} onClick={handleFilterShowDelete}>{translations.filterManagement.delete}</Button>}
                    {newFilter.show && <>
                        <TextField label={translations.filterManagement.listName}
                            margin="dense"
                            variant="filled"
                            inputProps={{ name: "newFilterName" }}
                            value={newFilter.name}
                            error={newFilter.error}
                            helperText={newFilter.message}
                            onChange={handleNewFilterNameChange} fullWidth />
                        <ButtonWithProgress variant="text" color="primary" onClick={handleFilterSave}
                            showProgress={actionInProgress}
                            disabled={actionInProgress || newFilter.name.trim() === '' || (newFilter.error && !newFilter.canRetry)}
                            disableElevation>{translations.filterManagement.save}</ButtonWithProgress>
                    </>}
                    {deleteFilter && selectedFilter.id && <>
                        <TextField label={translations.filterManagement.deleteList}
                            margin="dense"
                            error
                            variant="filled"
                            value={selectedFilter.data.name} fullWidth />
                        <ButtonWithProgress variant="text" color="primary" onClick={handleFilterDelete}
                            showProgress={actionInProgress} disabled={actionInProgress}
                            disableElevation>{translations.filterManagement.deleteForever}</ButtonWithProgress>
                    </>}
                </>}
        </div>
    </div>;
}

export default FilterManagementComponent;