import React, { useLayoutEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useState } from 'react';

export default (({ profile, setProfile, criteria, editable, filter, setError }) => {
    const [fieldErrors, setFieldErrors] = useState({});

    useLayoutEffect(() => {
        setFieldErrors({});
    }, [profile]);

    const handleValueChange = (valueKey, criterion = {}) => event => {
        const value = event.target.value;
        if (value !== "" && criterion && criterion.format && !new RegExp(criterion.format).test(value)) {
            return;
        }
        if (criterion.requiredLength) {
            setError(fieldErrors.length > 0 || value.length !== criterion.requiredLength);
        }
        const newProfile = Object.assign({}, profile || {});
        if (value === "") {
            delete (newProfile[valueKey]);
        } else {
            newProfile[valueKey] = criterion.attributeType === 'number' ? parseInt(value) : value;
        }
        setProfile(newProfile);
    }

    const handleFieldOutOfFocus = criterion => event => {
        const value = event.target.value;
        if (!criterion.requiredLength)
            return;
        if (value !== "" && value.length !== criterion.requiredLength) {
            setFieldErrors({ ...fieldErrors, [criterion.attributeName]: true });
            setError(true);
        } else if (fieldErrors[criterion.attributeName]) {
            setFieldErrors({ ...fieldErrors, [criterion.attributeName]: false });
            setError(fieldErrors.length > 0);
        }
    }

    const handleFieldFocus = criterion => event => {
        if (!criterion.requiredLength)
            return;
        if (fieldErrors[criterion.attributeName]) {
            setFieldErrors({ ...fieldErrors, [criterion.attributeName]: false });
        }
    }

    const showCriteria = filter ? criteria.filter(filter) : criteria;
    return showCriteria &&
        <div>
            {showCriteria.map(c => {
                let extraValueKey = false;
                let showExtraInput = false;
                switch (c.attributeType) {
                    case 'number':
                    case 'text':
                        return <TextField style={{ display: "block" }} name={c.attributeName} value={profile[c.attributeName] || ""}
                            label={c.label} key={c.attributeName} required={!c.optional}
                            InputProps={{ disableUnderline: !editable }} fullWidth={!editable}
                            onBlur={handleFieldOutOfFocus(c)}
                            onFocus={handleFieldFocus(c)}
                            error={fieldErrors[c.attributeName]}
                            helperText={fieldErrors[c.attributeName] ? c.formatError : undefined}
                            onChange={handleValueChange(c.attributeName, c)} />;
                    case 'select':
                        if (!editable) {
                            const value = c.allowedValues.find(v => v.value === profile[c.attributeName]);
                            let valueLabel = (value && value.label) || "- nespecificat -";
                            let extraValue = (value && value.hasExtraInput) ? profile[c.attributeName + "_extra"] : false;

                            return <TextField style={{ display: "block" }} key={c.attributeName} readOnly label={c.label}
                                InputProps={{ disableUnderline: true }} fullWidth
                                name={c.attributeName} value={extraValue || valueLabel} />;
                        } else {
                            const selectedVal = c.allowedValues.find(v => v.value === profile[c.attributeName]);
                            showExtraInput = !!selectedVal && selectedVal.hasExtraInput;
                            extraValueKey = showExtraInput && `${c.attributeName}_extra`;

                            return <FormControl required={!c.optional} key={c.attributeName} style={{ display: "block", backgroundColor: showExtraInput ? "#f7f7ff" : "inherit" }}>
                                <InputLabel id={`profile-label-${c.attributeName}`}>{c.label}</InputLabel>
                                <Select
                                    style={{ minWidth: "20rem" }}
                                    labelId={`profile-label-${c.attributeName}`}
                                    value={profile[c.attributeName] || ""}
                                    onChange={handleValueChange(c.attributeName)}
                                    autoWidth
                                >
                                    <MenuItem value="" key="$empty">- nespecificat -</MenuItem>
                                    {c.allowedValues && c.allowedValues.map(v => {
                                        // here while we iterate through values we decide whether to show the extra input
                                        return <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                    })}
                                </Select>
                                {showExtraInput &&
                                    <TextField name={extraValueKey} value={profile[extraValueKey]}
                                        fullWidth required={!c.optional}
                                        disabled={!showExtraInput}
                                        onChange={handleValueChange(extraValueKey)}
                                    />}
                            </FormControl>
                        }
                    default:
                        break;
                }
                return null;
            })}
        </div >
});