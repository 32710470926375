import React, { useState, useLayoutEffect } from 'react';
import { ChevronRightSharp } from '@material-ui/icons';
import { TextField, FormControl } from '@material-ui/core';
import {cloneDeep as _cloneDeep} from 'lodash';
import { useStaticData } from 'src/providers/StaticDataProvider';
import { Autocomplete, Alert } from '@material-ui/lab';
import { RadioGroup, LinearFormControl, ButtonWithProgress } from 'src/components/widgets';

export default (({ evalDetail, readOnly, onContinue, saveInProgress }) => {
    const staticData = useStaticData();
    const [myDetail, setMyDetail] = useState(_cloneDeep(evalDetail) || {});

    useLayoutEffect(() => {
        setMyDetail(_cloneDeep(evalDetail) || {});
    }, [evalDetail]);

    const myData = myDetail.treatment || {};

    const handleChange = (key, value) => {
        const data = _cloneDeep(myData);
        if (key === "medication" && value === "") {
            data.medication = data.medication2;
            delete data.medication2;
        } else {
            data[key] = value;
        }
        setMyDetail({...myDetail, treatment: data});
    }

    const options = staticData.isInitialized ? staticData.medication.schizo : [];
    const medValue = options.find(o => o.id === myData.medication);
    const medValue2 = options.find(o => o.id === myData.medication2);
    const defaultMed = { id: "", label: "-" };

    return <>
        {!readOnly &&
            <FormControl fullWidth>
                <Alert severity="info">
                    Confirmati tratamentul urmat de pacient in ultimele 30 zile si daca au avut loc recaderi sau spitalizari
                </Alert>
            </FormControl>}
        {readOnly &&
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Tratament" value={(medValue || defaultMed).label} />}
        {readOnly && medValue && medValue2 &&
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Tratament adițional" value={medValue2.label} />}
        {!readOnly &&
            <Autocomplete
                value={medValue || defaultMed}
                style={{ width: "20rem" }}
                options={[defaultMed, ...options]}
                renderInput={(params) => <TextField {...params}
                    label="Tratament"
                    variant="standard" />}
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                    console.log(JSON.stringify(value));
                    handleChange("medication", value ? value.id : "")
                }
                }
            />}
        {!readOnly && medValue && <Autocomplete
            value={medValue2 || defaultMed}
            style={{ width: "20rem" }}
            options={[defaultMed, ...options]}
            renderInput={(params) => <TextField {...params}
                label="Tratament adițional"
                variant="standard" />}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => {
                console.log(JSON.stringify(value));
                handleChange("medication2", value ? value.id : "")
            }
            }
        />}
        {readOnly && <>
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Recăderi" value={myData.relapses === "yes" ? "Da" : "Nu"} />
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Spitalizare" value={myData.hospitalizations === "yes" ? "Da" : "Nu"} />
        </>}
        {!readOnly && <>
            <RadioGroup key="recaderi" options="yes_no" label="Recăderi" value={myData.relapses}
                onChange={(event, value) => handleChange("relapses", value)} />
            <RadioGroup key="spitalizari" options="yes_no" label="Spitalizare" value={myData.hospitalizations}
                onChange={(event, value) => handleChange("hospitalizations", value)} />
        </>}
        {!readOnly &&
            <LinearFormControl>
                <ButtonWithProgress variant="outlined" endIcon={<ChevronRightSharp />}
                    showProgress={saveInProgress} disabled={!myData.relapses || !myData.hospitalizations}
                    onClick={() => onContinue(myDetail)}>Continuă</ButtonWithProgress>
            </LinearFormControl>}
    </>
})