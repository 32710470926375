import React, { useState, useLayoutEffect } from 'react';
import { ChevronRightSharp } from '@material-ui/icons';
import { Button, FormControl } from '@material-ui/core';
import DemographicProfile from '../DemographicProfile';
import { LinearFormControl, ButtonWithProgress } from 'src/components/widgets';
import { Alert } from '@material-ui/lab';
import { useStaticData } from 'src/providers/StaticDataProvider';
import { has as _has, cloneDeep as _cloneDeep } from 'lodash';

export default (({ evalDetail, readOnly, onContinue, saveInProgress }) => {
    const staticData = useStaticData();
    const [editMode, setEditMode] = useState(!readOnly || false);
    const [myDetail, setMyDetail] = useState(_cloneDeep(evalDetail) || {});
    const [error, setError] = useState(false);

    const isProfileComplete = (p, criteria) => {
        if (!p || !criteria) return false;
        const isComplete = !criteria.find(c => !c.optional && !_has(p, c.attributeName));
        return isComplete;
    };

    const profile = staticData.isInitialized && evalDetail && evalDetail.profile;
    const myProfile = staticData.isInitialized && myDetail && myDetail.profile;
    if (!myProfile) {
        // we should never end up here, or it will be for a very short time
        return <></>;
    }

    useLayoutEffect(() => {
        // switch to not editable if readOnly status changes
        if (editMode && readOnly) {
            setEditMode(false);
        }
    }, [readOnly, editMode, setEditMode]);

    useLayoutEffect(() => {
        setMyDetail(_cloneDeep(evalDetail) || {});
    }, [evalDetail])

    useLayoutEffect(() => {
        let criteria = staticData.isInitialized && staticData.demographicCriteria.criteria;
        if (criteria && profile) {
            setEditMode(!isProfileComplete(profile, criteria));
        }
    }, [evalDetail, staticData, setEditMode, profile]);

    const toggleEditMode = () => {
        if (editMode) {
            setEditMode(false);
            setError(false);
            setMyDetail(_cloneDeep(evalDetail));
        } else {
            setEditMode(true);
        }
    }

    const profileComplete = myProfile && isProfileComplete(myProfile, staticData.demographicCriteria.criteria);
    const originalProfileComplete = profile && isProfileComplete(profile, staticData.demographicCriteria.criteria);

    return !profile ? <></> : <>
        {!readOnly && !editMode &&
            <FormControl fullWidth>
                <Alert severity="info">
                    Verificați dacă au intervenit schimbări în profilul demografic al pacientului
                </Alert>
            </FormControl>}
        <DemographicProfile
            criteria={staticData.demographicCriteria.criteria}
            profile={myProfile}
            editable={editMode}
            filter={profileComplete && !editMode ? (c => c.tag === 'reassess') : undefined}
            setProfile={p => setMyDetail({ ...myDetail, profile: p })}
            setError={setError} />
        {(!profileComplete || error) &&
            <FormControl fullWidth>
                <Alert severity="warning">
                    <span>Pentru a continua, completați <b>integral</b> criteriile marcate cu <b>*</b></span>
                </Alert>
            </FormControl>}
        {!readOnly && <LinearFormControl>
            {originalProfileComplete && <Button variant="outlined" onClick={toggleEditMode}>
                {editMode ? "Anuleaza Modificări" : "Modifică..."}
            </Button>}
            <ButtonWithProgress variant="outlined" endIcon={<ChevronRightSharp />}
                showProgress={saveInProgress} disabled={!profileComplete || error}
                onClick={() => onContinue(myDetail)}>
                Continuă
            </ButtonWithProgress>
        </LinearFormControl>}
    </>


})