import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Paper, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import { auth as firebaseAuth } from 'src/firebase';
import { useHistory } from 'react-router';

import SignIn from './SignIn';
import RegisterMedic from './RegisterMedic';
import RegisterPatient from './RegisterPatient';
import ChooseRole from './ChooseRole';
import FinalInfoPatient from './FinalInfoPatient';
import { AuthContext } from 'src/providers/AuthProvider';
import FinalInfoMedic from './FinalInfoMedic';
import useLanguage from '../../translations/useLanguage';

const useStyles = makeStyles(theme => ({
    nonEssential: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "unset"
        }
    },
    formPaper: {
        minWidth: 500
    }
}));

export default () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));

    const [currentStep, setCurrentStep] = useState('sign-in');
    const [userInfo, setUserInfo] = useState({});
    const auth = useContext(AuthContext);
    const handleAuthUpdateRef = useRef();
    const isFirstLoadRef = useRef(true);
    const { translations } = useLanguage();

    const handleAuthUpdate = useCallback((auth) => {
        if (auth.isInitializing)
            return;
        if (!auth.isSignedIn) {
            setUserInfo({});
            if (currentStep !== 'sign') {
                setCurrentStep("sign-in");
            }
        } else if (isFirstLoadRef.current) {
            // first load we sign them out
            console.log("SIGNING OUT...", auth.idToken, userInfo);
            auth.signOut();
        }
        isFirstLoadRef.current = false;
    }, [currentStep, userInfo]);

    useEffect(() => {
        handleAuthUpdateRef.current = handleAuthUpdate;
    }, [handleAuthUpdate]);

    useEffect(() => {
        handleAuthUpdateRef.current(auth);
    }, [auth, handleAuthUpdateRef]);

    useEffect(() => {
        if (auth.isSignedIn && auth.idToken.claims.role) {
            history.replace("/");
        }
    }, [auth.isSignedIn, auth.idToken, history])

    const handleContinue = async (data) => {
        switch (currentStep) {
            case 'sign-in':
                const { email, password } = userInfo;
                if (!password) {
                    setCurrentStep('choose-role');
                    return;
                }
                setUserInfo({ email, authenticating: true });
                try {
                    await firebaseAuth.signInWithEmailAndPassword(email, password);
                } catch (err) {
                    console.warn("LOGIN ERROR", err.message);
                }
                break;
            case 'choose-role':
                setUserInfo({ ...userInfo, role: data });
                setCurrentStep(data === 'medic' ? 'register-medic' : 'register-patient');
                break;
            case 'register-medic':
                setCurrentStep('final-medic');
                break;
            case 'register-patient':
                setCurrentStep('final-patient');
                break;
            default:
                break;
        }
    }

    const handleStepBack = () => {
        switch (currentStep) {
            case 'choose-role':
                if (auth.isSignedIn) {
                    setUserInfo({});
                    auth.signOut();
                }
                setCurrentStep('sign-in');
                break;
            case 'register-medic':
            case 'register-patient':
                setUserInfo({ ...userInfo, role: undefined });
                setCurrentStep('choose-role');
                break;
            default:
                break;
        }
    }

    const roles = {
        medic: "Medic",
        patient: "Pacient",
        caregiver: "Aparținător"
    }

    const stepInfo = {
        "sign-in": { label: translations.steps.signIn },
        "choose-role": { label: translations.steps.chooseRole + (userInfo.name ? ", " + userInfo.name : "!") },
        "register-medic": { label: translations.steps.registerMedic },
        "register-patient": { label: `${translations.steps.registerPatient} / ${roles[userInfo.role]}` },
        "final-patient": { label: translations.steps.finalPatient },
        "final-medic": { label: translations.steps.finalMedic }
    }

    const title = <Typography variant="h5" style={{ padding: "16px 32px 0 32px" }}>
        {stepInfo[currentStep].label}
    </Typography>;

    let content;
    switch (currentStep) {
        case 'sign-in':
            content = <SignIn userInfo={userInfo} setUserInfo={setUserInfo} onContinue={handleContinue} onBack={handleStepBack} />;
            break;
        case 'choose-role':
            content = <ChooseRole userInfo={userInfo} setUserInfo={setUserInfo} onContinue={handleContinue} onBack={handleStepBack} />;
            break;
        case 'register-medic':
            content = <RegisterMedic userInfo={userInfo} setUserInfo={setUserInfo} onContinue={handleContinue} onBack={handleStepBack} />;
            break;
        case 'register-patient':
            content = <RegisterPatient userInfo={userInfo} setUserInfo={setUserInfo} onContinue={handleContinue} onBack={handleStepBack} />;
            break;
        case 'final-patient':
            content = <FinalInfoPatient userInfo={userInfo} onContinue={() => {
                setUserInfo({});
                setCurrentStep('sign-in');
            }} />;
            break;
        case 'final-medic':
            content = <FinalInfoMedic userInfo={userInfo} onContinue={() => {
                setUserInfo({});
                setCurrentStep('sign-in');
            }} />;
            break;
        default: break;
    }

    if (auth.isInitializing) {
        return <></>;
    }

    if (!smMatch) {
        return <>{title}{content}</>
    } else {
        return <div style={{ height: "calc(100vh - 64px)", minHeight: 500, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
            <Paper elevation={0} className={classes.formPaper}>{title}{content}</Paper>
        </div>
    }
}