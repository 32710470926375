import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from '@material-ui/core';
import { useParams, useLocation } from 'react-router';
import moment from 'moment';
import { get as _get, setWith as _setWith, cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';

// internal references
import { downloadPdf as downloadEvalPdf } from './pdfEvalReport';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import PatientDetail from './PatientDetail';
import { useStaticData } from 'src/providers/StaticDataProvider';

export default () => {
    // context hooks
    const firestoreStreams = useFirestoreStreams();
    const location = useLocation();
    const staticData = useStaticData();
    const theme = useTheme();

    // holds onSnapshot subscription
    const visitSubscriptionRef = useRef();
    const renewSubscriptionRef = useRef(false);
    // temporary cache of data views (profile, visits, etc..) for current patient
    const evalCacheRef = useRef({});
    // current patient id - can be used for loading the patient if state is not passed in
    const { id } = useParams();
    // current patient
    const [patient, setPatient] = useState({});
    // history of patient evaluation results
    const [summaryData, setSummaryData] = useState({ loading: true });
    // current period yymm
    const [currentMonth, setCurrentMonth] = useState();
    // evaluation detail for current month
    const [evaluationDetail, setEvaluationDetail] = useState();

    const loadEvaluationDetail = useCallback(async (yearMonth) => {
        let visitData = _get(evalCacheRef.current, yearMonth);
        if (visitData && !_isEqual(evaluationDetail, visitData)) {
            setEvaluationDetail(visitData);
            return;
        }
        // if we already have a detail for this month, do not update the loading status
        // so that we do not cause disruption in the UI
        if (_get(evaluationDetail, "yearMonth") !== yearMonth) {
            setEvaluationDetail({ loading: true, yearMonth });
        }
        const doc = await firestoreStreams.collection("evaluations").doc(id)
            .collection("detail").doc(`${yearMonth}_medic`).get();

        const patientProfile = _get(patient.data, "profile.demographic", {});
        const visit = { yearMonth, activeStep: 1, profile: patientProfile, results: {}, patientLoading: patient.loading };
        if (!doc.exists) {
            console.debug("No visit info found");
        } else {
            console.debug("Loaded Visit Info", yearMonth);
            Object.assign(visit, doc.data());
            _setWith(evalCacheRef.current, yearMonth, visit, Object);
        }
        setEvaluationDetail(visit);
    }, [patient, evaluationDetail, firestoreStreams, id]);

    useEffect(() => {
        if (!(patient.loading === true) && _get(evaluationDetail, "patientLoading") === true) {
            const patientProfile = _get(patient.data, "profile.demographic", {});
            setEvaluationDetail({ ...evaluationDetail, profile: patientProfile, patientLoading: false });
        }
    }, [patient, setEvaluationDetail, evaluationDetail])

    const downloadEvalReport = useCallback(async () => {
        const fileName = `${patient.id}-eval-${moment().format("YYYY-MM")}`;
        const evalHistory = [...summaryData];
        if (Array.isArray(summaryData)) {
            const medications1 = await Promise.all(evalHistory.map(eh => eh.medication1));
            const medications2 = await Promise.all(evalHistory.map(eh => eh.medication2));
            evalHistory.forEach((eh, idx) => {
                eh.medication1 = medications1[idx];
                eh.medication2 = medications2[idx];
            });
        }
        downloadEvalPdf({ staticData, theme, fileName, evaluationDetail, evalHistory, patient: patient.data, date: summaryData.date });
    }, [patient, evaluationDetail, summaryData, staticData, theme]);

    const setCurrentMonthAction = useCallback((yearMonth) => {
        if (yearMonth !== currentMonth) {
            setCurrentMonth(yearMonth);
            loadEvaluationDetail(yearMonth);
        }
    }, [currentMonth, loadEvaluationDetail]);

    const setEvaluationDetailAction = (evalDetail) => {
        if (evalDetail && evalDetail.yearMonth) {
            // update the cache
            _setWith(evalCacheRef.current, evalDetail.yearMonth.toString(), evalDetail, Object);
        }
        setEvaluationDetail(evalDetail);
    };

    /**
     * prepares monthly evaluation summary data for consumption
     * @param {*} data Data of evaluations/{patientId}
     */
    const applySummaryData = useCallback((data) => {
        // convert summary {yymm: data,...} to [{date: {endof month}, data}]
        let items = Object.entries(data || {})
            .map(([yymm, data]) => {
                const getTreatment = async(medicationx, yearMonth) => {
                    const treatment = await firestoreStreams.collection("evaluations").doc(id)
                        .collection("detail").doc(`${yearMonth}_medic`).get()
                        .then( snapshot => {
                                const data = snapshot.data().treatment[medicationx];
                                if(data !== undefined && data !== '-') {
                                    return data;
                                } else {
                                    return '';
                                }
                        })
                        .catch (error =>  console.log(error)); 
                    return treatment;
                };

                return { date: moment(yymm, "YYMM").endOf('month'), yearMonth: parseInt(yymm), data, medication1: getTreatment('medication', parseInt(yymm)), medication2: getTreatment('medication2', parseInt(yymm))}});
        // order by date in case the keys were out of order
        items.sort((a, b) => a.date.isAfter(b.date) ? 1 : -1);

        let lastItem = items.length > 0 ? items[items.length - 1] : null;
        if (!lastItem) {
            // add current month
            const thisMonth = moment().endOf('month');
            items.push({ hasData: false, date: thisMonth, yearMonth: thisMonth.format("YYMM") });
        } else {
            // for each month in between add the missing month
            let date = moment(items[0].date).add(-1, 'M');
            const endOfMonth = moment().endOf('month');
            const newItems = [];
            // todo: optimized this code
            items.forEach(i => {
                while (i.date > (date = date.add(1, 'M').endOf("month"))) {
                    const thisDate = moment(date);
                    newItems.push({ date: thisDate, hasData: false, yearMonth: thisDate.format("YYMM") });
                }
                newItems.push(i);
            });
            while ((date = date.add(1, 'M').endOf("month")) <= endOfMonth) {
                const thisDate = moment(date);
                newItems.push({ date: thisDate, hasData: false, yearMonth: thisDate.format("YYMM") });
            }
            items = newItems;
        }

        // reconcile with the period param, in case it is requested
        console.debug("summary items is set:", !!items);
        setSummaryData(items);
        // default period to current month if not already set or if we don't have data
        if (!items.find(i => i.yearMonth === currentMonth))
            setCurrentMonthAction(items[items.length - 1].yearMonth);
    }, [currentMonth, setCurrentMonthAction, firestoreStreams, id]);


    // this is called by firestore onSnapshot
    const applySummaryDataRef = useRef(applySummaryData);
    // store this reference
    useEffect(() => {
        applySummaryDataRef.current = applySummaryData;
    }, [applySummaryData])

    // load data on navigation
    useEffect(() => {
        // we must always navigate here with params = {id, ...}
        // patient info MAY be available in location 
        if (id !== patient.id) {
            // clear the cache and reset data props
            evalCacheRef.current = {};
            setSummaryData({ loading: true });
            if (location.state) {
                console.debug("Patient data passed in location state");
                if (_get(location.state, "data.profile.versions")) {
                    // sort versions in descending order
                    location.state.data.profile.versions.sort().reverse();
                }
                setPatient({ id: location.state.id, data: location.state.data });
            } else {
                console.debug("Loading patient data, no state provided");
                setPatient({ id, loading: true });
                renewSubscriptionRef.current = true;
            }

        }
        if (firestoreStreams.isInitialized && (renewSubscriptionRef.current || !firestoreStreams.hasSubscriptions())) {
            renewSubscriptionRef.current = false;
            // subscribe to patient stream, this will automatically unsubscribe any previous subscriptions
            firestoreStreams.subscribe("patients", state => {
                const patientItem = state.changes.find(i => i.id === id);
                if (patientItem) {
                    setPatient(_cloneDeep(patientItem));
                }
            })
        }
    }, [firestoreStreams, location, id, patient, setPatient, setSummaryData, evalCacheRef, renewSubscriptionRef]);

    // subscribe to evaluation summary stream for current patient
    useEffect(() => {
        if (firestoreStreams.isInitialized) {
            // see if different patient / id
            if (id !== _get(visitSubscriptionRef.current, "patientId")) {
                if (visitSubscriptionRef.current) {
                    console.debug("Unsubscribe evaluations", visitSubscriptionRef.current.patientId);
                    visitSubscriptionRef.current.unsubscribe();
                    visitSubscriptionRef.current = null;
                }
                console.debug("Subscribe evaluations", id);
                visitSubscriptionRef.current = {
                    patientId: id,
                    unsubscribe: firestoreStreams.collection("evaluations").doc(id).onSnapshot(doc => {
                        const summary = doc.exists ? doc.data().summary : {};
                        applySummaryDataRef.current(summary);
                    })
                };
            }
        }
    }, [firestoreStreams, applySummaryDataRef, setPatient, id]);

    // cleanup subscription on unload
    useEffect(() => {
        try {
            const getPdf = firestoreStreams.getCallableFunction('getPdf');
            getPdf({ doctype: "p2", period: "2107" }).then(result => {
                console.log("PDF RESULT", result);
            })
        } catch (err) {
            // todo: handle error
            console.error("PDF ERROR", err.message);
        } 

        return () => {
            if (visitSubscriptionRef.current) {
                console.debug("Unsubscribe evaluations");
                visitSubscriptionRef.current.unsubscribe();
                visitSubscriptionRef.current = null;
            }
        }
    }, [firestoreStreams])

    return <PatientDetail patient={patient}
        evaluationSummary={summaryData}
        evaluationDetail={evaluationDetail}
        downloadEvalReport={downloadEvalReport}
        setEvaluationDetail={setEvaluationDetailAction}
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonthAction} />
}