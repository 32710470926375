import React from 'react';
import Typography from '@material-ui/core/Typography';
import { LinearFormControl } from 'src/components/widgets';
import { Divider, Button } from '@material-ui/core';
import { ChevronLeftSharp } from '@material-ui/icons';

export default ({  onBack, error }) => {
    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px", maxWidth: "600px" }}>
            <Typography style={{marginTop: 32}}>
                Credentialele dumneavoastra nu au putut fi validate.
            </Typography>
            <Typography style={{marginTop: 32}}>
                {error}
            </Typography>
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", width: "unset", justifyContent: "space-between" }}>
            <Button startIcon={<ChevronLeftSharp />} variant="outlined" disableElevation onClick={onBack}>INAPOI</Button>
        </LinearFormControl>
    </>
};