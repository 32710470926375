import React from 'react';
import { Typography, makeStyles, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useStaticData } from 'src/providers/StaticDataProvider';
import { PercentBar, ButtonWithProgress, LinearFormControl } from '.';
import { Check, ChevronRightSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => {
    return {
        table: {
            "& .MuiTableCell-root": {
                padding: "1rem",
                fontSize: "unset"
            }
        },
        selected: {
            border: '1px solid blue'
        },
        c1: {
            backgroundColor: theme.colorlib.c1.main
        },
        c2: {
            backgroundColor: theme.colorlib.c2.main
        },
        c3: {
            backgroundColor: theme.colorlib.c3.main
        },
        c4: {
            backgroundColor: theme.colorlib.c4.main
        },
        dim: {
            opacity: 0.2
        },
        default: {
            backgroundColor: "#4A4A4B"
        }
    }
});

export default (({ monthData, onSurveyAction, onContinue, readOnly, downloadSurveyAsPdf }) => {
    const classes = useStyles();
    const staticData = useStaticData();

    const surveyCodes = ['C1', 'C2', 'C3', 'C4'];

    if (!staticData.isInitialized) {
        return <div>Please wait...</div>
    }

    const autoEvalScore = monthData && (monthData["P1"] || monthData["F1"]);
    const visitForm = monthData && monthData["P2"];

    return <>
        <Table className={classes.table}>
            <TableBody>
                {surveyCodes.map((code, ix) => {
                    const surveyItem = staticData.surveys.find(i => i.id === code);
                    const _code = code.toLowerCase();
                    return <TableRow key={code} onClick={(event) => onSurveyAction && onSurveyAction('edit', code)} hover className="clickable">
                        <TableCell key="1" style={{ whiteSpace: "nowrap" }}>{surveyItem ? `${code} - ${surveyItem.data.title}` : `[Error: unknown form ${code}]`}</TableCell>
                        <TableCell key="2">
                            <div style={{ display: "flex", flex: "1 0 5rem", maxWidth: "10rem", height: "1.2rem", position: "relative", justifyContent: "center" }}>
                                {monthData && (readOnly || monthData[code]) && <><PercentBar value={monthData[code]} width="100%" height="100%" color={_code} direction="horizontal" />
                                    {monthData[code] && <Typography variant="body2" style={{
                                        position: "absolute", right: 0, left: 0,
                                        textAlign: "center",
                                        alignSelf: "center"
                                    }}>{monthData[code]}%</Typography>}
                                </>}
                            </div>
                        </TableCell>
                        <TableCell key="3" style={{ whiteSpace: "nowrap" }} >
                            {monthData && monthData[code] && downloadSurveyAsPdf &&
                                <Typography color="primary" variant="body2" onClick={(e) => {
                                    e = e || window.event;
                                    e.stopPropagation();
                                    downloadSurveyAsPdf(code);
                                }}>Descarcă Pdf</Typography>}
                            {(!monthData || !monthData[code]) && !readOnly &&
                                <Typography color="primary" variant="body2">Completează</Typography>}
                        </TableCell>
                    </TableRow>
                })}
                {monthData && monthData["$F"] && <TableRow key="func">
                    <TableCell style={{ whiteSpace: "nowrap" }}>Capacitatea totala de functionare
                    </TableCell>
                    <TableCell>
                        <div style={{ display: "flex", flex: "1 0 5rem", maxWidth: "10rem", height: "1.2rem", position: "relative", justifyContent: "center" }}>
                            <PercentBar value={monthData["$F"]} width="100%" height="100%" color="$f" direction="horizontal" />
                            <Typography variant="body2" style={{
                                position: "absolute", right: 0, left: 0,
                                textAlign: "center",
                                alignSelf: "center"
                            }}>{monthData["$F"]}%</Typography>
                        </div>
                    </TableCell>
                </TableRow>}

                {(visitForm || autoEvalScore) && <TableRow>
                    <TableCell colSpan={2}><Typography variant="h6" style={{ paddingTop: "1rem" }}>Formulare Pacient</Typography></TableCell>
                </TableRow>}
                {visitForm && <TableRow key="visit" onClick={(event) => onSurveyAction && onSurveyAction('view', "P2")} hover className="clickable">
                    <TableCell style={{ whiteSpace: "nowrap" }}>Pregatire Vizită</TableCell>
                    <TableCell>
                        <div style={{ display: "flex", flex: "1 0 5rem", maxWidth: "10rem", height: "1.2rem", position: "relative", justifyContent: "center" }}>
                            <Check />
                        </div>
                    </TableCell>
                </TableRow>}
                {autoEvalScore && <TableRow key="auto" onClick={(event) => onSurveyAction && onSurveyAction('view', !!monthData["P1"] ? "P1" : "F1")} hover className="clickable">
                    <TableCell style={{ whiteSpace: "nowrap" }}>Autoevaluare - Dizabilitate</TableCell>
                    <TableCell>
                        <div style={{ display: "flex", flex: "1 0 5rem", maxWidth: "10rem", height: "1.2rem", position: "relative", justifyContent: "center" }}>
                            <PercentBar value={Math.round(autoEvalScore)} width="100%" height="100%" color="$auto" direction="horizontal" />
                            <Typography variant="body2" style={{
                                position: "absolute", right: 0, left: 0,
                                textAlign: "center",
                                alignSelf: "center"
                            }}>{Math.round(autoEvalScore)/10}</Typography>
                        </div>
                    </TableCell>
                </TableRow>}

            </TableBody>
        </Table>
        {!readOnly && <LinearFormControl>
            <ButtonWithProgress variant="outlined" endIcon={<ChevronRightSharp />}
                onClick={event => onContinue()}>Continuă</ButtonWithProgress>
        </LinearFormControl>}
    </>
})