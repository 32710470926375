import React, { useState, useLayoutEffect } from 'react';
import { cloneDeep as _cloneDeep, get as _get, set as _set, unset as _unset } from 'lodash';
import { Button, TextField, FormControl } from '@material-ui/core';
import { useStaticData } from 'src/providers/StaticDataProvider';
import { Autocomplete, Alert, AlertTitle } from '@material-ui/lab';
import { LinearFormControl, ButtonWithProgress } from 'src/components/widgets';

export default (({ evalDetail, readOnly, onContinue, saveInProgress }) => {
    const staticData = useStaticData();
    const [myDetail, setMyDetail] = useState(_cloneDeep(evalDetail) || {});

    useLayoutEffect(() => {
        setMyDetail(_cloneDeep(evalDetail) || {});
    }, [evalDetail]);

    const myData = myDetail.therapy || {};

    const handleChange = (key, value) => {
        const data = _cloneDeep(myData);
        if (key === "medication" && value === "") {
            data.medication = data.medication2;
            delete data.medication2;
        } else {
            data[key] = value;
        }
        setMyDetail({ ...myDetail, therapy: data });
    }

    const handleContinueTreatment = () => {
        const data = _cloneDeep(myDetail);
        _unset(data, "therapy.medication");
        _unset(data, "therapy.medication2");
        let val = _get(evalDetail, "treatment.medication");
        if (val) {
            _set(data, "therapy.medication", _get(evalDetail, "treatment.medication"));
        }
        val = _get(evalDetail, "treatment.medication2")
        if (val) {
            _set(data, "therapy.medication2", _get(evalDetail, "treatment.medication2"));
        }
        setMyDetail(data);
    }

    const options = staticData.isInitialized ? staticData.medication.schizo : [];
    const prevMedication1 = staticData.isInitialized &&
        (staticData.medication.schizo.find(m => m.id === _get(evalDetail, "treatment.medication")) || {});
    const prevMedication2 = staticData.isInitialized &&
        (staticData.medication.schizo.find(m => m.id === _get(evalDetail, "treatment.medication2")) || {});
    const prevMedicationLabel = <><span>{prevMedication1.label}</span>{prevMedication2 && <span> + {prevMedication2.label}</span>}</>;

    const medTerValue = options.find(o => o.id === myData.medication);
    const medTerValue2 = options.find(o => o.id === myData.medication2);
    const defaultMed = { id: "-", label: "-" };

    return !myData ? <></> : <>
        {!readOnly && (prevMedication1 || prevMedication2) &&
            <FormControl fullWidth><Alert severity="info"
                action={<Button size="small" variant="text" color="primary" onClick={handleContinueTreatment}>Continuă același tratament</Button>}>
                <AlertTitle>Tratament anterior</AlertTitle>
                {prevMedicationLabel}
            </Alert></FormControl>}
        {readOnly &&
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Tratament" value={(medTerValue || defaultMed).label} />}
        {readOnly && medTerValue && medTerValue2 &&
            <TextField InputProps={{ disableUnderline: true, readOnly }} fullWidth label="Tratament adițional" value={medTerValue2.label} />}
        {!readOnly &&
            <Autocomplete
                value={medTerValue || defaultMed}
                style={{ width: "20rem" }}
                options={[defaultMed, ...options]}
                renderInput={(params) => <TextField {...params}
                    label="Tratament"
                    variant="standard" />}
                getOptionLabel={option => option.label}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                    // console.log(JSON.stringify(value));
                    handleChange("medication", value ? value.id : "-")
                }
                }
            />}
        {!readOnly && medTerValue && <Autocomplete
            value={medTerValue2 || defaultMed}
            style={{ width: "20rem" }}
            options={[defaultMed, ...options]}
            renderInput={(params) => <TextField {...params}
                label="Tratament adițional"
                variant="standard" />}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => handleChange("medication2", value ? value.id : "-")}
        />}
        <TextField multiline fullWidth value={myData.note || ""} readOnly={readOnly} maxRows={10}
            InputProps={{ disableUnderline: readOnly, readOnly }}
            onChange={event => handleChange("note", event.target.value)} label="Note" />
        {!readOnly &&
            <LinearFormControl>
              <ButtonWithProgress variant="outlined" showProgress={saveInProgress}
                    onClick={() => onContinue(myDetail)}>Salvează</ButtonWithProgress>
            </LinearFormControl>}
    </>
})