import React from 'react';
import { FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import LinearFormControl from './LinearFormControl';

export default ({ label, value, options, onChange, direction = "row" }) => {
    let myOptions = options;
    if (options === 'yes_no') {
        myOptions = [
            { label: "Da", value: "yes" },
            { label: "Nu", value: "no" }
        ]
    }
    return <LinearFormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup name="quiz" style={{ flexDirection: "row", marginLeft: "1rem", flex: 1 }} value={value || ""} onChange={onChange}>
            {myOptions.map(o =>
                <FormControlLabel value={o.value} control={<Radio color="primary" />} label={o.label} key={o.value} />
            )}
        </RadioGroup>
    </LinearFormControl>
}