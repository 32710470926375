import React from 'react';
import { FormControl, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
    noLeftMargin: {
        "& .MuiButton-root:first-child": {
            marginLeft: 0
        },
        "& .MuiButton-root:last-child": {
            marginRight: 0
        }
    }
}));

export default (({ centered, children, style, className, compact, wrap, ...rest }) => {
    const classes = useStyles();
    const _className = classnames(!centered && classes.noLeftMargin, className);

    const _style = Object.assign({
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        position: "relative",
        width: "100%"
    },
        centered ? {justifyContent: "center" } : {},
        style || {},
        compact ? {margin: 0} : {},
        wrap ? {flexWrap: "wrap"} : {});

    return <FormControl className={_className} {...rest} style={_style}>{children}</FormControl>
})