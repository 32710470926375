import { get as _get, isNumber as _isNumber } from 'lodash';
import moment from 'moment';
import Color from 'color';
import pdfMake from 'pdfmake/build/pdfmake';

pdfMake.fonts = {
    // download default Roboto font from cdnjs.com
    Roboto: {
        normal: `${process.env.REACT_APP_FONT_CDN_URL}/Roboto/Roboto-Regular.ttf`,
        bold: `${process.env.REACT_APP_FONT_CDN_URL}/Roboto/Roboto-Medium.ttf`
    }
}

var styles = {
    header: {
        fontSize: 14,
        bold: true,
        margin: [0, 20, 0, 10]
    },
    title: {
        fontSize: 24,
        bold: true,
    },

    fieldTitle: {
        fontSize: 11,
        margin: [0, 5, 0, 5]
    },
    cellValue: {
        fontSize: 11,
        margin: [0, 15, 0, 0]
    },
    fieldValue: {
        bold: true,
        fontSize: 14,
        margin: [0, 0, 0, 5]
    },
    smallPrint: {
        fontSize: 8.5,
        margin: [45, 0, 30, 10]
    },
    monthHeader: {
        fontSize: 14,
        margin: [0, 20, 0, 5]
    },
    monthFooter: {
        fontSize: 16,
        bold: true,
        margin: [0, 5, 0, 5]
    },
    body: {
        fontSize: 10,
        bold: false,
        margin: [0, 5, 0, 5]
    }
};

export const downloadPdf = (({ fileName, staticData, evalHistory, patient, date, theme }) => {
    const surveyCodes = ['c1', 'c2', 'c3', 'c4'];

    const upcomingAppointments = Object.values(_get(patient, "appointments.upcoming", {})).sort((a, b) => a > b ? 1 : -1);
    const nextAppointment = upcomingAppointments.length > 0 && upcomingAppointments[0];

    const isCaregiver = (patient.role === 'caregiver');
    let patientName = `${patient.patientName || patient.name}`;
    let caregiverName = isCaregiver ? `${patient.name}` : '-';
    let autoevalKey = isCaregiver ? `F1` : 'P1';
    let medicName = _get(patient, 'medic.name', '-');
    const patientEmail = isCaregiver ? '-' : patient.email;
    const caregiverEmail = isCaregiver ? patient.email : '-';

    let history = evalHistory.slice(-6);
    const firstDate = history[0].date;
    const lastDate = history[history.length - 1].date;

    let mostRecentDate = lastDate;
    let evalData = {};
    evalHistory.forEach(monthData => {
        if (monthData.data) {
            mostRecentDate = monthData.date;
            evalData = monthData.data;
        }
    });

    const autoEvalScore = parseFloat(_get(evalData, autoevalKey))/10;
    const autoEvalFinal = Number(Math.round(Number(autoEvalScore+'e'+1))+'e-'+1);

    let historyHeaders = history.map(monthData => ({
        text: monthData.date.format("MM.YYYY"),
        alignment: 'center',
        style: 'monthHeader'
    }));

    let historyFooters = history.map(monthData => {
        const val = _get(monthData, 'data.$F');
        const valPercent = _isNumber(val) ? `${val}%` : '-';
        let medicationText = "";
        const medication1 = monthData.medication1 || '';
        if (medication1) {
            medicationText = staticData.medication.schizo.find(m => m.id === medication1)?.label || "?";
        }
        const medication2 = monthData.medication2 || '';
        if (medication2) {
            medicationText += ", " + staticData.medication.schizo.find(m => m.id === medication2)?.label || "?";
        }
        return {
            stack: [
                valPercent,
                medicationText ? {text: medicationText, style: 'body' } : {}
            ],
            alignment: 'center',
            style: 'monthFooter'
        };
    })
    let classes = '';
    Object.entries(theme.colorlib).forEach(c => {
        if (!_get(c[1], `main`))
            return;
        const color = Color(c[1].main).hex();
        const border = Color(c[1].border).hex();
        classes += `.cls-${c[0]}-back{fill:${color};fill-opacity:0.3}`;
        classes += `.cls-${c[0]}-fill{fill:${color};}`;
        classes += `.cls-${c[0]}-line{stroke:${border};fill:white}`;
    });

    let historyCharts = history.map(monthData => {
        const width = 12;
        const gap = 4;
        const height = 70;
        let bars = '';
        const totalWidth = width * 4 + gap + 3;
        surveyCodes.forEach((c, ix) => {
            const dx = ix * (width + gap);
            const val = _get(monthData, `data.${c.toUpperCase()}`);
            if (!_isNumber(val)) {
                bars += `<rect id="${c}_border" data-name="${c}_border" class="cls-${c}-line" x="${dx}" width="${width}" height="${height}" />`;
            } else {
                bars += `<rect id="${c}_back" data-name="${c}_back" class="cls-${c}-back" x="${dx}" width="${width}" height="${height}" />`;
                bars += `<rect id="${c}_fill" data-name="${c}_fill" class="cls-${c}-fill" x="${dx}" width="${width}" y="${height * (100 - val) / 100}" height="${height * val / 100}" />`;
            }
        });

        return {
            alignment: 'center',
            svg: `<svg viewBox="0 0 60 80" width="${totalWidth}" height="${height}"><defs><style>${classes}</style></defs><g id="Layer_1-2" data-name="Layer 1">${bars}</g></svg>`,
            width: totalWidth,
            height
        }
    });
    let evalDetail = [];
    const renderEvalItem = (classes, classPrefix, val, margin) => {
        const width = 200;
        const height = 15;
        let bar = '';
        if (!_isNumber(val) || isNaN(val)) {
            bar = `<rect id="border" data-name="border" class="${classPrefix}-line" width="${width}" height="${height}" />`;
        } else {
            bar = `<rect id="back" data-name="back" class="${classPrefix}-back" width="${width}" height="${height}" />`;
            bar += `<rect id="fill" data-name="fill" class="${classPrefix}-fill" width="${width * val / 100}" height="${height}" />`;
            bar += `<text x="${width / 2 - 8}" y="${height - 3}" font-size="12" font-weight="bold">${val}%</text>`;
        }
        return {
                svg: `<svg viewBox="0 0 200 15"><defs><style>${classes}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">${bar}</g></g></svg>`,
                style: 'cellValue',
                margin
            };
    }

    surveyCodes.forEach((c, ix) => {
        const item = staticData.surveys.find(i => i.id === c.toUpperCase());
        const margin = (ix === surveyCodes.length - 1) ? [0, 15, 0, 15] : undefined;
        const val = _get(evalData, c.toUpperCase());
        const bar = renderEvalItem(classes, `cls-${c}`, val, margin);
        evalDetail.push([
            { text: `${c.toUpperCase()}  `, style: 'cellValue' },
            { text: item.data.title, style: 'cellValue' },
            bar
        ]);
    });

    var pdfDefinition = {
        footer: {
            text: 'Raport exportat din platforma software de informare si sprijinire a MEDICILOR PSIHIATRI in coordonarea planului terapeutic si comunicarea cu pacientii diagnosticati cu schizofrenie si apartinatorii lor',
            style: 'smallPrint',
        },
        content: [
            {
                columnGap: 30,
                columns:
                    [
                        // {
                        //     // if you specify width, svg will scale proportionally
                        //     svg: '<svg viewBox="0 0 350 133" width="350" height="133"><defs><style>.cls-1{fill:#484fc7;}.cls-2{fill:#39b54a;}.cls-3{fill:#ff8b00;}.cls-4{fill:#f0164b;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M205.61,133.23A48.57,48.57,0,1,1,254,81l.41,5.42H167.17a38.53,38.53,0,0,0,38.44,36.73,38.13,38.13,0,0,0,23.14-7.73,38.82,38.82,0,0,0,13.7-19.59,5,5,0,1,1,9.66,2.91,49,49,0,0,1-17.29,24.74A48.13,48.13,0,0,1,205.61,133.23Zm0-87a38.54,38.54,0,0,0-37.56,30.13h75.13a38.51,38.51,0,0,0-37.57-30.13Z"></path><path class="cls-2" d="M152.92,133A24.45,24.45,0,0,1,134.08,125c-5-5.62-7.52-13.5-7.52-23.42V5.05a5,5,0,1,1,10.09,0v96.48c0,13.61,5.93,21.42,16.27,21.42a5,5,0,1,1,0,10.09Z"></path><path class="cls-3" d="M348.24,132.49a5,5,0,0,1-3.52-1.43L259,47.6a5,5,0,1,1,7-7.23l85.77,83.45a5,5,0,0,1-3.52,8.67Z"></path><path class="cls-3" d="M262.47,132.49a5,5,0,0,1-3.52-8.67l85.77-83.45a5,5,0,1,1,7,7.23L266,131.06A5,5,0,0,1,262.47,132.49Z"></path><path class="cls-4" d="M110.48,122.62c-5.7,0-6.81-1.85-7.67-4.27-1.52-4.27-1.64-11.56-1.64-22.2V43.74a5,5,0,0,0-10.09,0V53.83a50.58,50.58,0,1,0,.54,59.73,34.69,34.69,0,0,0,1.68,8.17c2.63,7.39,8.25,11,17.18,11a5,5,0,1,0,0-10.09Zm-59.91,1.93A40.48,40.48,0,1,1,91.05,84.07,40.53,40.53,0,0,1,50.57,124.55Z"></path><circle class="cls-1" cx="64.97" cy="69.22" r="6.97"></circle><circle class="cls-1" cx="35.54" cy="69.22" r="6.97"></circle><path class="cls-1" d="M52.63,108.09V100c8.73,0,16.1-5.24,16.1-11.45h8.06C76.79,99.34,66,108.09,52.63,108.09Z"></path><path class="cls-4" d="M48.6,88.58v8c-8.72,0-16.1,5.25-16.1,11.46H24.44C24.44,97.33,35.28,88.58,48.6,88.58Z"></path></g></g></svg>',
                        //     width: 80
                        // },
                        {
                            width: '*',
                            text: 'Raport functionalitate pacient',
                            style: 'title',
                            margin: [0, 5, 0, 3]
                        }
                    ]
            },
            {
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0) ? 0 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    },
                    paddingTop: function (i, node) { return 10; },
                },
                table: {
                    widths: ['*', 10, '*'],
                    body: [
                        [
                            {
                                style: 'fieldTitle',
                                text: [
                                    { text: 'DATA: ' },
                                    { text: moment().format("DD.MM.YYYY"), style: 'fieldValue' }
                                ]
                            },
                            {
                                text: '',
                                border: [false, false, false, false]
                            },
                            ''
                        ],
                        [
                            [
                                { text: 'PACIENT', style: 'fieldTitle' },
                                { text: patientName, style: 'fieldValue' },
                                { text: patientEmail, margin: [0, 0, 0, 8] },
                            ],
                            {
                                text: '',
                                border: [false, false, false, false]
                            },
                            [
                                { text: 'APARTINATOR', style: 'fieldTitle' },
                                { text: caregiverName, style: 'fieldValue' },
                                { text: caregiverEmail, margin: [0, 0, 0, 8] },
                            ]
                        ],
                        [
                            [{ text: 'DATA URMATOAREI CONSULTATII', style: 'fieldTitle' },
                            { text: nextAppointment ? moment(nextAppointment).format("DD.MM.YYYY") : '-', style: 'fieldValue' },
                            ],
                            {
                                text: '',
                                border: [false, false, false, false]
                            },
                            [{ text: 'MEDIC SPECIALIST', style: 'fieldTitle' },
                            { text: medicName, style: 'fieldValue' },
                            ]
                        ]
                    ]
                },
            },
            { text: `Evolutie functionalitate pacient: ${firstDate.format("MM.YYYY")} - ${lastDate.format("MM.YYYY")}`, style: 'header' },
            {
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === 4) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    }
                },
                table: {
                    widths: ['*', '*', '*', '*', '*', '*'],
                    body: [
                        historyHeaders,
                        historyCharts, 
                        historyFooters,
                    ]
                }
            },
            { text: `Evaluare pacient - ${mostRecentDate.format("MM.YYYY")}`, style: 'header' },
            {
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === 4) ? 1 : 0;
                    },
                    vLineWidth: function (i, node) {
                        return 0;
                    }
                },
                table: {

                    widths: ['auto', '*', 200],
                    body: [
                        ...evalDetail,
                        [
                            {
                                colSpan: 2,
                                text: 'Capacitatea totala de functionare',
                                style: 'cellValue',
                            },
                            {},
                            renderEvalItem(classes, 'cls-functionality', _get(evalData, '$F')),
                        ],
                        [
                            {
                                colSpan: 2,
                                text: 'Autoevaluare pacient',
                                style: 'cellValue'
                            },
                            {},
                            renderEvalItem(classes, 'cls-autoeval', autoEvalFinal)
                        ]
                    ]
                }
            }
        ],
        styles
    };

    // pdfMake.createPdf(pdfDefinition).open();
    pdfMake.createPdf(pdfDefinition).download(fileName);
    // return pdfDefinition;
}); 