import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router';
import { get as _get, isNil as _isNil } from 'lodash';
import { Typography, TextField, Button, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ChevronLeftSharp, Equalizer, PictureAsPdf } from '@material-ui/icons';

// internal references
import ContentWithActionBarComponent from 'src/components/layout/contentWithActionBar';
import FunctionalityTimeline from 'src/components/widgets/functionalityTimeline';
import VisitDetail from './VisitDetail';
import { calendar } from 'src/utils';
import useLanguage from '../../translations/useLanguage';

export default ({ patient, evaluationSummary, evaluationDetail, setEvaluationDetail,
    currentMonth, setCurrentMonth, downloadEvalReport }) => {

    const {translations} = useLanguage();
    const history = useHistory();
    const location = useLocation();

    // visibility flag for the chart section on top
    const [showChart, setShowChart] = useState(false);

    // references used for syncing param values to display state
    const currentSurveyIdRef = useRef();
    const currentStepRef = useRef();

    // load specific display context from location url parameters
    const params = new URLSearchParams(location.search);
    useEffect(() => {
        currentSurveyIdRef.current = params.get("eval");
        currentStepRef.current = parseInt(params.get("step"));
    }, [params]);

    // update location url when display context changes
    const updateLocationParam = (key, value) => {
        if (_isNil(value) && params.has(key)) {
            params.delete(key);
            history.replace({ ...location, search: params.toString() });
        } else if (!_isNil(value) && params.get(key) !== value) {
            params.set(key, value.toString());
            history.replace({ ...location, search: params.toString() });
        }
    }

    if (!patient.id) {
        return <div>{translations.patientDetail.noPatientSelectedError}</div>
    }
    if (patient.error) {
        return <div>Error: {patient.error.message}</div>
    }

    const patientApName = patient.data.patientFamilyName + " " + patient.data.patientGivenName;
    const patientNoName = patient.data.displayName;
    const patientType = patient.data && patient.data.role === "caregiver" ? translations.patientDetail.caregiver + patientNoName : translations.patientDetail.patient;

    const patientName = patient.data && patient.data.role === "caregiver" ? patientApName : patientNoName;

    const header = <div style={{ display: "flex", alignItems: "flex-start", marginTop: "1rem" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5" noWrap>{patient.loading ? <Skeleton /> : patientName}</Typography>
            <Typography variant="body2">{patient.loading ? <Skeleton /> : patientType}</Typography>
            
        </div>
        {/* MONTHLY CHART */ 
            currentMonth &&
            <div style={{ display: "inline-flex", marginLeft: "1rem", alignItems: "end" }}>
                <IconButton size="small" onClick={() => downloadEvalReport()}>
                    <PictureAsPdf />
                </IconButton>
                <IconButton size="small" onClick={() => setShowChart(!showChart)}>
                    {showChart ? <ChevronLeftSharp /> : <Equalizer />}
                </IconButton>
                {showChart && <FunctionalityTimeline
                    evaluationDetail={evaluationDetail}
                    evaluationSummary={evaluationSummary}
                    selectedMonth={currentMonth}
                    setSelectedMonth={setCurrentMonth} />}
            </div>}
    </div>;
    // sort by date desc
    const recentAppointments = Object.values(_get(patient, "data.appointments.recent", {})).sort((a, b) => a > b ? -1 : 1);
    // sort by date asc
    const upcomingAppointments = Object.values(_get(patient, "data.appointments.upcoming", {})).sort((a, b) => a > b ? 1 : -1);
    const lastAppointment = recentAppointments.length > 0 && recentAppointments[0];
    const nextAppointment = upcomingAppointments.length > 0 && upcomingAppointments[0];

    return <ContentWithActionBarComponent
        content={<>
            {header}
            {currentMonth && !evaluationSummary.loading && <VisitDetail
                patient={patient}
                evalHistory={evaluationSummary}
                evalSummary={evaluationSummary.find(i => i.yearMonth === currentMonth)}
                evalDetail={evaluationDetail}
                setEvalDetail={setEvaluationDetail}
                currentSurveyId={currentSurveyIdRef.current}
                setCurrentSurveyId={id => {
                    currentSurveyIdRef.current = id;
                    updateLocationParam("eval", id);
                }}
                currentStepId={currentStepRef.current}
                setCurrentStepId={id => {
                    currentStepRef.current = id;
                    updateLocationParam("step", id);
                }} />}
        </>}
        actionBarProps={!currentSurveyIdRef.current && {
            style: {
                whiteSpace: "nowrap",
                paddingLeft: "1rem", paddingRight: "1rem", minWidth: "15rem"
            }
        }}
        actionBarContent={!currentSurveyIdRef.current && <>
            {lastAppointment && <TextField label={translations.patientDetail.lastAppointment} variant="standard"
                InputProps={{ readOnly: true, disableUnderline: true }}
                value={calendar(lastAppointment)} />}
            {nextAppointment && <TextField label={translations.patientDetail.nextAppointment} variant="standard"
                InputProps={{ readOnly: true, disableUnderline: true }}
                value={calendar(nextAppointment)} />}
            <Button variant="outlined" color="primary" style={{ margin: "1.5rem 0 0 0" }}
                onClick={() => history.push({ pathname: "/medic/calendar", search: `?book=${patient.id}` })}>{translations.patientDetail.addAppointment}</Button>
        </>} />
}