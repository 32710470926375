import { get as _get, setWith as _setWith, has as _has, unset as _unset } from 'lodash';

export default class {

    constructor(demographicProfile, surveyDefinition) {
        this.demographicProfile = demographicProfile;
        this.surveyDefinition = surveyDefinition;
    }

    isActiveItem = (item) => {
        if (!item.conditions) {
            return true;
        }
        // this will be used in eval
        let profile = this.demographicProfile;
        // this could also be loading
        if (!profile)
            return false;

        let conditions = item.conditions;
        if (typeof (conditions) === "string") {
            conditions = [conditions];
        }
        if (conditions.find(i => !i.startsWith("profile."))) {
            console.warn("INVALID DISPLAY CONDITION", item.title, JSON.stringify(conditions));
            return false;
        }
        let evalText = `true`;
        conditions.forEach(c => {
            // for now we use AND logic
            evalText += ` && (${c})`;
        });
        console.log(evalText);
        /* eslint no-eval: 0 */
        return eval(evalText);
    }

    enrichMetadata = (item, parentScoreItemPath, scoreItems = [], path = [], totalValue = { value: 0 }) => {
        const isRootItem = item.tag === 'survey';
        // my path relative to the root of the survey definition
        const myPath = isRootItem ? [] : [...path, item.id];
        // if we keep score, pass down the tree a new array
        // otherwise we mutate the existing one
        const myItems = isRootItem || item.score ? [] : scoreItems;
        // path to the parent where the value/score of this item is to be rolled up into a score
        const myScoreItemPath = item.score ? myPath : parentScoreItemPath;
        // pass this as an object so that we can mutate it recursively
        let myTotalValue = item.score ? { value: 0 } : totalValue;
        if (Array.isArray(item.items)) {
            item.items = item.items.filter(i => this.isActiveItem(i));
            item.items.forEach(i => {
                if (i.type === 'question') {
                    myItems.push([...myPath, i.id, "value"]);
                    // this is the path of the item (definition) where the score needs to be rolled up
                    // when the value changes for this question, the score is calculated from the parent up (as defined)
                    // by using the "scoreItems" property of the parent
                    i.parentScoreItemPath = myScoreItemPath;
                    // store the max range value, attach it to the item def as well
                    if (i.question_type.startsWith("range_")) {
                        const [min, max] = i.question_type.substring(6).split("_");
                        i.minValue = parseInt(min);
                        i.maxValue = parseInt(max);
                    } else if (i.score === "count" && i.question_type === 'select_many') {
                        i.maxValue = i.select_options.length;
                        i.minValue = 0;
                    }
                    myTotalValue.value += i.maxValue;
                } else if (i.type === 'section') {
                    if (i.score) {
                        i.parentScoreItemPath = myScoreItemPath;
                        myItems.push([...myPath, i.id, "score"]);
                    }
                    if (Array.isArray(i.info)) {
                        // concatenate the strings
                        i.info = i.info.reduce((a, b) => a + b);
                    }
                    this.enrichMetadata(i, myScoreItemPath, myItems, myPath, myTotalValue);
                    myTotalValue.value += i.score ? i.maxValue : 0;
                }
            })
        }
        if (myItems.length > 0) {
            // this is an array of paths in the results document (evaluations/detail) 
            // that either have a "value" or a "score" leaf property to roll up into the parent item score
            item.scoreItems = myItems;
            // evaluate total value
            switch (item.score) {
                case "average":
                    item.maxValue = myTotalValue.value / myItems.length;
                    break;
                case "percent":
                    item.totalValue = myTotalValue.value;
                    item.maxValue = 100;
                    break;
                default:
                    break;
            }
        }
    }

    // goes up the tree and rolls up scores
    computeScores = (path, results) => {
        let item = this.surveyDefinition;
        path.forEach(id => item = item.items.find(i => i.id === id));

        if (item.scoreItems && !item.scoreItems.find(i => !_has(results, i))) {
            let score = false;
            let totalScore = parseFloat(item.scoreItems.reduce((s, path) => {
                var numericValue = _get(results, path, 0);
                // if question is select_many, score type would be count
                if (Array.isArray(numericValue)) {
                    numericValue = numericValue.length;
                }
                return s + numericValue;
            }, 0));
            if (item.score === 'average') {
                // path is value path, s is rolling sum
                score = totalScore / item.scoreItems.length;
                score = parseFloat(score.toFixed(1));
            } else if (item.score === 'percent') {
                score = Math.round(100 * totalScore / item.totalValue);
            }
            if (score !== false) {
                _setWith(results, [...path, "autoScore"], score, Object);
            } else {
                _unset(results, [...path, "autoScore"]);
            }
            // set main score if there is no override
            if (_get(results, [...path, "overrideScore"], false) === false) {
                _setWith(results, [...path, "score"], score, Object);
            }
        }
        // move up to the parent if any
        if (item.parentScoreItemPath) {
            this.computeScores(item.parentScoreItemPath, results);
        }
    }
}