/* eslint-disable no-console */
import { Observable, BehaviorSubject } from 'rxjs';
import { firebase, firestore } from 'src/firebase';
import 'firebase/functions';

class RxStreams {
  // private fields
  /**
   * Stores streams based on firestore queries
   * These may not be subscribed to, so there is no state maintained once there is no listener
   */
  #liveStreams;
  /**
   * Stores background liveStream subscriptions, so that data is maintained even without active subscribers
   */
  #subscriptions;

  constructor() {
    this.liveStreams = new Map();
    this.subscriptions = [];
  }

  cleanup = () => {
    // unsubscribe existing streams
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.lengh = 0;
    this.liveStreams.clear();
  };

  subscribe = (name, next, error, complete) => {
    if (!this.liveStreams.has(name)) {
      console.debug('INVALID STREAM', name);
      return null;
    }
    // keep a proxy observable / subject
    return this.liveStreams.get(name).subscribe(next, error, complete);
  }

  /**
   * Registers a stream for future reference (adding subscriptions)
   * If @subscribe is true, it also sets up an internal subscription to the stream
   * in order to avoid reading large amounts of data on first active subscriptions
   * @param {string} name Key to access the stream
   * @param {Observable} observable an Observable instance, typically returned by asStatefulQueryObservable
   * @param {boolean} subscribe 
   */
  registerStream(name, observable, subscribe = false) {
    if (!observable instanceof Observable) {
      console.error("registerStream: observable must be of type rxjs.Observable");
      return;
    }
    this.liveStreams.set(name, observable);
    if (subscribe === true) {
      this.subscriptions.push(this.subscribe(name));
    }
  }

  /**
   * Registers a stream for future reference (adding subscriptions)
   * If @subscribe is true, it also sets up an internal subscription to the stream
   * in order to avoid reading large amounts of data on first active subscriptions
   * @param {string} name 
   * @param {string} collectionName Name of firestore collection
   * @param {Query => Query} whereFn 
   * @param {*} stateFilterFn 
   * @param {boolean} subscribe 
   */
  registerQueryStream(name, collectionName, whereFn, stateFilterFn, subscribe = false, oneTimeLoad = false) {
    const query = this.getCollectionQuery(collectionName, whereFn);
    return this.registerStream(name,
      this.asStatefulQueryObservable(query, name, stateFilterFn, oneTimeLoad),
      subscribe);
  }

  /**
   * Wrapper to firestore.collection(...).where(...) 
   * @param {string} collectionName Name of Firestore collection
   * @param {*} whereFn Query filter function, it takes a Query param and returns the filtered Query
   * @returns {Query} A query for a firestore collection, with an optional filter
   */
  getCollectionQuery = (collectionName, whereFn) => {
    const collection = firestore.collection(collectionName);
    return typeof (whereFn) === 'function' ? whereFn(collection) : collection;
  }

  collection = (collectionName) => {
    return firestore.collection(collectionName);
  }

  deleteItem = (collectionName, itemId) => {
    const collection = firestore.collection(collectionName);
    return collection.doc(itemId).delete();
  }

  getItem = (collectionName, itemId) => {
    const collection = firestore.collection(collectionName);
    return collection.doc(itemId).get().then(ref =>
      ref.exists ? { id: ref.id, data: ref.data() } : { exists: false });
  }

  updateRef = (ref, data) => {
    return ref.get().then(doc => {
      if (doc.exists)
        return doc.ref.update(data);
      else
        return doc.ref.set({}).then(() => doc.ref.update(data));
    });
  }

  saveItem = (collectionName, itemId, data, options) => {
    const collection = firestore.collection(collectionName);
    const docRef = itemId ? collection.doc(itemId) : collection.doc();
    return docRef.set(data, options).then(() => docRef.get());
  }

  updateItem = (collectionName, itemId, data) => {
    const collection = firestore.collection(collectionName);
    return this.updateRef(itemId ? collection.doc(itemId) : collection.doc(), data);
  }

  getCallableFunction = (functionName, region) => {
    // todo: handle default region via env
    const defaultRegion = 'europe-west2';
    return firebase.app().functions(region || defaultRegion).httpsCallable(functionName);
  }

  /**
   * Returns a stateful Observable instance based on a firestore query
   * Multiple item collections can be maintained by providing a map of filter functions
   */
  asStatefulQueryObservable = (query, name, stateFilterFn, oneTimeLoad) => {
    let refCount = 0;
    const observable = this.asQueryObservable(query, name);
    const defaultState = () => ({ items: [], changes: [] });
    let stateVal = defaultState();
    if (stateFilterFn instanceof Map) {
      stateVal.items = new Map();
      stateFilterFn.forEach((value, key) => stateVal.items.set(key, []));
    }

    const subject = new BehaviorSubject(stateVal);
    let mySubscription = null;

    return new Observable(subscriber => {
      if (refCount === 0) {
        mySubscription = observable.subscribe(snapshot => {
          // remove all that changed and add only what is not removed
          const changedIds = snapshot.changes.map(i => i.id);
          // store back in state
          if (stateFilterFn instanceof Map) {
            stateFilterFn.forEach((filterFn, key) => {
              const items = stateVal.items.get(key).filter(i => !changedIds.includes(i.id))
                .concat(snapshot.changes.filter(i => i.changeType !== 'removed'));
              stateVal.items.set(key, items.filter(filterFn));
            });
          } else {
            const items = stateVal.items.filter(i => !changedIds.includes(i.id))
              .concat(snapshot.changes.filter(i => i.changeType !== 'removed'));
            stateVal.items = stateFilterFn ? items.filter(stateFilterFn) : items;
            stateVal.changes = snapshot.changes;
          }
          subject.next(stateVal);

          if (oneTimeLoad) {
            // disconnect from underlying query observable, we will keep the current state
            mySubscription.unsubscribe();
            mySubscription = null;
          }
        });
      }
      const subjectSubscription = subject.subscribe(subscriber);
      refCount += 1;

      return () => {
        subjectSubscription.unsubscribe();
        refCount -= 1;
        if (refCount === 0) {
          if (mySubscription) {
            // disconnect from underlying query observable
            mySubscription.unsubscribe();
            mySubscription = null;
          }
          // clear state
          stateVal = defaultState();
          subject.next(stateVal);
        }
      }
    });
  };

  /**
   * Returns a stateless Observable instance based on a firestore query
   * It is a wrapper around the query.onSnapshot method
   */
  asQueryObservable = (query, name) => {
    const queryName = name;
    const queryObservable = new Observable(subscriber => {
      console.info('SUBSCRIBE', queryName);
      // subscribe to firebase snapshot
      const queryUnsubscribe = query.onSnapshot(
        snapshot => {
          subscriber.next({
            changes: snapshot.docChanges().map(c => ({
              id: c.doc.id,
              changeType: c.type,
              data: c.doc.data()
            }))
          }
          );
        },
        err => {
          console.error('STREAM ERROR', queryName, err);
          subscriber.error(err);
          // todo: reconnect snapshot
        }
      );

      // Provide a way of canceling and disposing the interval resource
      return function unsubscribe() {
        console.info('UNSUBSCRIBE', queryName);
        queryUnsubscribe();
      };
    });

    return queryObservable;
  };

  cleanupStreams = () => { };
}

export default RxStreams;
