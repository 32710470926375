import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
// internal references
import { withAuth } from 'src/providers/AuthProvider';
import NotFoundComponent from 'src/components/notFound';
import PrivateRoute from './privateRoute';
import PatientsComponent from 'src/components/patients/patients';
import SignIn from 'src/components/signIn';
import CalendarComponent from 'src/components/scheduler';
import UsersComponent from 'src/components/users/users';
import AppBar from 'src/components/appBar/appBar';
import PatientDetail from 'src/components/patients/PatientDetail.container';
import MedicValidation from 'src/components/validation';
import ProfileComponent from 'src/components/profile';
import PasswordReset from 'src/components/passwordReset';

const GlobalRoute = ({ auth }) => {
  console.debug("rendering global route");
  const location = useLocation();

  console.log("LOCATION", location);

  if (auth.isInitializing) {
    return <div>Please Wait...</div>
  }

  let defaultRoute = auth.isSignedIn ? "/" : "/signin";
  if (auth.isSignedIn && auth.idToken.claims.role) {
    const role = auth.idToken.claims.role;
    if (role === 'pending:medic') {
      defaultRoute = '/medic/validation';
    } else {
      defaultRoute = `/${role}`;
    }
  }
  console.log("DEFAULT ROUTE", defaultRoute, auth);

  // todo: move app bar on top if all signed in users have it
  return <>
    <AppBar />
    <div style={{/*, height: "calc(100vh - 64px)"*/ }}>
      <Switch>
        {auth.isSignedIn && defaultRoute !== '/' && <Redirect exact from="/signin" to={defaultRoute} />}
        {auth.isSignedIn && defaultRoute !== '/medic/validation' && <Redirect exact from="/medic/validation" to={defaultRoute} />}
        {defaultRoute !== '/' && <Redirect exact from="/" to={defaultRoute} />}
        <Route path="/password-reset"><PasswordReset /></Route>
        <Route path="/signin"><SignIn /></Route>
        <PrivateRoute path="/medic/validation" authRoles={['pending:medic']}>
          <MedicValidation />
        </PrivateRoute>
        <PrivateRoute path="/profile" authRoles={['medic']}>
          <ProfileComponent />
        </PrivateRoute>
        <PrivateRoute path="/medic" authRoles={['medic']}>
          <Switch>
            <Redirect exact from="/medic" to="/medic/patients" />
            <Route path="/medic/patients"><PatientsComponent /></Route>
            <Route path="/medic/patient/:id"><PatientDetail /></Route>
            <Route path="/medic/calendar">
              <CalendarComponent />
            </Route>
            <Route render={NotFoundComponent} />
          </Switch>
        </PrivateRoute>
        <PrivateRoute path="/admin" authRoles={['admin']}>
          <Switch>
            <Redirect exact from="/admin" to="/admin/users" />
            <Route path="/admin/users"><UsersComponent /></Route>
          </Switch>
        </PrivateRoute>
        <Route render={NotFoundComponent} />
      </Switch>
    </div>
  </>;
}

export default withAuth(GlobalRoute);