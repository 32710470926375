import React, { useContext, useState } from 'react';
import { Paper, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
// import { auth as firebaseAuth } from 'src/firebase';
// import { useHistory } from 'react-router';

import { AuthContext } from 'src/providers/AuthProvider';
import Welcome from './Welcome';
import RequestProof from './RequestProof';
import ValidationOk from './ValidationOk';
import ValidationError from './ValidationError';

const useStyles = makeStyles(theme => ({
    nonEssential: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "unset"
        }
    },
    formPaper: {
        minWidth: 500
    }
}));

export default () => {
    const classes = useStyles();
    // const history = useHistory();
    const theme = useTheme();
    const smMatch = useMediaQuery(theme.breakpoints.up('sm'));
    const auth = useContext(AuthContext);
    const [currentStep, setCurrentStep] = useState('welcome');
    const [tokenResult, setTokenResult] = useState({token: false, error: false});

    const stepInfo = {
        "welcome": { label: "Bine ati venit, " + auth.user.displayName + "!" },
        "proof-request": { label: "" },
        "validation-ok": { label: "Felicitari" },
        "validation-error": { label: "Validare Nereusita" },
    }

    const handleContinue = async (data) => {
        switch (currentStep) {
            case 'welcome':
                setCurrentStep('proof-request');
                break;
            default:
                break;
        }
    }

    const handleStepBack = () => {
        switch (currentStep) {
            case 'validation-error':
                setCurrentStep('proof-request');
                break;
            default:
                break;
        }
    }

    const handleTokenResult = (token, error) => {
        if (token !== false) 
        {
            setCurrentStep("validation-ok");
            setTokenResult({token, error});
        } 
        else 
        {
            setCurrentStep("validation-error");
            setTokenResult({token, error});
        }
    }

    const title = <Typography variant="h5" style={{ padding: "16px 32px 0 32px" }}>
        {stepInfo[currentStep].label}
    </Typography>;

    let content;
    switch (currentStep) {
        case 'welcome':
            content = <Welcome userInfo={auth.user} onContinue={handleContinue} onBack={handleStepBack} />;
            break;
        case 'proof-request':
            content = <RequestProof onTokenResult={handleTokenResult} onBack={handleStepBack} />;
            break;
        case 'validation-ok':
            content = <ValidationOk token={tokenResult.token}/>;
            break;
        case 'validation-error':
            content = <ValidationError onBack={handleStepBack} error={tokenResult.error} />;
            break;
        default: break;
    }

    if (auth.isInitializing) {
        return <></>;
    }

    if (!smMatch) {
        return <>{title}{content}</>
    } else {
        return <div style={{ height: "calc(100vh - 64px)", minHeight: 500, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f0f0f0" }}>
            <Paper elevation={0} className={classes.formPaper}>{title}{content}</Paper>
        </div>
    }
}