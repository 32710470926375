import React from 'react';
import { useTheme } from '@material-ui/core';

export default (({ direction = "vertical", value, color, width, height, style, className, showBorder = true }) => {
    const theme = useTheme();
    const colors = theme.colorlib.palette(color);

    let myStyle = style || {};
    if (showBorder && !value && value !== 0) {
        myStyle = { border: `1px solid ${colors.border}`, ...myStyle }
    }

    return direction === "vertical" ? <svg width={width} height={height} style={myStyle} className={className} preserveAspectRatio="none"
        viewBox="0 0 100 100">
        {(value || value === 0) && <>
            <rect x="0" y="0" width="100" height={100 - value} fill={colors.background}></rect>
            <rect x="0" y={100 - value} width="100" height={value} fill={colors.main}></rect>
        </>}
    </svg> : <svg width={width} height={height} style={myStyle} className={className} preserveAspectRatio="none"
        viewBox="0 0 100 100">
            {(value || value === 0) && <>
                <rect x={value} y="0" width={100 - value} height="100" fill={colors.background}></rect>
                <rect x="0" y="0" width={value} height="100" fill={colors.main}></rect>
            </>}
        </svg>
})