import React, { useState } from 'react';
import _ from 'lodash';
import validator from 'validator';
import { FormControlLabel, TextField, Checkbox } from '@material-ui/core';
import { LinearFormControl, ButtonWithProgress } from 'src/components/widgets';
import useLanguage from '../../translations/useLanguage';

export const InviteComponent = ({ onCancel, onInvite, saveInProgress }) => {
    const { translations } = useLanguage();
    const [newPatient, setNewPatient] = useState({
        patientFamilyName: '', patientGivenName: '',
        caregiverFamilyName: '', caregiverGivenName: '',
        isCaregiver: false, email: ''
    });
    const [errors, setErrors] = useState({});

    const handlePatientChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const _newPatient = {
            ...newPatient,
            [name]: _.has(event.target, 'checked') ? event.target.checked : value,
        };
        setNewPatient(_newPatient);
        const validationResult = validateFields(name, _newPatient);
        if (errors[name] !== (validationResult !== true)) {
            setErrors({ ...errors, [name]: validationResult !== true });
        }
    };

    const validateFields = (fieldName, patient) => {
        const _patient = patient || newPatient;
        const _errors = {};
        if ((!fieldName || fieldName === 'patientFamilyName') && _.isEmpty(_patient.patientFamilyName)) {
            _errors.patientFamilyName = true;
        }
        if ((!fieldName || fieldName === 'patientGivenName') && _.isEmpty(_patient.patientGivenName)) {
            _errors.patientGivenName = true;
        }
        if ((!fieldName || fieldName === 'caregiverFamilyName') &&
            newPatient.isCaregiver && _.isEmpty(_patient.caregiverFamilyName)) {
            _errors.caregiverFamilyName = true;
        }
        if ((!fieldName || fieldName === 'caregiverGivenName') &&
            newPatient.isCaregiver && _.isEmpty(_patient.caregiverGivenName)) {
            _errors.caregiverGivenName = true;
        }
        if ((!fieldName || fieldName === 'email') && !validator.isEmail(_patient.email)) {
            _errors.email = true;
        }
        return _.keys(_errors).length === 0 ? true : _errors;
    }

    const handleInvite = async () => {
        const validationResult = validateFields();
        if (validationResult !== true) {
            setErrors(validationResult);
            return;
        }
        if (onInvite) {
            const result = await onInvite(newPatient);
            if (result.data.result === 'alreadyExists') {
                setErrors({ ...errors, emailInUse: true });
            } else  {
                setErrors({ ...errors, emailInUse: false });
            }
        }
    }

    let emailError = errors.email === true ? translations.addPatient.emailError : null;
    if (errors.emailInUse) {
        emailError = translations.addPatient.emailErrorRegistered;
    };

    return <div>
        <TextField label={translations.addPatient.lastName}
            autoComplete="off"
            inputProps={{ name: "patientFamilyName" }}
            value={newPatient.patientFamilyName}
            error={errors.patientFamilyName}
            helperText={errors.patientFamilyName ? translations.addPatient.helperPatientsFamilyName : null}
            onChange={handlePatientChange} fullWidth />
        <TextField label={translations.addPatient.firstName}
            autoComplete="off"
            inputProps={{ name: "patientGivenName" }}
            value={newPatient.patientGivenName}
            error={errors.patientGivenName}
            helperText={errors.patientGivenName ? translations.addPatient.patientHelperTextGiverName : null}
            onChange={handlePatientChange} fullWidth />
        <FormControlLabel control={<Checkbox
            checked={newPatient.isCaregiver}
            color="primary"
            inputProps={{ name: "isCaregiver" }}
            onChange={handlePatientChange} />
        } label={translations.addPatient.hasCaregiver} />
        {
            newPatient.isCaregiver &&
            <TextField label={translations.addPatient.caregiverName}
                autoComplete="off"
                inputProps={{ name: "caregiverFamilyName" }}
                value={newPatient.caregiverFamilyName}
                error={errors.caregiverFamilyName}
                helperText={errors.caregiverFamilyName ? translations.addPatient.caregiverHelperTextFamilyName : null}
                onChange={handlePatientChange} fullWidth />
        }
        {
            newPatient.isCaregiver &&
            <TextField label={translations.addPatient.caregiverFirstName}
                autoComplete="off"
                inputProps={{ name: "caregiverGivenName" }}
                value={newPatient.caregiverGivenName}
                error={errors.caregiverGivenName}
                helperText={errors.caregiverGivenName ? translations.addPatient.caregiverHelperGivenName : null}
                onChange={handlePatientChange} fullWidth />
        }
        <TextField label={newPatient.isCaregiver ? translations.addPatient.caregiverEmail : translations.addPatient.email}
            autoComplete="off"
            inputProps={{ name: "email" }}
            value={newPatient.email}
            error={!!emailError}
            helperText={emailError}
            onChange={handlePatientChange} fullWidth />
        <LinearFormControl centered>
            <ButtonWithProgress variant="contained" color="primary" onClick={handleInvite} disableElevation
                showProgress={saveInProgress} disabled={saveInProgress}>{translations.addPatient.actionButton}</ButtonWithProgress>
        </LinearFormControl>
    </div>
}

export default InviteComponent;