import { useContext } from 'react';

import { LanguageContext } from './languageProvider';


const useLanguage = () => {
  const languageContext = useContext(LanguageContext);
  
  return {
    translations: languageContext.translations,
    setLanguage: languageContext.setLanguage,
    language: languageContext.language
  };
};

export default useLanguage;