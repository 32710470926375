import React, { useEffect } from 'react';
import {
    makeStyles,
    AppBar,
    Typography,
    IconButton,
    MenuItem,
    Menu,
    Toolbar,
    Button,
    Link,
  Select,
  Box,
    OutlinedInput,
} from '@material-ui/core';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { AccountCircle, HelpOutline } from '@material-ui/icons';
import Logo from '../../images/logo.png';
import useLanguage from '../../translations/useLanguage';

import { withAuth } from 'src/providers/AuthProvider';
// todo: see if we can dispatch signOut through the context
// to avoid using auth here
import { auth as firebaseAuth } from 'src/firebase';
import AuthorizedItem from './authorizedItem';
import { languages } from '../../translations/languageProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    mainLinks: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.text.secondary
    },
    currentLink: {
        color: theme.palette.text.primary,
        fontWeight: "bold"
    },
    nonEssential: {
        display: "none",
        [theme.breakpoints.up('sm')]: {
            display: "unset"
        }
    },
    logoContainer: {
        height: 64,
        '& img': {
            height: 64
        }
    }
}));


const LOCAL_STORAGE_LANGUAGE_KEY = 'registreye_language';

export const AppBarComponent = ({ auth }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const open = Boolean(anchorEl);
    const history = useHistory();
    const {translations, setLanguage, language} = useLanguage();
    
    const handleChangeLanguage = (event) => {
        const {
            target: { value },
        } = event;
        const selectedLanguage = languages.find(l => l.id === value);
        setLanguage(selectedLanguage.id);
        localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, selectedLanguage.id);
    };

    useEffect(() => {
        if (auth.isSignedIn && auth.idToken.claims.role) {
            setAnchorEl(null);
        }
    }, [auth.isSignedIn, auth.idToken])

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const showProfilePage = (event) => {
        setAnchorEl(null);
        history.replace("/profile");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isPatientLink = location.pathname.startsWith('/medic/patient');
    const isCalendarLink = location.pathname.startsWith('/medic/calendar');

    return <>
        <AppBar position="fixed" elevation={0} style={{backgroundColor: "#fff"}}>
            <Toolbar>
                <div className={classes.logoContainer}>
                    <img src={Logo} alt=""/>
                </div>
                <Typography variant="h6" color="inherit" />
                <div className={classes.mainLinks}>
                    <AuthorizedItem roles="medic">
                        <Link style={{marginLeft: "2rem"}} className={isPatientLink ? classes.currentLink : classes.link} component={RouterLink} to="/medic/patients">{translations.navbar.patients}</Link>
                        <Link style={{marginLeft: "2rem"}} className={isCalendarLink ? classes.currentLink : classes.link} component={RouterLink} to="/medic/calendar">{translations.navbar.calendar}</Link>
                    </AuthorizedItem>
                </div>
                <div className={classes.nonEssential}>
                    <IconButton
                        aria-label="help"
                        aria-controls="menu-appbar"
                        // onClick={handleMenu}
                        color="inherit"
                    >
                        <HelpOutline />
                    </IconButton>
                    <Select value={language} onChange={handleChangeLanguage} input={<OutlinedInput label='Limba'/>}>
                        {languages.map(language => (<MenuItem key={language.id} value={language.id}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={language.image} alt=""/>
                                <Box sx={{ ml: '10px' }}>
                                    {language.text}
                                </Box>
                            </Box>
                        </MenuItem>))}
                    </Select>
                    {auth.isSignedIn && auth.idToken.claims.role && <>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={showProfilePage}>{auth.user.displayName}</MenuItem>
                            <MenuItem onClick={() => firebaseAuth.signOut()}>Iesire</MenuItem>
                        </Menu>
                    </>}
                    {!auth.isSignedIn && <Button href="/signin">{translations.navbar.login}</Button>}
                </div>
            </Toolbar>
        </AppBar>
        <Toolbar />
    </>
}

export default withAuth(AppBarComponent);