import React, { useState, useLayoutEffect, useRef, useCallback } from 'react';
import CalendarGrid from './calendar';
import moment from 'moment';
import { get as _get } from 'lodash';
import { DeleteForever, Equalizer } from '@material-ui/icons';
import {
    Button, Table, TableBody, TableRow, TableCell, TableContainer,
    Paper, IconButton, makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
import ContentWithActionBarComponent from '../layout/contentWithActionBar';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import AppointmentEdit from './appointmentEdit';
import { TitleBar } from '../widgets';
import { useHistory, useLocation } from 'react-router';
import useLanguage from '../../translations/useLanguage';

const getTimeSlots = (date) => {
    // todo: see what times are required, maybe read from config
    // from 7 am to 8 pm, every 15 minutes
    const now = moment();
    const startTime = moment(date).set("hour", 7).set("minute", 0);
    const endTime = moment(date).set("hour", 20).set("minute", 0);
    const time = startTime.clone();
    const timeSlots = [];

    while (time.isBefore(endTime)) {
        if (time.isSameOrAfter(now)) {
            timeSlots.push(time.clone());
        }
        time.add(15, "minutes");
    }

    return timeSlots;
}

const useStyles = makeStyles((theme) => ({
    selectedRow: {
        "& .hoverButton": {
            visibility: "hidden",
            padding: 0
        },
        "&:hover .hoverButton": {
            visibility: "visible"
        }
    },
    highlightedRow: {
        backgroundColor: `${theme.palette.primary.main}20`
    },
    highlightedRowCancel: {
        backgroundColor: `${theme.palette.secondary.main}20`
    },
    clickable: {
        cursor: "pointer",
        color: theme.palette.primary.main
    }
}));

export default () => {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
    const [data, setData] = useState({ patients: [], appointments: [] });
    const [sidebarContext, setSidebarContext] = useState(false);
    const firestoreStreams = useFirestoreStreams();
    // calendar grid stuff
    const scrollOffsetRef = useRef();
    const listRef = useRef();
    const history = useHistory();
    const location = useLocation();
    const {translations} = useLanguage();

    const dailyAppointments = data.appointments.filter(a =>
        selectedDate.isSameOrBefore(a.date) && a.date.isBefore(selectedDate.clone().add(1, "day")));

    const handleInitAppointment = useCallback((action = "new", patient, appointment) => {
        setSidebarContext({
            params: {
                // EditAppointment params
                appointment,
                patient,
                action
            },
            // internal use
            originalDate: selectedDate.clone(),
            scrollOffset: scrollOffsetRef.current
        });
    }, [selectedDate]);

    useLayoutEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get("book") && data.patients.length > 0 && !sidebarContext) {
            // need to book new appointment for a patient
            const patient = data.patients.find(p => p.id === params.get("book"));
            handleInitAppointment("new", patient);
        }
    }, [location, data, sidebarContext, handleInitAppointment]);

    useLayoutEffect(() => {
        if (firestoreStreams.isInitialized) {
            firestoreStreams.subscribe("patients", state => {
                const allAppointments = [];
                // consolidate all appointment dates from all patients in one array
                state.items.forEach(i => {
                    const appts = {
                        ..._get(i.data, "appointments.recent", {}),
                        ..._get(i.data, "appointments.upcoming", {})
                    };

                    allAppointments.push.apply(allAppointments, Object.entries(appts).map(([id, unixDate]) => ({
                        id, patientId: i.id,
                        date: moment(unixDate)
                    })));
                });
                allAppointments.sort((a, b) => a.date > b.date ? 1 : -1);
                setData({ patients: state.items, appointments: allAppointments });
            });
        }
    }, [firestoreStreams])

    // todo: refactor this to handle more params
    const cleanupLocationParams = () => {
        const params = new URLSearchParams(location.search);
        if (params.has("book")) {
            params.delete("book");
            // cleanup location url
            history.replace({ ...history.location, search: "" });
        }
    }

    const handleEditAppointment = (appointmentId) => {
        cleanupLocationParams();
        const appointment = data.appointments.find(a => a.id === appointmentId);
        const patient = data.patients.find(p => p.id === appointment.patientId);
        // navigate to that date as well
        setSelectedDate(appointment.date.clone().startOf("day"));
        // switch originalDate and scroll offset as well
        setSidebarContext({
            ...sidebarContext, params: { appointment, patient, action: 'edit' },
            // internal use
            originalDate: appointment.date.clone().startOf("day"),
            scrollOffset: scrollOffsetRef.current
        });
    }

    /**
     * @param {boolean} isCancel indicate how the action panel closed
     */
    const handleSideActionClose = (isCancel) => {
        if (isCancel && !!sidebarContext
            && (!selectedDate.isSame(sidebarContext.originalDate) || sidebarContext.scrollOffset !== scrollOffsetRef.current)) {
            setSelectedDate(sidebarContext.originalDate);
            listRef.current.scrollTo(sidebarContext.scrollOffset);
        }
        cleanupLocationParams();
        // cleanup context
        setSidebarContext(false);
    }

    const handlePatientClick = (patientItem) => {
        history.push({ pathname: `/medic/patient/${patientItem.id}`, state: patientItem });
    }

    /**
     * Callback from calendar grid to keep track of scroll position on Cancel
     * @param {number} scrollOffset 
     * @param {boolean} scrollWasRequested 
     */
    const handleListScroll = (scrollOffset, scrollWasRequested) => {
        scrollOffsetRef.current = scrollOffset;
    }

    console.debug("RENDER CALENDAR");
    return <ContentWithActionBarComponent
        content={
            <div style={{
                height: "calc(100vh - 64px - 2rem)",
                display: "flex", flexDirection: "column",
                userSelect: "none",
                MozUserSelect: "-moz-none",
                WebkitUserSelect: "none",
                KhtmlUserSelect: "none"
            }}>
                <CalendarGrid
                    highlightDate={_get(sidebarContext, "params.action") === "edit" ? sidebarContext.params.appointment.date : null}
                    disabled={_get(sidebarContext, "params.action") === "cancel"}
                    disablePastDates={!!sidebarContext} selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate} appointments={data.appointments}
                    listRef={listRef} onScroll={handleListScroll} />
            </div>}
        actionBarProps={{ style: { paddingLeft: "1rem", paddingRight: "1rem", minWidth: "20rem", flex: 0 } }}
        actionBarContent={
            <>
                {
                    !!sidebarContext &&
                    <AppointmentEdit
                        onCancel={() => handleSideActionClose(true)}
                        lockPatient={new URLSearchParams(location.search).has("book")}
                        date={selectedDate}
                        setDate={setSelectedDate}
                        setEditAppointment={handleEditAppointment}
                        timeSlots={getTimeSlots(selectedDate)}
                        onDone={() => handleSideActionClose(false)}
                        patients={data.patients}
                        {...sidebarContext.params} />
                }

                {!sidebarContext && <TitleBar variant="h6" text={selectedDate.format("dddd, D MMM")}>
                    {selectedDate.isSameOrAfter(moment().startOf("day")) &&
                        /* <Fab color="primary" onClick={() => handleInitAppointment()} size="small" >
                        <Add />
                        </Fab> */
                        <Button variant="outlined" color="primary" style={{ margin: 0 }}
                            onClick={() => handleInitAppointment()}>{translations.scheduler.addAppointment}</Button>}
                </TitleBar>}

                <TableContainer component={Paper} elevation={0} style={{ marginTop: "1rem" }}>
                    <Table>
                        <TableBody>
                            {dailyAppointments && dailyAppointments.map(a => {
                                const patient = data.patients.find(p => p.id === a.patientId);
                                return <TableRow key={a.id} hover={!sidebarContext} className={classNames({
                                    [classes.selectedRow]: true,
                                    [classes.highlightedRow]: _get(sidebarContext, "params.action") === 'edit' && a.id === _get(sidebarContext, "params.appointment.id"),
                                    [classes.highlightedRowCancel]: _get(sidebarContext, "params.action") === 'cancel' && a.id === _get(sidebarContext, "params.appointment.id")
                                })}>
                                    <TableCell onClick={sidebarContext ? null : () => handleInitAppointment("edit", patient, a)}
                                        className={sidebarContext ? null : classes.clickable}>
                                        {a.date.format("HH:mm")}
                                    </TableCell>
                                    <TableCell align="left" className={classNames}>
                                        {patient.data.displayName}
                                    </TableCell>
                                    <TableCell align="right">
                                        {!sidebarContext && <IconButton className="hoverButton" onClick={() => handlePatientClick(patient)}>
                                            <Equalizer />
                                        </IconButton>}
                                        {!sidebarContext && <IconButton className="hoverButton" onClick={() => handleInitAppointment("cancel", patient, a)}>
                                            <DeleteForever />
                                        </IconButton>}
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        } />
}