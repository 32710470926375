import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, Slider, Chip, makeStyles, TextField, Table, TableBody, TableRow, TableCell, Checkbox, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { get as _get, has as _has } from 'lodash';
import Parser from 'html-react-parser';
import classNames from 'classnames';
import { delta } from 'src/utils';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
        padding: 0,
        marginTop: "1rem",
        "& .MuiTableCell-root": {
            padding: 0,
            fontSize: "unset"
        },
    },
    section: {
        marginTop: "1rem",
        marginLeft: "2rem",
        "& .title": {
            paddingBottom: "2rem",
            whiteSpace: "nowrap"
        },
        "& .MuiTableCell-root": {
            padding: "1rem 0",
        }
    },
    noBorder: {
        "& .MuiTableCell-root": {
            border: "none"
        }
    },
    question: {
        display: "flex",
        marginTop: "1rem"
    },
    wrap: {
        flexWrap: "wrap"
    },
    break: {
        flexBasis: "100%",
        height: "1rem"
    },
    info: {
        padding: "1rem",
        flex: "0.3 200px",
        marginLeft: "2rem",
        opacity: 0.8,
        whiteSpace: "pre-line"
    },
    inactiveSlider: {
        "&:active": {},
        "& .MuiSlider-thumb": {
            backgroundColor: '#fff',
            border: '1px solid currentColor',
            '&:focus, &:hover, &:active': {
                boxShadow: 'inherit',
            },
        }
    }
}));

export default (({ loading, definition, results, onResultChange, onManualScoreChange, profile, readOnly }) => {
    const classes = useStyles();
    const definitionRef = useRef(definition);

    const getCollapsibleSections = useCallback(() => {
        return definition ? definition.items.filter(i =>
            readOnly || (i.type === 'section' && i.score && _get(results, [i.id, "score"], false) !== false))
            .map(i => i.id) : [];
    }, [definition, results, readOnly]);

    const [collapsed, setCollapsed] = useState(getCollapsibleSections());

    // reset collapsed status on survey change
    useEffect(() => {
        if (delta(definition, definitionRef.current)) {
            definitionRef.current = definition;
            setCollapsed(getCollapsibleSections());
        }
    }, [definition, getCollapsibleSections])


    const handleInputChange = (event, path, valuePath, value) => {
        onResultChange(path, valuePath, value);
    }

    const handleMultiSelectCheck = (event, path, valuePath, value) => {
        const oldValue = _get(results, valuePath, []);
        // one level clone, this is an array of objects
        let newValue = [...oldValue];
        const isChecked = event.target.checked;
        if (isChecked) {
            newValue.push(value);
        } else {
            const index = newValue.indexOf(value);
            if (index > -1) {
                newValue.splice(index, 1);
            }
        }
        onResultChange(path, valuePath, newValue);
    }

    const handleManualScoreChange = (event, path) => {
        onManualScoreChange(path, event.target.value);
    }

    const toggleCollapsed = (sectionId) => {
        const collapsedFiltered = collapsed.filter(id => id !== sectionId);
        if (collapsedFiltered.length === collapsed.length) {
            collapsedFiltered.push(sectionId);
        }
        setCollapsed(collapsedFiltered);
    }

    const renderRangeInput = (q, path) => {
        const valuePath = [...path, "value"];
        const marks = [];
        for (let val = q.minValue; val <= q.maxValue; val++) {
            marks.push({ value: val, label: val })
        }
        return <Slider className={classNames({ [classes.inactiveSlider]: !_has(results, valuePath) })} step={1}
            min={q.minValue} max={q.maxValue} value={_get(results, valuePath, q.minValue)}
            marks={marks} onChange={readOnly ? undefined : (event, value) => handleInputChange(event, path, valuePath, value)}
            style={{ flex: "0 10rem", marginLeft: "2rem" }} />;
    }

    const renderOptionsInput = (q, path) => {
        const valuePath = [...path, "value"];
        console.log("OPTIONS", q.select_options);
        return <>
            <div className={classes.break} />
            <div>
                {q.select_options.map(option =>
                    <FormControlLabel
                        style={{ display: "block" }}
                        key={option.value}
                        control={
                            <Checkbox
                                size="small"
                                // className={classes.checkbox}
                                value={option.value}
                                // name={option.toLowerCase()}
                                checked={_get(results, valuePath, []).includes(option.value)}
                                onChange={readOnly ? undefined : (event, value) => handleMultiSelectCheck(event, path, valuePath, option.value)}
                                checkedIcon={<CheckBox color="primary" />}
                                icon={<CheckBoxOutlineBlank color="primary" />}
                            />
                        }
                        label={option.label}
                    />)}
            </div>
        </>
    }

    const renderQuestion = (q, path) => {
        const myPath = path ? [...path, q.id] : [q.id];
        return <div key={q.id} className={classNames({
            [classes.question]: true,
            [classes.wrap]: q.question_type === 'select_many'
        })}>
            <Typography variant="body2" style={{ flex: 1 }}>{q.title}</Typography>
            {q.question_type.startsWith("range_") && renderRangeInput(q, myPath)}
            {q.question_type === 'select_many' && renderOptionsInput(q, myPath)}
        </div>
    }

    const renderItems = (items, path) => items && items.map(item =>
        item.type === 'question' ? renderQuestion(item, path) : renderSection(item, path));

    const renderSection = (s, path) => {
        const myPath = path ? [...path, s.id] : [s.id];
        const isTopSection = myPath.length === 1;
        const isCollapsed = (readOnly || s.score) && isTopSection && collapsed.includes(s.id);
        const isCollapsible = (readOnly || (s.score && _get(results, [...myPath, "score"], false) !== false)) && myPath.length === 1;

        const SectionWrapper = ({ children, ...params }) => {
            return isTopSection ? <TableRow {...params}>{children}</TableRow> : <div {...params}>{children}</div>;
        }

        const sectionContent = <div style={{ display: "flex", alignItems: "stretch" }}>
            <div style={{ flex: "0.7 200px", position: "relative", display: "flex", flexDirection: "column" }}>
                {renderItems(s.items, myPath)}

                <div style={{ display: "flex", flexDirection: "row", margin: "1rem 0", flex: 1, alignItems: "flex-end" }}>
                    <div style={{ flex: "1 0" }}></div>
                    <div style={{ flex: "0 1 10rem", display: "flex", alignItems: "baseline" }}>
                        <Chip size="small" color="primary"
                            label={_get(results, myPath + ".autoScore", "/")} style={{ marginRight: "1rem", borderRadius: "unset" }} />
                        <TextField label="Scor" style={{ margin: 0 }} value={_get(results, myPath + ".overrideScore", readOnly ? "-" : "")}
                            InputProps={{ readOnly }}
                            onChange={event => handleManualScoreChange(event, myPath)}></TextField>
                    </div>
                </div>
            </div>
            {s.info && <Alert severity="info" elevation={0} icon={false} className={classes.info}>
                {Parser(s.info)}
            </Alert>}
        </div>

        return <React.Fragment key={s.id}>
            <SectionWrapper className={classNames({ [classes.section]: true, [classes.noBorder]: !isCollapsed, "clickable": isCollapsible })}>
                <TableCell className="title" onClick={isCollapsible ? () => toggleCollapsed(s.id) : undefined}>
                    <b>{s.id}. {s.title}</b></TableCell>
                <TableCell width="100%"><Chip color="primary" variant="outlined"
                    label={_get(results, myPath + ".score", "/")} style={{ borderRadius: "unset", marginLeft: "2rem", width: "3rem" }} /></TableCell>
            </SectionWrapper>
            {!isCollapsed && (
                isTopSection ? <TableRow><TableCell colSpan={3}>{sectionContent}</TableCell></TableRow> : { sectionContent }
            )}
        </React.Fragment>
    }

    if (!profile)
        return <></>;

    return loading ?
        <Typography variant="body1" style={{ display: "block", opacity: 0.4, margin: "2rem", fontWeight: "bold" }}>
            Se incarca...
        </Typography> :
        <Table className={classes.root}>
            <TableBody>
                {renderItems(definition.items)}
            </TableBody>
        </Table>
});