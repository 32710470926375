import React, { useState } from 'react';
import validator from 'validator';
import { TextField, Button, Divider } from '@material-ui/core';
import { ButtonWithProgress, LinearFormControl } from '../widgets';
import { ChevronLeftSharp } from '@material-ui/icons';
import { useFirestoreStreams } from 'src/providers/FirestoreStreamProvider';
import useLanguage from '../../translations/useLanguage';

export default ({ onBack, onContinue }) => {
    const [emailError, setEmailError] = useState(false);
    const [, setRegistrationError] = useState(false);
    const [email, setEmail] = useState("");
    const [progress, setProgress] = useState(false);
    const { translations } = useLanguage();

    const firestoreStreams = useFirestoreStreams();
    
    const handleContinue = async (event) => {
        if (email === "") {
            setEmailError(translations.loginForm.emailError);
            return;
        } else if (!validator.isEmail(email)) {
            setEmailError(translations.loginForm.invalidEmailAddress);
            return;
        } else {
            setEmailError(false);
        }

        setProgress(true);
        try {
            const passwordReset = firestoreStreams.getCallableFunction('passwordReset');
            const result = await passwordReset({ email });
            console.log("RESET RESULT", result);
            onContinue();
        } catch (err) {
            // todo: handle error
            console.error("RESET ERROR", err.message);
            setProgress(false);
            setRegistrationError(err.message);
        } finally {
            setProgress(false);
        }
    }

    return <>
        <div style={{ display: "flex", flexDirection: "column", padding: "0 32px 16px 32px", maxWidth: 600 }}>
            <TextField autoComplete="off" label={translations.loginForm.placeholderEmail}
                error={!!emailError} helperText={emailError}
                value={email} onChange={e => setEmail(e.target.value.trim())} />
        </div>
        <Divider />
        <LinearFormControl style={{ marginTop: 12, padding: "0 32px 16px 32px", justifyContent: "space-between", width: "unset" }}>
            <Button startIcon={<ChevronLeftSharp />} disabled={progress}
                variant="outlined" disableElevation onClick={onBack}>{translations.loginForm.back}</Button>
            <ButtonWithProgress variant="contained" color="primary" disableElevation disabled={progress} showProgress={progress}
                onClick={handleContinue}>{translations.loginForm.actionButton}</ButtonWithProgress>
        </LinearFormControl>
    </>
};